import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-back-header',
  templateUrl: './back-header.component.html',
  styleUrls: ['./back-header.component.css']
})
export class BackHeaderComponent {
  @Input() link: string | undefined;
}
