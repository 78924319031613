import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HomeVersionerComponent } from '../home-versioner/home-versioner.component';
import { RouterModule } from '@angular/router';
import { ArticleVersionerComponent } from '../article-versioner/article-versioner.component';
import { SharedModule } from "../../shared/shared.module";



@NgModule({
    declarations: [
        HomeVersionerComponent,
        ArticleVersionerComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        SharedModule
    ]
})
export class VersionerModuleModule { }
