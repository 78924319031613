<app-header title="{{'Newsletter.title' | transloco }}"></app-header>

<div class="flex mt-2">
    <app-sidebar optionMenu="2"></app-sidebar>

    <div class="bg-white rounded-2xl shadow-md mx-auto mt-20 w-3/4 h-3/4 flex flex-col items-center justify-center">
        <h2 class="customTitleBg">{{ 'Newsletter.title' | transloco }}</h2>

        <div id="tablaContainer">
            <div class="flex justify-between">
                <h2 class="font-bold text-2xl text-gray-400">{{ 'Newsletter.listNewsletter' | transloco }}</h2>
                <!-- <button *ngIf="canCreate" -->
                <ng-template [ShowForRoles]="['coordinadorredaccion']">
                    <button 
                        class="mb-8 bg-green-MainColor text-white w-40 rounded-full flex  items-center h-11 ml-auto"
                        [routerLink]="['/create-newsletter']">
                        <i class="fa fa-plus fa-lg mr-2 text-white text-base pl-6 cursor-pointer"></i>
                        {{'globals.create' | transloco }}
                    </button>
                </ng-template>
            </div>

            <div class="customTable">
                <table class="bg-white mx-auto w-full rounded-lg overflow-hidden shadow-2xl">
                    <thead class="customThead">
                        <tr class="pb-5 font-bold">
                            <th *ngFor="let title of thTitles"
                                class="text-center px-6 py-4 font-bold text-sm tracking-widest bg-[#038652]/10 w-[38rem]">
                                {{title}}
                            </th>
                            <th class="text-center px-6 py-4 font-bold text-sm tracking-widest bg-[#038652]/10 w-[38rem]">
                                Acciones
                            </th>
                        </tr>
                    </thead>
                    <tbody class="divide-y border-b-[1px]">
                        <tr class="customTrContent" *ngFor="let content of newsletterList, let i = index">
                            <td class="text-center pt-2 pb-2 font-medium">{{content.name}}</td>
                            <td class="text-center font-medium">{{content.section}}</td>
                            <td class="text-center font-medium">{{content.schedule_status}}</td>
                            <td class="font-medium text-center">
                                <ng-template [ShowForRoles]="['adminredaccion']">    
                                    <a 
                                        title="Editar" class="cursor-pointer" (click)="edit(content.id)"><i
                                            class="fa fa-pencil fa-lg mr-2 text-green-700 cursor-pointer"></i></a>
                                </ng-template>
                                <ng-template [ShowForRoles]="['adminredaccion']">
                                    <a title="Eliminar" class="cursor-pointer"
                                        (click)="deleteNewsletter(content.id)"><i
                                            class="fa fa-trash fa-lg mr-2 text-red-700 cursor-pointer"></i></a>
                                </ng-template>
                                <ng-template [ShowForRoles]="['adminredaccion','redactor']">
                                    <a  title="Programar Envío"
                                        class="cursor-pointer" (click)="programmingNewsletter(content.id)"><i
                                            class="fa fa-calendar fa-lg mr-2 text-blue-700 cursor-pointer"></i></a>
                                </ng-template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
