<app-header title="{{'links-list.title' | transloco }}"></app-header>

<div class="flex mt-2">
    <app-sidebar optionMenu="6"></app-sidebar>

    <div class="bg-white rounded-2xl shadow-md mx-auto mt-8 w-3/4 h-3/4 flex flex-col">
       
        <div class="flex justify-between ">
            <h2 class="font-bold text-3xl my-10 ml-10">{{ 'links-list.titleTable' | transloco }}</h2>

            <div class="flex items-center gap-1 mr-6">
                <button class="items-center w-44 h-10 border rounded bg-green-MainColor flex"
                    [routerLink]="['/create-list']">
                    <i class="fas fa-plus text-white px-3"></i>
                    <span class="text-white text-center font-semibold text-sm m-auto">
                        {{ "links-list.create" | transloco }}
                    </span>
                </button>
            </div>
        </div>

        <table class="bg-white mx-auto w-full shadow-lg rounded-lg table-auto overflow-hidden divide-y divide-light-light">
            <thead>
              <tr>
                <th class="px-10 py-5 border-b-2 border-light-dark p-2 text-left text-sm font-bold uppercase tracking-wider">
                  {{ 'links-list.list' | transloco }}
                </th>
                <th class="py-5 border-b-2 border-light-dark text-center text-sm font-bold uppercase tracking-wider hidden md:table-cell">
                  {{ 'globals.options' | transloco }}
                </th>
              </tr>
            </thead>
            
            <tbody>
                <tr *ngFor="let data of dataLinks" class="border-b border-gray-200 relative text-dark-light">
                    <td class="px-10 py-6">
                       <span class="text-center">{{data.name}}</span>
                    </td>

                    <td class="text-center">
                        <i class="far fa-edit fa-lg text-green-MainColor mr-5 cursor-pointer" (click)="edit(data.id)"></i>
                        <i class="fas fa-trash-alt fa-lg text-red-light cursor-pointer" (click)="delete(data.id)"></i>
                    </td>
                </tr>
            </tbody>
        </table>    
    </div>    
</div>