<app-back-header link='/list-links'></app-back-header>

<div class="bg-white rounded-2xl shadow-md mx-auto p-8 mt-8 w-3/4 h-3/4 flex flex-col">

    <div class="flex justify-between py-6">
        <h1 class="font-bold text-xl mt-4 md:text-2xl md:mt-1 mb-4">{{ 'links-list.form' | transloco }}</h1>
    
        <button type="submit" (click)="submitList()" class="items-center w-44 h-10 border rounded-md  bg-green-MainColor flex">
            <span class="text-white text-center font-semibold text-sm m-auto">{{ 'globals.save' | transloco }}</span>
        </button>
    </div>

    <form [formGroup]="listForm">
        <div class="py-6 border-b">
            <label for="" class="text-dark text-xs uppercase font-thin tracking-widest  ml-2 py-2 md:m-0 md:inline-block md:w-1/3">{{ 'links-list.nameList' | transloco }}</label>
            <div class="w-full md:w-2/3 md:inline-block">
                <input type="text" formControlName="name" class="py-2 w-96 h-9 text-base rounded-xl pl-4 border border-dark-light  focus:ring-1 focus:outline-none focus:border-transparent focus:ring-dark-light">
            </div>  
            
            <div *ngIf="checkValidation(1, listForm.get('name'))" class="text-red text-sm">
                <div *ngIf="checkValidation(2, listForm.get('name'))"> {{ 'globals.required' | transloco }}</div>
            </div>
        </div>   

        <div class="my-5">
            <p class="text-dark text-xs uppercase font-bold tracking-widest my-8">{{ 'links-list.linkData' | transloco }}</p>
        </div>
        

        <div class="mb-5">
            <label for="" class="ml-2 py-2 md:m-0 md:inline-block md:w-1/3 text-dark text-xs uppercase font-thin tracking-wider">{{ 'links-list.nameTag' | transloco }}</label>
            <div class="w-full md:w-2/3 md:inline-block">
                <input type="text" formControlName="label" class="py-2 w-96 h-9 text-base rounded-xl pl-4 border border-dark-light  focus:ring-1 focus:outline-none focus:border-transparent focus:ring-dark-light">
            </div>    
        </div>

        <div class="mb-5">
            <label for="" class="ml-2 py-2 md:m-0 md:inline-block md:w-1/3 text-dark text-xs uppercase font-thin tracking-wider">{{ 'links-list.link' | transloco }}</label>
            <div class="w-full md:w-2/3 md:inline-block">
                <input type="text" formControlName="url" class="py-2 w-96 h-9 text-base rounded-xl pl-4 border border-dark-light  focus:ring-1 focus:outline-none focus:border-transparent focus:ring-dark-light">
            </div>
        </div>

        <div class="py-6 border-b">
            <button class="items-center w-44 h-10 border rounded-md  bg-blue-500 flex" (click)="addItem()">
                <span class="text-white text-center font-semibold text-sm m-auto">{{ 'globals.new' | transloco }}</span>
            </button>
        </div>
        

        <p class="text-dark text-xs uppercase font-bold tracking-widest my-8">{{ 'links-list.title' | transloco }}</p>

        <div cdkDropList class="w-full h-auto p-5 shadow-lg border rounded-lg mb-4" (cdkDropListDropped)="drop($event)">
            <div class="p-5 border-b cursor-move" *ngFor="let link of links" cdkDrag>
                <div>
                    <span class="font-bold">{{ 'links-list.nameTag' | transloco }}:</span>
                    <span> {{link.label}}</span>
                </div>
                <div>
                    <span class="font-bold">{{ 'links-list.link' | transloco }}:</span>
                    <span> {{link.url}}</span>
                </div>
            </div>
        </div>
    </form>
</div>

