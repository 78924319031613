<app-header title="{{ 'gallery.title' | transloco}}"></app-header>

<div class="flex bg-[#F2F2F5] w-full mt-5">
    <div class="sticky top-0">
        <div class=" flex mt-2">

            <app-sidebar optionMenu="3"></app-sidebar>

            <div class="mx-5 mt-1 w-5/6">
                <div class="w-full flex flex-wrap p-3 md:mt-0">
                    <!-- <h3 class="text-[#303030] text-2xl ml-10 font-semibold">Home</h3> -->
                    <div class="ml-auto pr-5">
                        <ul class="">
                            <li class="-mb-px mr-1">
                                <a class="inline-block rounded-t-xl py-2 px-12 font-medium " 
                                [ngClass]="{'text-dark-light  bg-light-dark pl-10': openTab !== 1, 'bg-white text-black': openTab === 1}">
                                Galerías
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div class="w-full mb-6 shadow-lg rounded-lg">
                        <div class="bg-white overflow-x-auto py-4 px-4 rounded-xl"
                            [ngClass]="{ hidden: openTab !== 1, block: openTab === 1 }">
                            <div class="flex">
                                <div class="mt-8 ml-7">
                                    <span class="text-[#C2C2C2] text-2xl font-semibold">Galerías</span>
                                </div>
                                <div class="ml-auto pt-7 pr-2">
                                    <lib-buttons-iris typeValue="publicize" [routerLink]="'/create-gallery'"></lib-buttons-iris>
                                </div>
                            </div>
                            <div class="bg-white flex flex-wrap items-center justify-center p-14" 
                            [ngClass]="{hidden: listingItems.length > 0, block: listingItems.length === 0}">

                                <div class="w-full flex-none p-4 text-left lg:w-3/6">
                                    <p class="text-dark-light text-2xl font-semibold my-4">
                                        {{ "dashboard.main.empty_title" | transloco }}
                                    </p>
                                    <p class="text-dark-light text-lg font-normal my-4">
                                        {{ "dashboard.main.empty_message" | transloco }}
                                    </p>
                                </div>

                                <div class="w-full flex-none lg:flex lg:w-3/6">
                                    <img class="mx-auto" src="./assets/Group88.png" width="360px" height="290px"
                                        alt="No resultados" />
                                </div>
                            </div>

                            <div [ngClass]="{block: listingItems.length > 0, hidden: listingItems.length === 0}">
                                <!-- <i class="fas fa-info-circle text-green-dark"></i> -->

                                <!--TABLA-->
                                <table class="bg-white w-full rounded-lg overflow-hidden divide-light-light mt-10">
                                    <thead class="border-b-[20px] border-b-[#FFFFFF] sticky top-0">
                                        <tr>
                                            <th *ngFor="let head of listingItems[0] | keyvalue"
                                                class="px-6 py-4 font-medium text-sm tracking-widest bg-[#038652]/10">
                                                {{ head.key }}
                                            </th>
                                            <th class="fpx-6 py-4 font-medium text-sm tracking-widest bg-[#038652]/10">
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of listingItems"
                                            class="text-dark hover:bg-[#F2F2F5] text-center h-12">
                                            <td *ngFor="let list of item | keyvalue" class="border-[1px] border-l-0">
                                                {{ list.value }}
                                            </td>
                                            <td class="border-[1px] border-r-0">
                                                <i class="fa fa-solid fa-pencil text-green-MainColor m-2 cursor-pointer"
                                                    (click)="edit(item['id'])"></i>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <!--TABLA-->
                            </div>

                            <!--PAGINADOR -->
                            <lib-paginador-iris *ngIf="showPagination" [pagina]="page" (paginacion)="displayItemsOnTable($event)"></lib-paginador-iris>
                            <!--PAGINADOR -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
