import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Create } from 'src/app/models/create-template';
import { environment as env } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EditTemplateService {
  private apiKey =`${env.apiKey}`;
  private baseUrl = `${env.apiCerberoUrl}`;
  private _create: Create | null = null; 
  private sharedData: any;

  constructor(private http: HttpClient) { }

  editTemplate(data: any): Observable<any> {
    console.log('Desde el servicio', `${this.baseUrl}/request?event=update-template`, data);
    return this.http.put<any>(`${this.baseUrl}/request?event=update-template`, data);
  }

  createTemplate(templateData: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/request?event=update-template`, templateData);
  }

}
