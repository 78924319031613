<app-header title="{{'module-management.title' | transloco }}"></app-header>

<div class="flex mt-2">
    <app-sidebar optionMenu="5"></app-sidebar>
    
    <div class="pt-5 pb-10 w-full md:pl-11 md:pr-8 lg:pl-20 lg:pr-16">
        <form class="mx-auto max-w-screen-xl">
            <div class="flex justify-between">
                <h1 class="text-2xl font-bold mt-1">{{ 'ModuleManager.Specials' | transloco }}</h1>

                <button [routerLink]="['/create-module']" type="submit"
                    class="flex items-center w-44 h-[50px] p-4 rounded-lg bg-green-700">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M12 5V19" stroke="white" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path d="M5 12H19" stroke="white" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                    <span class="text-white font-semibold text-sm pl-3">{{ 'ModuleManager.newModule' | transloco
                        }}</span>
                </button>
            </div>

            <div class="bg-white rounded-lg mt-6 py-2 shadow-strongShadow px-2">
                <form [formGroup]="jsonForm" class="flex items-center" action="">
                    <div class="relative w-96">
                        <input type="text" formControlName="name" (input)="searchModule()"
                            class="bg-transparent px-8  py-2 pl-12 w-full h-14 rounded-lg border border-gray-transparent"
                            name="" id="" placeholder="{{ 'ModuleManager.searchModule' | transloco }}">
                        <svg class="absolute left-3 top-4 h-6 w-6 text-gray-500" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20" fill="currentColor">
                            <path
                                d="M19.6714 18.0942L15.8949 14.3287C17.1134 12.7764 17.7745 10.8595 17.7721 8.88603C17.7721 7.12854 17.2509 5.41052 16.2745 3.94922C15.2981 2.48792 13.9103 1.34897 12.2866 0.676412C10.6629 0.00385015 8.87617 -0.172123 7.15245 0.170746C5.42873 0.513616 3.84539 1.35993 2.60266 2.60266C1.35993 3.84539 0.513616 5.42873 0.170746 7.15245C-0.172123 8.87617 0.00385015 10.6629 0.676412 12.2866C1.34897 13.9103 2.48792 15.2981 3.94922 16.2745C5.41052 17.2509 7.12854 17.7721 8.88603 17.7721C10.8595 17.7745 12.7764 17.1134 14.3287 15.8949L18.0942 19.6714C18.1974 19.7755 18.3203 19.8582 18.4556 19.9146C18.591 19.971 18.7362 20 18.8828 20C19.0294 20 19.1746 19.971 19.31 19.9146C19.4453 19.8582 19.5682 19.7755 19.6714 19.6714C19.7755 19.5682 19.8582 19.4453 19.9146 19.31C19.971 19.1746 20 19.0294 20 18.8828C20 18.7362 19.971 18.591 19.9146 18.4556C19.8582 18.3203 19.7755 18.1974 19.6714 18.0942ZM2.22151 8.88603C2.22151 7.56791 2.61238 6.2794 3.34468 5.18342C4.07699 4.08745 5.11785 3.23324 6.33563 2.72882C7.55341 2.22439 8.89342 2.09241 10.1862 2.34957C11.479 2.60672 12.6665 3.24145 13.5986 4.1735C14.5306 5.10555 15.1653 6.29306 15.4225 7.58585C15.6796 8.87864 15.5477 10.2186 15.0432 11.4364C14.5388 12.6542 13.6846 13.6951 12.5886 14.4274C11.4927 15.1597 10.2041 15.5505 8.88603 15.5505C7.11849 15.5505 5.42334 14.8484 4.1735 13.5986C2.92366 12.3487 2.22151 10.6536 2.22151 8.88603Z"
                                fill="#ABAFB1" />
                        </svg>
                    </div>
                    <div class="flex items-center;">
                        <button class="ml-2 flex pl-5 pr-5 text-gray-400 justify-between" (mousedown)="toggleMenu()">
                            <span>{{ 'ModuleManager.typeModules' | transloco }} {{ selectedTemplate }} </span>
                            <svg class="mt-1 ml-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                viewBox="0 0 16 16" fill="none">
                                <path
                                    d="M11.5289 5.52892C11.7893 5.26857 12.2114 5.26857 12.4717 5.52892C12.7321 5.78927 12.7321 6.21138 12.4717 6.47173L8.47173 10.4717C8.21934 10.7241 7.81295 10.7329 7.54984 10.4918L3.54984 6.82509C3.27843 6.5763 3.26009 6.15459 3.50889 5.88317C3.75768 5.61176 4.1794 5.59343 4.45081 5.84222L7.98027 9.07757L11.5289 5.52892Z"
                                    fill="#ABAFB1" />
                            </svg>
                        </button>
                        <div id="menu-filter" [ngClass]="{'hidden': !turn}"
                        class="w-auto absolute p-3 z-50 ml-10 mt-8 font-dm-sans font-bold bg-white border border-gray-400 shadow-2xl rounded-2xl flex-col items-start cursor-pointer"
                        [class.hidden]="!showMenu">
                        <ng-container *ngFor="let templateName of templateNames; let i = index">
                            <span class="flex justify-between items-center w-full px-2 py-1 hover:bg-green-600 hover:rounded hover:text-white"
                                id="filter-option" 
                                [class.selected]="selectedTemplate === templateNames[i].name"
                                [ngClass]="{ 'bg-green-600 rounded text-white': selectedTemplate === templateNames[i].name }"
                                (click)="onTemplateSelect(templateNames[i].id)">
                                {{ templateName.name }}
                                <svg *ngIf="selectedTemplate === templateNames[i].name" xmlns="http://www.w3.org/2000/svg"
                                    width="10" height="8" viewBox="0 0 10 8" fill="none">
                                    <path d="M1 4.00001L3.66686 6.5L9 1.5" stroke="#ffffff" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </span>
                        </ng-container>
                    </div>
                    </div>

                    <div class="ml-auto flex pr-7 gap-3" *ngIf="jsonForm">
                        <button (click)="toggleModuleStatus('enable')"
                            class="active bg-gray-200 h-8 w-32 text-center rounded-lg enable-button"
                            [ngClass]="{'bg-green-100 border border-green-600 text-green-600': jsonForm.get('status')?.value === 'enable'}">
                            <span class="flex items-center justify-center mt-1">{{'ModuleManager.activados' |
                                transloco}}</span>
                        </button>
                        <button (click)="toggleModuleStatus('disabled')"
                            class="noActive active bg-gray-200 h-8 w-32 text-center rounded-lg disable-button"
                            [ngClass]="{'bg-red-100 border border-red-600 text-red-600': jsonForm.get('status')?.value === 'disabled'}">
                            <span class="flex items-center justify-center mt-1">{{'ModuleManager.desactivados' |
                                transloco}}</span>
                        </button>
                    </div>

                </form>
            </div>
        </form>

        <div class="card grid grid-cols-3 gap-8 mt-9">
            <ng-container *ngFor="let module of moduleList; let i = index">
                <div class="cardMain bg-white shadow-md w-full h-80 rounded-xl mb-[58px] md:w-80 lg:w-full">
                    <div class="header-card flex justify-between items-center pt-3 pl-5 pr-5">
                        <div class="first-icon shadow-lg w-11 h-11 flex items-center justify-center rounded-sm mr-4">
                            <img [src]="getIconUrl(module.icon)" alt="">
                        </div>
                        <div class="status">
                            <button (click)="menuAcviteNot(module.id.toString())"
                                [ngClass]="{'bg-green-100 text-green-600': module.status === 'enable', 'bg-red-100 text-red-600': module.status === 'disabled'}"
                                class="active hover:shadow-xl h-8 w-28 text-center rounded-xl">
                                <span class="flex items-center justify-center">{{ module.status === 'enable' ? 'Activo'
                                    : 'Inactivo' }}
                                    <svg class="ml-4" xmlns="http://www.w3.org/2000/svg" width="10" height="6"
                                        viewBox="0 0 10 6" fill="none">
                                        <path [attr.stroke]="module.status === 'enable' ? '#13B977' : '#D62B2B'"
                                            d="M9.19981 0.900391L4.9998 5.10039L0.799805 0.900391" stroke-width="1.2"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </span>
                            </button>


                            <div [class.hidden]="!menus[module.id]"
                                class="w-36 absolute p-3 gap-1 -mb-3 mt-3 font-dm-sans font-bold bg-white border border-gray-400 shadow-2xl rounded-2xl flex flex-col items-start cursor-pointer">
                                <span
                                    class="flex justify-between items-center px-2 w-full hover:bg-green-600 hover:rounded hover:text-white "
                                    [class.selected]="selectedStatus === 'enable'"
                                    [ngClass]="{ 'bg-green-600 rounded text-white': selectedStatus === 'active' }"
                                    (click)="updateStatusModule(module.id, 'enable')">
                                    {{ 'ModuleManager.active' | transloco }}
                                </span>
                                <span
                                    class="flex justify-between items-center w-full px-2 hover:bg-red-600 hover:rounded hover:text-white"
                                    [class.selected]="selectedStatus === 'disable'"
                                    [ngClass]="{ 'bg-red-600 rounded text-white': selectedStatus === 'deactivate' }"
                                    (click)="updateStatusModule(module.id, 'disabled')">
                                    {{ 'ModuleManager.deactivate' | transloco }}
                                </span>
                            </div>

                        </div>

                    </div>

                    <div class="second-Main flex flex-col items-baseline pt-4 pb-4 pl-4 pr-4">
                        <span class="text-black font-dm-sans text-base font-bold leading-6">{{ module.name }}</span>
                        <span class="text-black font-inter text-sm font-normal tracking-tighter">
                            <span class="text-[#87888C] font-inter pr-1 text-base font-medium leading-6">{{
                                'ModuleManager.position' | transloco }}: <span class="text-black">{{
                                    moduleList[i]['section']['position'] }}</span>
                            </span>
                        </span>
                    </div>



                    <div class="bg-gray-200 h-48 flex justify-center items-end ">
                        <div class="p-4 flex justify-center items-center max-w-full h-full relative">
                            <img class="max-w-full max-h-full object-cover transition duration-300"
                                src="{{module.thumbnail}}"
                                alt="">
                                <div class="absolute top-0  w-96 h-full flex justify-center items-center opacity-0 hover:opacity-100 transition duration-300 backdrop-filter hover:backdrop-blur-md">
                                    <button (click)="editModule(module.id)" class="bg-blue-500 text-white py-2 px-4  flex gap-2 border-2 rounded-full  mx-auto">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M10 18H21M3 18H6M6 18V20M6 18V16M20 12H21M3 12H16M16 12V14M16 12V10M14 6H21M3 6H10M10 6V8M10 6V4" stroke="#FDFDFD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        {{ 'ModuleManager.editModule' | transloco}}
                                    </button>
                       
                            </div>
                        </div>
                    </div>


                    <div
                        class="shadow-xl bg-white flex justify-between items-center pl-4 h-12 rounded-bl-xl rounded-br-xl">
                        <div class="flex items-center">
                            <div class="text-[#87888C] font-inter text-base font-normal leading-4 mr-2">{{editorNotSpecialCharacters(module.editor)}}</div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none"
                                class="text-[#87888C]">
                                <circle cx="2" cy="2" r="2" fill="#87888C" />
                            </svg>
                            <div class="ml-2 text-[#87888C] font-inter text-base font-normal leading-4">{{module.updated_at}}</div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>