import { Component, Input } from '@angular/core';
import { AuthService } from 'src/app/core/services';
import { StorageService } from 'src/app/core/services/storage.service'; // Importa el StorageService
import { environment as env } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  @Input() title: string | undefined;
  user: any; 
  version!: string;

  constructor(private logoutSrv: AuthService, private storageService: StorageService) { 
    this.getUser();
    this.version = env.release;
  }

  getUser() {
    let user = this.storageService.decryptAndGetObject('user');
    if (user?.user) {
      user = user.user;
    }
  
    if (user?.email) {
      const index = Math.min(user.email.indexOf('.'), user.email.indexOf('@'));
      if (index !== -1) {
          user.email = user.email.substring(0, index);
      }
  
      user.email = user.email.charAt(0).toUpperCase() + user.email.slice(1) + '!';
      this.getUser = user.email;
    }
  }
  

  logout() {
    this.logoutSrv.logout()
  }
}