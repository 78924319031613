import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { BasicTableIrisModule, BuscadorModule, ButtonsIrisModule, HeaderModule, InputFileModule, InputIrisModule, NavbarModule, PaginadorIrisModule, TextAreaModule } from 'iris-front';
import { SharedModule } from '../shared/shared.module';
import { CreateGalleryComponent } from './create-gallery/create-gallery.component';
import { HomeGalleryComponent } from './home-gallery/home-gallery.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [ CreateGalleryComponent, HomeGalleryComponent],
  imports: [RouterModule, HeaderModule, NavbarModule, BasicTableIrisModule, PaginadorIrisModule, CommonModule, BuscadorModule, ButtonsIrisModule, TranslocoModule, SharedModule, InputIrisModule, InputFileModule, TextAreaModule, FormsModule, ReactiveFormsModule, TextAreaModule],
})
export class GalleryModule { }
