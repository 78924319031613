import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { map,tap, Subscription, distinctUntilChanged } from 'rxjs';
import { Roles } from 'src/app/models/roles';
import { AuthService } from '../../services';
import { HasRoleGuard } from '../has-role.guard';

@Directive({
  selector: '[ShowForRoles]'
})
export class ShowForRolesDirective implements OnInit, OnDestroy{
  @Input('ShowForRoles') allowedRoles?: any[];

  constructor(private authService: AuthService, private hasRole: HasRoleGuard, private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>  
    ) { }
  private sub?: Subscription;

  ngOnInit(): void {
      this.sub = this.authService.isLoggedIn$.pipe(
        map(() => Boolean(this.allowedRoles?.includes(this.hasRole.role))),
        distinctUntilChanged(),
        tap((hasRole) => hasRole ? this.viewContainerRef.createEmbeddedView(this.templateRef) : this.viewContainerRef.clear())
      ).subscribe();
  }
  

  ngOnDestroy(): void {
     this.sub?.unsubscribe();
  }

}
