import { Injectable } from '@angular/core';
import { environment as env } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AddArticle, Article, DeleteArticle, Response } from 'src/app/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  private baseUrl = `${env.apiCerberoUrl}`;

  constructor(private http: HttpClient) { }

  getListArticles(): Observable <Article[]>{

    return this.http.get<Response>(`${this.baseUrl}/request?event=seo-article-home`).pipe(
      map((response: Response) => {
        if (response.code === "200") {
          return response.data as Article[];
        } else {
          throw new Error('Error en la respuesta del servicio.');
        }
      })
    );
  }

  createArticle(article : AddArticle){
    return this.http.post<Response>(`${this.baseUrl}/request?event=add-seo-article-home`, article);
  }

  deleteArticle(deleteArticle : DeleteArticle){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: deleteArticle,  
    };
    return this.http.delete<any>(`${this.baseUrl}/request?event=remove-seo-article-home`, httpOptions);
  }
}
