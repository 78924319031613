import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Create, createImage } from 'src/app/models/create-template';
import { environment as env } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CreateTemplateService {
  private apiKey =`${env.apiKey}`;
  private baseUrl = `${env.apiCerberoUrl}`;
  private _create: Create | null = null;

  constructor(private http: HttpClient) { }

  createTemplate(data:any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/request?event=create-template`, data);
  }

  uploadImages(file: any): Observable<any> {
    const app = this.apiKey;
    const url = `${this.baseUrl}/upload-file`;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('app', app);
    return this.http.post<any>(url, formData);
  }

  obtenerPulzoRevolution(): Observable<Create> {
    return this.http.get<Create>(`${this.baseUrl}/ruta-del-endpoint/view_pulzo_revolution`);
  }
}
