import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeSeoComponent } from './home-seo/home-seo.component';
import { 
  BasicTableIrisModule, 
  BuscadorModule, 
  ButtonsIrisModule, 
  HeaderModule, 
  InputFileModule, 
  InputIrisModule, 
  NavbarModule, 
  PaginadorIrisModule, 
  TextAreaModule 
} from 'iris-front';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [
    HomeSeoComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslocoModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HeaderModule,
    NavbarModule,
    BasicTableIrisModule,
    PaginadorIrisModule,
    BuscadorModule,
    ButtonsIrisModule,
    InputIrisModule,
    InputFileModule,
    TextAreaModule,
    SharedModule
  ]
})
export class SeoModule { }
