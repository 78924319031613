import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment as env } from 'src/environments/environment'

@Injectable({ providedIn: 'root' })
export class UploadImageService {
  private baseUrl = `${env.apiCerberoUrl}`;

  constructor(private httpClient: HttpClient) {}

  uploadImageS3(file: File): Observable<any>{
    const formData = new FormData();

    formData.append('file', file, file.name)
    formData.append('app', "GalleryApp")

    return this.httpClient.post<any>(`${this.baseUrl}/upload-file`, formData);
  }
  
}
