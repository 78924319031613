import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sidebar-coordinator',
  templateUrl: './siderbar.component.html',
  styleUrls: ['./siderbar.component.css']
})
export class SiderbarCoordinatorComponent {
  @Input() optionMenu: string | undefined;
  sider!: boolean;

  showSiderbar() {
    if (this.sider) {
      this.sider = false;
    } else {
      this.sider = true;
    }
  }

  
  navigateToSlack(){
    window.open('https://pulzoworkspace.slack.com/archives/CUB8B5YJX')
  }
}
