import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  sharedDataForDelete: any
  private modals: any[] = [];

  constructor() { }

  add(modal: any) {
    this.modals.push(modal);
  }

  remove(id: string) {
    this.modals = this.modals.filter((x) => x.id !== id);
  }

  setData(data: any): void {
    console.log("Voy a Delete:", data);
    this.sharedDataForDelete = data;
  }
  getData(): any {
    return this.sharedDataForDelete;
  }


}









    // private titleSubject = new BehaviorSubject<string>('Eliminar'); 
  // private textInformSubject = new BehaviorSubject<string>('¿Estás seguro de que deseas eliminar?'); 
  // private placeholderSubject = new BehaviorSubject<string>('Escribe aquí...'); 
  // private btnTextSubject = new BehaviorSubject<string>('Eliminar'); 

  // title$ = this.titleSubject.asObservable();
  // textInform$ = this.textInformSubject.asObservable();
  // placeholder$ = this.placeholderSubject.asObservable();
  // btnText$ = this.btnTextSubject.asObservable();
//   updateModalTitle(title: string) {
//     this.titleSubject.next(title);
//   }

//   updateModalTextInform(text: string) {
//     this.textInformSubject.next(text);
//   }

//   updateModalPlaceholder(placeholder: string) {
//     this.placeholderSubject.next(placeholder);
//   }

//   updateModalBtnText(btnText: string) {
//     this.btnTextSubject.next(btnText);
//   }