import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TableService } from 'src/app/core/services';
import { PulzoHubService, StorageService } from 'src/app/core/services';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-home-gallery',
    templateUrl: './home-gallery.component.html',
    styleUrls: ['./home-gallery.component.css']
})
export class HomeGalleryComponent {

@Input() optionMenu: string | undefined;
@Output() paginacion = new EventEmitter<number>();
listingItems: any[] = [];
page!: any;
showPagination: boolean = true;
openTab = 1;
pages!: number;
indexes!: number[];
activeUser: any;

constructor(
    private router: Router,
    private tableService: TableService,
    private pulzoHubService: PulzoHubService,
    private storageService: StorageService,

) {
    const user = this.storageService.decryptAndGetObject('user');
    if (user) {
        this.pulzoHubService.setPulzoHub(user._id);
    }
}

ngOnInit(): void {
    this.showSwalWaiting();

    setTimeout(() => {
        this.displayItemsOnTable(1)
    }, 1000);
}

    displayItemsOnTable(pag: number) {
        const requesArticle: Observable<any> = this.tableService.tableListing(pag);

        requesArticle.subscribe((response) => {

            this.listingItems = response.items;
            this.page = {
                initial_item: response.initial_item,
                end_item: response.end_item,
                page: response.page,
                total_register: response.total_register,
            };
            Swal.close();
        });

    }

    edit(id: number) {
        this.router.navigate(['/create-gallery', { id: id }]);
    }


    private showSwalWaiting(title: string = 'Espere por favor...') {
        Swal.fire({
            title: title,
            didOpen: () => {
                //Swal.showLoading()
            },
            allowOutsideClick: false,
        });
    }

}
