import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeLinksListComponent } from './home-links-list/home-links-list.component';
import { SharedModule } from '../shared/shared.module';
import { CreateLinkListComponent } from './create-link-list/create-link-list.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditLinkListComponent } from './edit-link-list/edit-link-list.component';



@NgModule({
  declarations: [
    HomeLinksListComponent,
    CreateLinkListComponent,
    EditLinkListComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule

  ]
})
export class LinksListModule { }
