import { Component } from '@angular/core';
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.css']
})
export class DeleteModalComponent {

  receivedData: any [] = [];
  functionReceived: string = '';
  title: string = '';
  description: string = '';
  placeholder: string = '';
  buttonName: string = '';


  constructor(private modaleService: ModalService) {
    
    this.receivedData = this.modaleService.getData();
    console.log("LLEGUE AL ELIMINAR");
    if(!this.receivedData){
      this.receivedData=[];
    }
    console.log("recibe", this.receivedData)
    //@ts-ignore
    this.functionReceived = this.receivedData.function;
    //@ts-ignore
    this.title = this.receivedData.title;
    //@ts-ignore
    this.description = this.receivedData.description;
    //@ts-ignore
    this.placeholder = this.receivedData.placeholder;
    //@ts-ignore
    this.buttonName = this.receivedData.nameButton;


 }

 eliminarModule(){

 }

 callFunction(){
  if(this.functionReceived == "eliminarModule"){
    this.eliminarModule();
  }
 }
 
}


  // title: string = 'Eliminar módulo'; 
  // textInform: string = 'Si tienes la seguridad de eliminar el módulo definitivamente escribe ‘eliminar’ y haz clic en el botón rojo. Recuerda que esta acción es irreversible, no se puede deshacer.'; 
  // placeholder: string = 'Escribe ‘eliminar’';
  // btnText: string = 'Eliminar usuario';
  //   this.modalService.title$.subscribe(title => {
    //     if (!this.title) {
    //       this.title = title;
    //     }
    //   });
    
    //   this.modalService.textInform$.subscribe(textInform => {
    //     if (!this.textInform) {
    //       this.textInform = textInform;
    //     }
    //   });
    
    //   this.modalService.placeholder$.subscribe(placeholder => {
    //     if (!this.placeholder) {
    //       this.placeholder = placeholder;
    //     }
    //   });
    
    //   this.modalService.btnText$.subscribe(btnText => {
    //     if (!this.btnText) {
    //       this.btnText = btnText;
    //     }
    //   });
    // }
    
    // updateModalTitle(newTitle: string) {
    //   this.modalService.updateModalTitle(newTitle);
    // }
    
    // updateModalTextInform(newText: string) {
    //   this.modalService.updateModalTextInform(newText);
    // }
    
    // updateModalPlaceholder(newPlaceholder: string) {
    //   this.modalService.updateModalPlaceholder(newPlaceholder);
    // }
    
    // updateModalBtnText(newBtnText: string) {
    //   this.modalService.updateModalBtnText(newBtnText);
