<app-back-header link='/home-gallery'></app-back-header>

<div class="md:flex mt-8 w-full rounded-2xl mb-10 pl-3 pr-3">

    <div class="flex md:w-1/2 md:mr-2">
        <form [formGroup]="formPrueba" (ngSubmit)="onPhotoSubmit()" class="bg-white w-full pt-8 px-10 pb-8 rounded-2xl">
            <div class="md:flex md:justify-between">
                <div class="mb-5 md:mb-20">
                    <label class="tracking-widest text-sm uppercase">Seleccionar fotos para la galería</label><br>
                </div>

                <div class="mb-5 md:mb-0 lg:mr-3">
                    <!-- <lib-buttons-iris typeValue="savePhoto" body="Publicar" class="ml-10" (childButtonClick)="clearPhotoPreview()"></lib-buttons-iris> -->
                </div>
            </div>

            <div class="ml-4 md:flex md:justify-between">
                <div class="md:w-2/5">
                    <span class="tracking-widest text-sm uppercase">Desktop</span>
                    <div class="w-full h-52 mt-5 bg-gray-200">
                        <img style="max-height: 200px;" [src]="srcImageDesktop" />
                        <i *ngIf="!srcImageDesktop"
                            class="far fa-image fa-2x flex justify-center py-24 text-gray-400"></i>
                    </div>

                    <div class="w-full h-12 border border-gray-400 hover:bg-gray-100 rounded-md">
                        <div id="div_file">
                            <label class="flex justify-center text-xs text-gray-500"><i
                                    class="fas fa-upload text-green-MainColor mr-2"></i>Subir imágen</label>
                            <input type="file" formControlName="fileDesktop"
                                (change)=" handleFileInput($event); captureFile($event)" name="imagen" id="desktop"
                                style="position: absolute; top:0px; left:0px; right:0px; bottom:0px; width:80%; height:100%; opacity: 0"
                                class="ml-20 text-xs text-gray-500 cursor-pointer">
                        </div>
                    </div>
                </div><br><br>

                <div class="md:ml-20 md:w-2/5">
                    <span class="tracking-widest text-sm uppercase">Mobile</span>
                    <div class="w-full h-52 mt-5 bg-gray-200">
                        <img style="max-height: 200px;" [src]="srcImageMobile" />
                        <i *ngIf="!srcImageMobile"
                            class="far fa-image fa-2x flex justify-center py-24 text-gray-400"></i>
                    </div>

                    <div class="w-full h-12 border border-gray-400 hover:bg-gray-100 rounded-md">
                        <div id="div_file">
                            <label class="flex justify-center text-xs text-gray-500"><i
                                    class="fas fa-upload text-green-MainColor mr-2 cursor-pointer"></i>Subir
                                imágen</label>
                            <input type="file" formControlName="fileMobile"
                                (change)="handleFileInput($event); captureFile($event)" name="imagen" id="mobile"
                                style="position: absolute; top:0px; left:0px; right:0px; bottom:0px; width:80%; height:100%; opacity: 0"
                                class="ml-20 text-xs text-gray-500 cursor-pointer">
                        </div>
                    </div>
                </div><br><br>
            </div>
            <div class="grid-cols-2 gap-4">
                <div class="md:grid md:grid-rows-3 md:gap-2 mt-6">
                    <div class="w-full">
                        <label class="tracking-widest text-sm uppercase">Título de la foto</label><br>
                        <div class="md:flex mt-4 mb-6">
                            <div class="w-full mt-2 md:inline-block">
                                <input #input formControlName="photoTitle" placeholder="Título de la foto"
                                    class="w-full h-9 rounded-3xl pl-4 font-thin border focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black"
                                    ngDefaultControl>
                            </div>
                        </div>
                    </div>

                    <div class="mt-4 md:mt-0">
                        <label class="tracking-widest text-sm uppercase">Alt</label><br>
                        <div class="flex flex-col mt-4">
                            <div class="w-full mt-2 md:inline-block">
                                <input #input formControlName="alt" placeholder="Alt"
                                    class="w-full h-9 rounded-3xl pl-4 font-thin border focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black"
                                    ngDefaultControl>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4 md:mt-0">
                        <label class="tracking-widest text-sm uppercase">Créditos</label><br>
                        <div class="flex flex-col mt-4">
                            <div class="w-full mt-2 md:inline-block">
                                <input #input formControlName="credits" placeholder="Créditos"
                                    class="w-full h-9 rounded-3xl pl-4 font-thin border focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black"
                                    ngDefaultControl>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="md:grid pt-1 mt-4 mb-4">
                    <div class="mt-3 md:mt-0">
                        <label class="tracking-widest text-sm uppercase">Descripción</label><br>
                        <div class="flex flex-col mt-4">
                            <div class="w-full mt-2 md:inline-block">

                                <textarea name="descripcion"
                                    class="h-32 font-thin rounded-lg w-full pl-2 border border-gray-400 focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black"
                                    placeholder="Descripción" value="descripcion" formControlName="description"
                                    ngDefaultControl></textarea>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="md:grid pt-1 mt-4 mb-4 justify-end">
                    <lib-buttons-iris typeValue="savePhoto" body="Publicar" class="justify-end"
                        (childButtonClick)="clearPhotoPreview()"></lib-buttons-iris>
                </div>
            </div>
        </form>
    </div>

    <div class="flex md:w-1/2 mt-3 md:mt-0">

        <form [formGroup]="formGallery" (ngSubmit)="submitGalleryForm()"
            class="bg-white w-full pt-8 pl-10 pr-8 rounded-2xl border-l-[1px] pb-10">

            <div class="md:flex md:justify-between">
                <div class="">
                    <label class="tracking-widest text-sm uppercase">Título de la galería</label><br>
                </div>

                <ng-template #elseBlock>
                    <div class="mt-4 md:mt-0">
                        <lib-buttons-iris typeValue="generic" body="Agregar"></lib-buttons-iris>
                    </div>
                </ng-template>

                <div *ngIf="this.item !== null; else elseBlock" class="flex flex-col">
                    <button
                        class="bg-[#038652] duration-500 hover:bg-[#038652E6] items-center mt-4 md:mt-0 md:w-40 p-1.5 rounded-full text-sm text-white w-full">
                        Actualizar galería
                    </button>
                </div>
            </div>

            <div class="w-full mt-6 md:inline-block">
                <input #input formControlName="name" placeholder="Título de la galería"
                    class="w-full h-9 rounded-3xl pl-4 font-thin border focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black"
                    ngDefaultControl>
            </div>
            <div class="bg-white flex flex-wrap items-center justify-center p-14"
                [ngClass]="{'hidden': formData.length > 0, 'block': formData.length === 0}">


                <div class="w-full flex-none p-4 text-left lg:w-3/6">
                    <p class="text-dark-light text-2xl font-semibold my-4">{{ 'dashboard.main.empty_photo' |
                        transloco }}</p>
                    <p class="text-dark-light text-lg font-normal my-4">{{ 'dashboard.main.empty_table' |
                        transloco }}</p>
                </div>

                <div class="w-full flex-none lg:flex lg:w-3/6">
                    <img class="mx-auto" src="./assets/Group88.png" width="360px" height="290px" alt="No resultados">
                </div>
            </div>

            <div class="flex flex-row mt-6 ">
                <table class="bg-white w-full rounded-lg overflow-hidden divide-light-light mt-10">
                    <thead class="border-b-[20px] border-b-[#FFFFFF]">
                        <tr>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let photos of formData; let i = index"
                            class="text-dark hover:bg-[#F2F2F5] text-center h-12">
                            <td class="border-[1px] border-l-0">
                                <p class="mr-2">{{i + 1}}</p>
                            </td>
                            <td class="border-[1px] border-l-0 w-20 ">
                                <div class="w-full h-full bg-gray-200">
                                    <img src={{photos.pathMobile}} />
                                </div>
                            </td>

                            <td class="border-[1px] border-l-0">
                                {{photos.title}}
                            </td>
                            <td class="border-[1px] border-r-0">
                                <i class="fa fa-solid fa-pencil text-green-MainColor mr-4 cursor-pointer ml-3"
                                    (click)="edit(photos['id'], i)"></i>
                                <i *ngIf="this.item === null" class="fa fa-solid fa-trash cursor-pointer hover:text-red"
                                    (click)="deletePhotoFromArray(i)"></i>
                                <i *ngIf="this.item !== null" class="fa fa-solid fa-trash cursor-pointer hover:text-red"
                                    (click)="deletePhotoFromGallery(photos.id, i)"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </form>
    </div>
</div>