import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ListLinkService } from 'src/app/core/services/list-link.service';
import { DeleteList, LinkDataItem, ListLinks } from 'src/app/models/list-link';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-home-links-list',
  templateUrl: './home-links-list.component.html',
  styleUrls: ['./home-links-list.component.css']
})
export class HomeLinksListComponent implements OnInit {
  dataLinks: LinkDataItem[] =[];

  constructor(private srvListLink: ListLinkService, private router: Router){}

  ngOnInit(): void {
    this.list();
  }

  list(){
    this.srvListLink.getListLink().subscribe((response: ListLinks)=> {
      this.dataLinks = response.data;
      //console.log('srv get',  this.dataLinks);
    })
  }

  edit(id:number){
    this.router.navigate(['/edit-list'], { queryParams: { id: id } });
  }

  delete(id:number){
    const body: DeleteList = { id: id };

    Swal.fire({
      text: '¿Está seguro de eliminar esta lista?',
       icon: 'warning',
       showCancelButton: true,
       confirmButtonText: 'Aceptar',
       cancelButtonText: 'Cancelar',
     }).then((confirmDelete) => {
      if (confirmDelete.isConfirmed) {
        this.srvListLink.deleteListLink(body).subscribe(()=> {
           Swal.fire({
             text: 'Se ha eliminado la lista con éxito',
             icon: 'success',
           }).then(() => {
            window.location.reload();
          });
        });
      }
    });
  }

}
