<app-header title="{{'seo.title' | transloco }}"></app-header>

<div class="flex mt-2">
    <app-sidebar optionMenu="4"></app-sidebar>

    <div class="bg-white rounded-2xl shadow-md mx-auto mt-8 w-3/4 h-3/4 flex flex-col">
       
        <h2 class="font-bold text-3xl my-10 ml-10">{{ 'seo.titleHome' | transloco }}</h2>

        <form [formGroup]="inputUrlForm" class="grid grid-cols-2 gap-4 " (ngSubmit)="addArticle()">
            <div class="grid grid-rows-2 gap-2 ml-10">
                <label class="uppercase text-sm ml-1 mt-2">Url artículo</label>
                <input type="text" formControlName="url"  class="py-2 w-full h-9 text-base rounded-xl pl-4 border border-dark-light  focus:ring-1 focus:outline-none focus:border-transparent focus:ring-dark-light">
            </div>
            <button type="submit" class="w-full h-8 shadow-lg rounded-xl text-xs text-white bg-green ml-10 md:float-none mt-10 px-20 md:w-40 md:px-5"> {{'globals.addArticle' | transloco }}</button>
        </form>    
       
        <div class="my-10 mx-auto w-11/12 border-b"></div>

        <div class="p-10" [ngClass]="{hidden: articlesList.length > 0, block: articlesList.length === 0}">
            <p class="text-dark-light text-2xl font-semibold my-4">
                {{ "seo.empty_articles" | transloco }}
            </p>
        </div>

        <div class="grid grid-cols-4 gap-2 mb-8" [ngClass]="{block: articlesList.length > 0, hidden: articlesList.length === 0}">
            <div class="mx-auto w-52" *ngFor="let article of articlesList, let i = index">
                <img [src]="article.data.images.medio" alt="" class="bg-black h-28 rounded-xl">
                <p class="uppercase text-green-MainColor text-sm font-semibold my-2">{{article.data.categories.main.name}}</p>
                <p class="h-1/4 text-sm font-semibold">{{article.data.title}}</p>
                <button (click)="deleteArticle(i+1)" class="w-full shadow-lg rounded-xl my-4 border-red text-red text-xs border py-2 px-20 md:mr-9 md:px-5 mt-7">Borrar</button>
            </div>
        </div>
     
    </div>    
</div>