import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeModulesComponent } from './home-modules/home-modules.component';
import { CreateModuleComponent } from './create-module/create-module.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ListTemplateComponent } from './new-template/list-template.component';
import { NewModuleComponent } from './new-module/new-module.component';
import { DuplicateTemplateComponent } from './home-modules-coordinador/duplicate-template.component';
import { EditTemplateComponent } from './edit-template/edit-template.component';
import { EditModuleComponent } from './edit-module/edit-module.component';
import { FirstNoteScrollComponent } from './first-note-scroll/first-note-scroll.component';



@NgModule({
  declarations: [
    HomeModulesComponent,
    CreateModuleComponent,
    ListTemplateComponent,
    NewModuleComponent,
    DuplicateTemplateComponent,
    EditTemplateComponent,
    EditModuleComponent,
    FirstNoteScrollComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDropzoneModule
  ]
})
export class ModuleManagerModule { }
