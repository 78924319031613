import { Component, OnInit } from '@angular/core';
import { ModuleManagementService } from 'src/app/core/services/module-management.service';
import { receivedData } from 'src/app/models/create-template';
import Swal from 'sweetalert2';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { AuthService } from 'src/app/core/services';
import { HttpClient } from '@angular/common/http';
import { CreateTemplateService } from 'src/app/core/services/create-template.service';
import { elementAt } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { moduleStructure, TemplateStructure, allowedPositions, openModalUploadImage } from 'src/app/models';



@Component({
  selector: 'app-edit-module',
  templateUrl: './edit-module.component.html',
  styleUrls: ['./edit-module.component.css']
})
export class EditModuleComponent{
  formCreateModule!: FormGroup;
  process:string = "ubicacion";
  arrayPath:string[] = [];
  nameImage:string = "";
  descriptionImage:string = "";
  copyrightImage:string = "";
  authorImage:string = "";
  tags:string = "";
  selectInfo:any[]=[];
  selectElements:any[]=[];
  selectedOption:string = "";
  arrayNewModule:any[] = [];
  name:string = "";
  dateNow:string = "";
  editor:string = "";
  structure:string = "";
  sections:any;
  specialSections:any;
  data:any;
  sectionsNews: string[] = [
    "pulzo-futuro",
    "especiales"
  ];

  // Images

  selectedFiles: File[] = [];

  imageErrors = {
    errorMsg: false,
    errorMsgForMB:false,
    errorMsgForFormat:false,
    namesFilesErrorMB:[],
    namesFilesErrorFormat:[],
    namesErrorToShowMB: "",
    namesErrorToShowFormat: "",
    limitInMB:3,
    minWidth:1024
  }

  // for edit
  idReceived:number = -1;
  moduleStructure:moduleStructure;
  moduleStructureCopy:moduleStructure;
  templateStructure:TemplateStructure;
  cantOfValues:number = 0;
  modalOpenUploadImage:openModalUploadImage;

  constructor(private route: ActivatedRoute, private moduleService: ModuleManagementService, private fB:FormBuilder, private router: Router,
  private http: HttpClient, private authService:AuthService,  private serviceTemplate: CreateTemplateService){
    this.selectInfo = allowedPositions;

    this.formCreateModule = this.fB.group({
      name: ['', Validators.required],
      created_at: [''],
      updated_at: [''],
      editor: ['', Validators.required],
      template_id: [0, Validators.required],
      location: this.fB.group({
        position: ['P1'], 
        sections: [[],Validators.required]
      }),
      properties: [[], Validators.required]
    });

    this.modalOpenUploadImage = {
      open:false,
      modalToOpen:""
    }

    this.selectInfo = [
      {
        "name":"P1",
        "text":"P1: Arriba del opening"
      },
      {
        "name":"P2",
        "text":"P2: Debajo del opening"
      },
      {
        "name":"P3",
        "text":"P3: Arriba de la grilla"
      },
      {
        "name":"P4",
        "text":"P4: Debajo de la grilla"
      },
      {
        "name":"P5",
        "text":"P5: Módulo 1"
      },
      {
        "name":"P6",
        "text":"P6: Módulo 2"
      }
    ]

    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      this.idReceived = id;
    });

    
    this.fetchDataGetModuleByID().then(() => { });
  }





  async fetchDataGetModuleByID(): Promise<void> {
    try {
        const response = await this.moduleService.getModuleByID(this.idReceived).toPromise();
        this.moduleStructure = response.data;
        this.moduleStructureCopy = response.data;

       console.log("Data Completa:", this.moduleStructure);
        if (this.moduleStructure && this.moduleStructure.template_id) {
            this.formCreateModule = this.fB.group({
                id: [this.moduleStructure?.id],
                name: [this.moduleStructure?.name, Validators.required],
                template_id: [this.moduleStructure?.id, Validators.required],
                editor: [this.editor, Validators.required],
                location: this.fB.group({
                    position: [this.moduleStructure?.section.position, Validators.required],
                    sections: [this.moduleStructure?.section.sections, Validators.required]
                }),
                properties: [this.moduleStructure?.properties, Validators.required],
                created_at: [this.moduleStructure?.created_at],
                updated_at: [this.moduleStructure?.updated_at]
            });

            for (let property of this.moduleStructure?.properties) {
                if (property.type.toLowerCase() == 'img') {
                    this.cantOfValues = property.value.length;
                }
            }

            this.formCreateModule.get('created_at')?.setValue(this.moduleStructure?.created_at);
            this.selectElements = this.moduleStructure?.properties;
            this.fetchDataSections();
            this.fetchDataSpecialSections();
            await this.fetchDataGetTemplateByID(this.moduleStructure?.template_id);
        } else {
            console.error("El objeto this.moduleStructure o su propiedad template_id no está definido.");
        }
    } catch (error) {
        console.error('Error al cargar el módulo:', error);
    }
}


  async fetchDataGetTemplateByID(id: number): Promise<void> {
    //console.log("Voy a obtener template:" + id);
    try {
        const response = await this.moduleService.getTemplateByID(id).toPromise();
        this.templateStructure =  response.data;
        //console.log("Template Structure:", this.templateStructure);
    } catch (error) {
        console.error('Error al cargar el template:', error);
    }
  }

  capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  hasEmptyProperties(properties: any[]): boolean {
    for (let property of properties) {
        if (property.value === null || property.value === undefined) {
            return true;
        } else if (Array.isArray(property.value)) {
            if(property.type.toLowerCase()=='img' &&  property.value.length!=this.cantOfValues){
              return true;
            }
            if (property.value.length === 0) {
                return true;
            }
            for (let value of property.value) {
                //console.log("value of:" + property.name + ":" + value);
                if (value === '' || value === null) {
                    return true;
                }
            }
        }
    }
    return false;
  }



  ngOnInit(): void {
    this.editor = this.authService.getUserLogged(); 

    this.formCreateModule = this.fB.group({
      id: [this.moduleStructure?.id],
      name: [this.moduleStructure?.name, Validators.required],
      template_id: [this.moduleStructure?.id, Validators.required],
      editor: [this.editor, Validators.required],
      location: this.fB.group({
          position: [this.moduleStructure?.section.position, Validators.required],
          sections: [this.moduleStructure?.section.sections, Validators.required]
      }),
      properties: [this.moduleStructure?.properties, Validators.required],
      created_at: [this.moduleStructure?.created_at],
      updated_at: [this.moduleStructure?.updated_at]
  });

  }

  getLinkLAControls(): FormArray {
    return this.formCreateModule.get('linkLA') as FormArray;
  }

  onChangeSelect(event: any) {
    this.selectedOption = event.target.value;
  } 

  changeProcess(value:string){
    this.process = value;
  }

  onSelect(event: any) {
    this.selectedFiles = event.addedFiles as File[];
    const maxSizeInMB = this.imageErrors.limitInMB;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
    const allowedExtensions = ['jpg', 'jpeg', 'JPG', 'JPEG']; 
    const filePromises: Promise<{ file: File; width: number }>[] = [];
    let mensajeMB = '';
    let mensajeFormat = '';
  
    this.imageErrors.namesErrorToShowMB = "";
    this.imageErrors.namesErrorToShowFormat = "";
    this.imageErrors.namesFilesErrorMB = [];
    this.imageErrors.namesFilesErrorFormat = [];
    this.imageErrors.errorMsgForFormat = false;

    for (const file of this.selectedFiles) {
      if (file.size > maxSizeInBytes) {
        this.imageErrors.namesFilesErrorMB.push(file.name);
      } else {
        const promise = new Promise<{ file: File; width: number }>((resolve) => {
          const reader = new FileReader();
          reader.onload = (event) => {
            const img = new Image();
            img.onload = () => {
              resolve({ file, width: img.width });
            };
            img.src = event.target?.result as string;
          };
          reader.readAsDataURL(file);
        });
        filePromises.push(promise);
      }
    }
  
    Promise.all(filePromises).then((results) => {
      for (const result of results) {
        if (result.width < this.imageErrors.minWidth) {
          this.imageErrors.namesFilesErrorFormat.push(result.file.name);
        }
      }
  
      if (this.imageErrors.namesFilesErrorMB.length > 0) {
        this.imageErrors.errorMsgForMB = true;
        mensajeMB = `Los siguientes archivos superan el tamaño máximo: <br>${this.imageErrors.namesFilesErrorMB.join(', ')} `;
      }
  
      if (this.imageErrors.namesFilesErrorFormat.length > 0) {
        this.imageErrors.errorMsgForFormat = true;
        mensajeFormat = `Los siguientes archivos tienen un formato o dimensiones no permitidas: <br>${this.imageErrors.namesFilesErrorFormat.join(', ')}`;
      }
  
    });
  }
  changeStateOpenModalUploadImage(modalToOpen: string) {
    const moduleToValidate = this.moduleStructure?.properties.find(data => data.name === modalToOpen);
    if (moduleToValidate.open) {
      moduleToValidate.open = false;
    }
    else{
      moduleToValidate.open = true;
    }

    this.moduleStructure.properties.forEach(property => {
      if(moduleToValidate.name!=property.name)
      property.open = false;
    });
  }

  fetchDataSections() {
    fetch("https://filesstaticpulzo.s3.us-west-2.amazonaws.com/pulzo-lite/sitemap/sections.json", {
      method: "GET",
      redirect: "follow"
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text(); 
      })
      .then((text) => {
        this.sections = JSON.parse(text); 
      })
      .catch((error) => console.error(error));
  }
  
  fetchDataSpecialSections() {
    fetch("https://filesstaticpulzo.s3.us-west-2.amazonaws.com/specialsSections/specialSections.json", {
      method: "GET",
      redirect: "follow"
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text(); 
      })
      .then((text) => {
        this.specialSections = JSON.parse(text); 
      })
      .catch((error) => console.error(error));
  }

  onRemove(event: any){
    this.selectedFiles.splice(this.selectedFiles.indexOf(event), 1);

      this.imageErrors = {
        errorMsg: false,
        errorMsgForMB:false,
        errorMsgForFormat:false,
        namesFilesErrorMB:[],
        namesFilesErrorFormat:[],
        namesErrorToShowMB: "",
        namesErrorToShowFormat: "",
        limitInMB:3,
        minWidth:1024
      }
  }

  debugValidation(){
    console.log('Vlidacion formulaio', this.formCreateModule?.valid);
    console.log('funcion result', this.hasEmptyProperties(this.formCreateModule?.get('properties').value));
  }

async addElement() {
  if(this.formCreateModule.get('properties')?.value){
    this.arrayNewModule =  this.formCreateModule.get('properties')?.value;
  }
  console.log("selectElements:", this.selectElements);
  const objectFounded = this.selectElements.find(item => item.name === this.selectedOption);
  const valueForReplace = this.arrayNewModule.find(item => item.name === this.selectedOption);

  let imgUrl = '';

  try {
      const response = await this.serviceTemplate.uploadImages(this.selectedFiles[0]).toPromise();
      console.log(response);
      imgUrl = response.path;
      console.log("urlImg:" + imgUrl);
      this.selectedFiles = [];

      let newObj = {
          name: '',
          type: '',
          value: ['']
      };

      if (!valueForReplace) {
          newObj = {
              name: objectFounded.name,
              type: objectFounded.type,
              value:[imgUrl] 
          };
          this.arrayNewModule.push(newObj);
          this.showAlert('Elemento ' + this.selectedOption + ' agregado exitosamente.');
      } else {
        if(valueForReplace.value.length<this.cantOfValues){
          valueForReplace.value.push(imgUrl);
        }
        else{
          this.showAlertFalse('Ya se agregaron todos los elementos para ' + this.selectedOption + ', no se pueden agregar más.');
        }
      }
     
  } catch (error) {
      console.error('Error al cargar imágenes:', error);
  }
}



// removeElement(name: string) {
//   if (this.formCreateModule.get('properties')?.value) {
//       this.arrayNewModule = this.moduleStructure?.properties;
//   }

//   const element = this.arrayNewModule.find(item => item.name === name);

//   if (element) {
//       element.value = [];
//       console.log("Valores de 'value' eliminados con éxito para el elemento:", name);
//   } else {
//       console.log("No se encontró ningún elemento para eliminar con el nombre", name);
//   }
// }

removeImage(value: string, data: any) {
  console.log("Valor a remover:", value, "Objeto:", data);
  if (Array.isArray(data)) {
      const index = data.indexOf(value);
      if (index !== -1) {
          data.splice(index, 1);
         // console.log("Elemento removido:", value);
      } else {
          console.log("El elemento", value, "no fue encontrado en el array.");
      }
  } else {
      console.log("data.value no es un array.");
  }
  //console.log("DataStructure:", this.moduleStructure);
}



  showAlert(message: string) {
    Swal.fire({
        icon: 'success',
        title: '¡Éxito!',
        text: message
    });
  }

  showAlertFalse(message: string) {
    Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: message
    });
}

  generateArray(length: number): any[] {
    return Array.from({ length });
  }

  sendInformation(){
    this.formCreateModule.get('name')?.setValue(this.moduleStructure.name);
    this.formCreateModule.get('template_id')?.setValue(this.moduleStructure.template_id);

      if(this.formCreateModule.get('properties')?.value){
        this.arrayNewModule =  this.formCreateModule.get('properties')?.value;
      }

    this.formCreateModule.get('properties')?.setValue(this.arrayNewModule);
    console.log("FormAEnviar:", this.formCreateModule.value);
    this.moduleService.UpdateModule(this.formCreateModule.value, this.moduleStructure.id).subscribe(
      (response) => {
        
        if(response.code == '201' || response.code == '200'){
        
          Swal.fire({
            icon: 'success',
            title: '¡Éxito!',
            text: "Modulo editado",
            didClose: () => {
              this.router.navigate(['/home-module']);
            }
            
          });
        }
        else{
          console.log('Respuesta del servidor erronea:', response);
        }
  
      },
      (error) => {
        console.error('Error al cargar imágenes:', error);
      }
    );
  }


  onChangeInputContent(value: any, nameMethod: string, position: number) 
  {
    if(this.formCreateModule.get('properties')?.value){
      this.arrayNewModule =  this.formCreateModule.get('properties')?.value;
    }
    const valueForReplace = this.arrayNewModule.find(item => item.name === nameMethod);
    const valueReceivedDataForReplace = this.arrayNewModule.find(item=> item.name === nameMethod);
    if (valueForReplace && position >= 0 && position < valueForReplace?.value.length) {
      valueForReplace.value[position] = value.value;
      valueReceivedDataForReplace.value[position] = value.value;
    } 
  }

  onChangeCheck(value: string, isChecked: boolean) 
  {
    const currentSeccion = this.formCreateModule.get('location.sections').value;
    
    if (isChecked) {
        currentSeccion.push(value);
       // console.log("val-checked:",this.formCreateModule.value);
    } else {
        const index = currentSeccion.indexOf(value);
        if (index !== -1) {
            currentSeccion.splice(index, 1);
            //console.log("val-checked:",this.formCreateModule.value);
        }
    }
    this.formCreateModule.patchValue({
        location: {
            sections: currentSeccion
        }
    });
  }



}
