import { Component, OnInit } from '@angular/core';
import { ModuleManagementService } from 'src/app/core/services/module-management.service';
import { receivedData } from 'src/app/models/create-template';
import Swal from 'sweetalert2';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { AuthService } from 'src/app/core/services';
import { HttpClient } from '@angular/common/http';
import { CreateTemplateService } from 'src/app/core/services/create-template.service';
import { elementAt } from 'rxjs';
import { Router } from '@angular/router';
import { allowedPositions } from 'src/app/models';

@Component({
  selector: 'app-new-module',
  templateUrl: './new-module.component.html',
  styleUrls: ['./new-module.component.css']
})
export class NewModuleComponent implements OnInit{
  formCreateModule!: FormGroup;
  process:string = "ubicacion";
  
  arrayPath:string[] = [];
  nameImage:string = "";
  descriptionImage:string = "";
  copyrightImage:string = "";
  authorImage:string = "";
  tags:string = "";
  selectInfo:any[]=[];
  selectElements:any[]=[];
  selectedOption:string = "";
  arrayNewModule:any[] = [];
  receivedData:receivedData;
  name:string = "";
  dateNow:string = "";
  editor:string = "";
  structure:string = "";
  sections:any;
  specialSections:any;
  cantOfValues:number = 0;

  selectedFiles: File[] = [];


  imageErrors = {
    errorMsg: false,
    errorMsgForMB:false,
    errorMsgForFormat:false,
    namesFilesErrorMB:[],
    namesFilesErrorFormat:[],
    namesErrorToShowMB: "",
    namesErrorToShowFormat: "",
    limitInMB:3,
    minWidth:1024
  }

  constructor(private moduleService: ModuleManagementService, private fB:FormBuilder, private router: Router,
  private http: HttpClient, private authService:AuthService,  private serviceTemplate: CreateTemplateService){
    this.selectInfo = allowedPositions

    this.formCreateModule = this.fB.group({
      name: ['', Validators.required],
      created_at: [''],
      updated_at: [''],
      editor: ['', Validators.required],
      template_id: [0, Validators.required],
      location: this.fB.group({
        position: ['P1'], 
        sections: [[],Validators.required]
      }),
      properties: [[], Validators.required]
    });
  }

  ngOnInit(): void {
    this.receivedData = this.moduleService.getData();
    console.log("ReceivedData:", this.receivedData);
    
    this.editor = this.authService.getUserLogged(); 
    
    const dateAndHourNow: Date = new Date();
    const dateNow = new Date().toString(); 
    this.dateNow = dateAndHourNow.toLocaleString();

    this.formCreateModule.get('created_at')?.setValue(dateNow);
    this.formCreateModule.get('updated_at')?.setValue(dateNow);
    this.formCreateModule.get('editor')?.setValue(this.editor);

    this.selectElements = this.receivedData?.typeModule?.properties;
    this.fetchDataSections();
    this.fetchDataSpecialSections();

    this.receivedData['typeModule']['properties'].forEach(element => {
      if (element.type === "LS" || element.type === "LA") {
        const quantity = element.quantity;
        const newArray = Array.from({ length: quantity }, () => '');

        const newObj = {
          name: element.name,
          type: element.type,
          value: newArray
        };

        this.arrayNewModule.push(newObj);
      }
      else if(element.type.toLowerCase() === "img"){
        this.cantOfValues = element.quantity;
      }
      element.value = [''];
    });

    console.log("CantOfValues:" + this.cantOfValues);

  }

  capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  getLinkLAControls(): FormArray {
    return this.formCreateModule.get('linkLA') as FormArray;
  }

  onChangeSelect(event: any) {
    this.selectedOption = event.target.value;
    console.log("Opción seleccionada:", this.selectedOption);
  } 

  changeProcess(value:string){
    this.process = value;
  }

  onSelect(event: any) {
    this.selectedFiles = event.addedFiles as File[];
    const maxSizeInMB = this.imageErrors.limitInMB;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
    const allowedExtensions = ['jpg', 'jpeg', 'JPG', 'JPEG']; 
    const filePromises: Promise<{ file: File; width: number }>[] = [];

    let mensajeMB = '';
    let mensajeFormat = '';
  
    this.imageErrors.namesErrorToShowMB = '';
    this.imageErrors.namesErrorToShowFormat = '';
    this.imageErrors.namesFilesErrorMB = [];
    this.imageErrors.namesFilesErrorFormat = [];
    this.imageErrors.errorMsgForFormat = false;
    this.imageErrors.errorMsgForMB = false;

    for (const file of this.selectedFiles) {
      if (file.size > maxSizeInBytes) {
        this.imageErrors.namesFilesErrorMB.push(file.name);
      } else {
        const promise = new Promise<{ file: File; width: number }>((resolve) => {
          const reader = new FileReader();
          reader.onload = (event) => {
            const img = new Image();
            img.onload = () => {
              resolve({ file, width: img.width });
            };
            img.src = event.target?.result as string;
          };
          reader.readAsDataURL(file);
        });
        filePromises.push(promise);
      }
    }
  
    Promise.all(filePromises).then((results) => {
      for (const result of results) {
        if (result.width < this.imageErrors.minWidth) {
          this.imageErrors.namesFilesErrorFormat.push(result.file.name);
        }
      }
  
      if (this.imageErrors.namesFilesErrorMB.length > 0) {
        this.imageErrors.errorMsgForMB = true;
        mensajeMB = `Los siguientes archivos superan el tamaño máximo: <br>${this.imageErrors.namesFilesErrorMB.join(', ')} `;
      }
  
      if (this.imageErrors.namesFilesErrorFormat.length > 0) {
        this.imageErrors.errorMsgForFormat = true;
        mensajeFormat = `Los siguientes archivos tienen un formato o dimensiones no permitidas: <br>${this.imageErrors.namesFilesErrorFormat.join(', ')}`;
      }
  
    });
    console.log("Quantity:",this.receivedData['typeModule']['properties'][0].quantity);
  }

  fetchDataSections() {
    fetch("https://filesstaticpulzo.s3.us-west-2.amazonaws.com/pulzo-lite/sitemap/sections.json", {
      method: "GET",
      redirect: "follow"
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text(); 
      })
      .then((text) => {
        this.sections = JSON.parse(text); 
      })
      .catch((error) => console.error(error));
  }
  
  fetchDataSpecialSections() {
    fetch("https://filesstaticpulzo.s3.us-west-2.amazonaws.com/specialsSections/specialSections.json", {
      method: "GET",
      redirect: "follow"
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text(); 
      })
      .then((text) => {
        this.specialSections = JSON.parse(text); 
      })
      .catch((error) => console.error(error));
  }
  

  onRemove(event: any){
    this.selectedFiles.splice(this.selectedFiles.indexOf(event), 1);
    this.imageErrors = {
      errorMsg: false,
      errorMsgForMB:false,
      errorMsgForFormat:false,
      namesFilesErrorMB:[],
      namesFilesErrorFormat:[],
      namesErrorToShowMB: "",
      namesErrorToShowFormat: "",
      limitInMB:3,
      minWidth:1024
    }
  }

async addElement() {
  
  console.log("SelectedFile:", this.selectedFiles);
  console.log("selectElements:", this.selectElements);
  const objectFounded = this.selectElements?.find(item => item.name === this.selectedOption);
  const valueForReplace = this.arrayNewModule?.find(item => item.name === this.selectedOption);

  let imgUrl = '';
  if(this.selectedFiles){
    try {
        const response = await this.serviceTemplate.uploadImages(this.selectedFiles[0]).toPromise();
        console.log(response);
        imgUrl = response.path;
        this.selectedFiles = [];
        console.log("urlImg:" + imgUrl);

        let newObj = {
            name: '',
            type: '',
            value: ['']
        };

        if (!valueForReplace) {
            newObj = {
                name: objectFounded.name,
                type: objectFounded.type,
                value:[imgUrl] 
            };
            this.arrayNewModule?.push(newObj);
            this.showAlert('Elemento ' + this.selectedOption + ' agregado exitosamente.');
            this.formCreateModule.get('properties')?.setValue(this.arrayNewModule);
            console.log("valor valid form:",this.formCreateModule?.valid);
        } else {
          console.log("ValueForReplace.length:",valueForReplace.value.length);
          if(valueForReplace.value.length<objectFounded.quantity){
            valueForReplace.value.push(imgUrl);
            console.log("Ya existe. Se agregó una imagen adicional.");
          }
          else{
            this.showAlertFalse('Ya se agregaron todos los elementos para ' + this.selectedOption + ', no se pueden agregar más.');
          }
        }
        

        console.log("Modulo a enviar:", this.arrayNewModule);
    } catch (error) {
        console.error('Error al cargar imágenes:', error);
    }
  }
}

changeStateOpenModalUploadImage(modalToOpen: string) {
  const moduleToValidate = this.formCreateModule?.value.properties?.find(data => data.name === modalToOpen);
  if (moduleToValidate.open) {
    moduleToValidate.open = false;
  }
  else{
    moduleToValidate.open = true;
  }

  this.formCreateModule?.value.properties?.forEach(property => {
    if(moduleToValidate.name!=property.name)
    property.open = false;
  });
}

removeImage(value: string, data: any) {
  //console.log("Valor a remover:", value, "Objeto:", data);
  if (Array.isArray(data)) {
      const index = data.indexOf(value);
      if (index !== -1) {
          data.splice(index, 1);
      } else {
          console.log("El elemento", value, "no fue encontrado en el array.");
      }
  } else {
      console.log("data.value no es un array.");
  }
}



  // removeElement(name:string){
  //   const index = this.arrayNewModule.findIndex(item => item.name === name);

  //   if (index !== -1) {
  //       this.arrayNewModule.splice(index, 1);
  //   } else {
  //       console.log("No se encontró ningún elemento para eliminar con el nombre", name);
  //   }    
    
  // }

  showAlert(message: string) {
    Swal.fire({
        icon: 'success',
        title: '¡Éxito!',
        text: message
    });
  }

  showAlertFalse(message: string) {
    Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: message
    });
}

  generateArray(length: number): any[] {
    return Array.from({ length });
  }

  sendInformation(){
    console.log("formToSend:",this.formCreateModule.value);

    this.moduleService.createModule(this.formCreateModule.value).subscribe(
      (response) => {
        
        if(response.code == '201' || response.code == '200'){
        
          Swal.fire({
            icon: 'success',
            title: '¡Éxito!',
            text: "Modulo creado",
            didClose: () => {
              this.router.navigate(['/home-module']);
            }
            
          });
        }
        else{
          console.log('Respuesta del servidor erronea:', response);
        }
  
      },
      (error) => {
        console.error('Error al cargar imágenes:', error);
      }
    );
  }


  onChangeInputContent(value: any, nameMethod: string, position: number) {
    const valueForReplace = this.arrayNewModule.find(item => item.name === nameMethod);
    const valueReceivedDataForReplace = this.receivedData.typeModule.properties.find(item=> item.name === nameMethod);
    if (valueForReplace && position >= 0 && position < valueForReplace.value.length) {
      valueForReplace.value[position] = value.value;
      valueReceivedDataForReplace.value[position] = value.value;
    } 
    this.formCreateModule.get('name')?.setValue(this.receivedData.name);
    this.formCreateModule.get('template_id')?.setValue(this.receivedData.typeModule.id);
  }

  getPropertyValue(values: string[], index: number): string {
    return values && values[index] !== undefined ? values[index] : '';
  }


  formatValue(value: string): string {
    if (value) {
        return value.split(',').map(v => v.trim()).join(' ');
    }
    return '';
  }



  hasEmptyProperties(properties: any[]): boolean {

    
    for (let property of properties) {
        //console.log("property:", property);
        if (property.value === null || property.value === undefined) {
           // console.log("hasEmpty: true");
            return true;
        } else if (Array.isArray(property.value)) {
            if(property.type.toLowerCase()=='img' &&  property.value.length!=this.cantOfValues){
             // console.log("hasEmpty: true");
              return true;
            }
            if (property.value.length === 0) {
                //console.log("hasEmpty: true");
                return true;
            }
            for (let value of property.value) {
                if (value === '' || value === null) {
                    //console.log("hasEmpty: true");
                    return true;
                }
            }
        }
    }
    //console.log("hasEmpty: false");
    return false;
}



  onChangeCheck(value: string, isChecked: boolean) {
    const currentSeccion = this.formCreateModule.value.location.sections;

    if (isChecked) {
        currentSeccion.push(value);
    } else {
        const index = currentSeccion.indexOf(value);
        if (index !== -1) {
            currentSeccion.splice(index, 1);
        }
    }
    //console.log("val-checked:",this.formCreateModule.value);
    this.formCreateModule.patchValue({
        location: {
            sections: currentSeccion
        }
    });
}




}
