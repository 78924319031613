<app-header></app-header>

<div class="flex bg-light-bg">
    <!--Menu lateral-->
        <!-- <app-siderbar optionMenu="5"></app-siderbar> -->
    <!--Menu lateral-->
    
    <div class="mx-auto">
        <div class="flex justify-center items-center absolute left-80 top-28 gap-2 cursor-pointer" [routerLink]="['/list-template']">
            <i class="fas fa-chevron-left text-green-dark"></i>
            <p class="text-dark-dark">{{ 'Modules.Back' | transloco }}</p>
        </div>
        <div class="flex justify-between items-center relative top-24">
            <h1 class="text-2xl font-bold leading-8 text-slate-800 left-96">{{ 'Modules.NewTemplate' | transloco }}</h1>
            <div class="flex items-center gap-1 right-96">
                <button [disabled]="!jsonForm.valid" class="items-center w-44 h-10 border rounded bg-dark-dark5 flex" (click)="sendInformation()" [ngClass]="{'bg-[#CBCBCB]': !jsonForm.valid, 'button-active': jsonForm.valid}">
                    <span class="text-white text-center font-semibold text-sm m-auto">{{ 'globals.save' | transloco }}</span>
                </button>
            </div>
          </div>          
        <div class="father-info bg-white rounded-2xl py-5 mx-auto mt-28 flex flex-col justify-center p-6 items-center gap-6">
            <div class="min-w-full text-left">
                <!-- Encabezado -->
                <div class="">
                  <div class="font-bold">
                    {{ 'Modules.GeneralInformation' | transloco }}
                  </div>
                </div>
                <div class="flex">
                    <div class="p-4 w-full">
                        <form [formGroup]="jsonForm" (ngSubmit)="submitPreview()"> 
                        <div class="mb-4 text-dark">
                            <label>{{ 'Modules.NameTemplate' | transloco }}</label>
                            <div class="mb-4">
                                
                                <input (change)="changeState()" formControlName="nameTemplate" type="text" formControlName="nameTemplate" id="TemplateNameInfo" class="mt-1 p-2 rounded-lg w-full bg-light-light focus-within:text-black">
                                <div class="custom-alert">
                                </div>
                                <label class="block text-sm font-medium text-dark-dark5 relative top-2">{{ 'Modules.Jsons' | transloco }}</label>
                            </div>
                        </div>
                        <div class="text-dark">
                            {{ 'Modules.RelatedComponent' | transloco }}
                            <div class="mb-4">
                                <input (change)="changeState()" type="text" formControlName="view_pulzo_revolution" class="mt-1 p-2 rounded-lg w-full bg-light-light focus-within:text-black" placeholder="Ej: modulo-1.html">
                                <div class="custom-alert">
                                </div>
                            </div>
                        </div>
                        </form>
                    </div>
                
                    <div class=" p-4">
                        <div class="flex">
                            <div class="mr-4 text-dark">
                                {{ 'Modules.Icon' | transloco }}
                                <div class="Icon flex relative w-40 h-44 flex-col justify-center items-center rounded-xl"
                                    ngx-dropzone [accept]="'image/*,video/*'" id="inputImages" (change)="onSelectIcon($event)">
                                    <img *ngIf="selectedFileIcon.length==0" class="p-1 w-full rounded-sm h-full absolute top-0 left-0 bg-[#ADADAD] object-cover" src="{{jsonForm.get('icon')?.value}}" />
                                    <div *ngIf="selectedFileIcon.length==0" class="flex flex-col justify-around ml-auto mr-auto">
                                        <ngx-dropzone-label class="Button-icons flex justify-center items-center gap-1 px-2.5 rounded-lg">{{ 'Modules.AddFiles' | transloco }}</ngx-dropzone-label>
                                        <ngx-dropzone-label class="block text-sm font-normal leading-4 relative">{{ 'Modules.Format' | transloco }}</ngx-dropzone-label>
                                    </div>
                                    
                                    <div *ngIf="selectedFileIcon.length>0" class="h-full w-full flex flex-col">
                                        <ngx-dropzone-image-preview class="w-24 bg-cover bg-opacity-5 bg-gray-medio" ngProjectAs="ngx-dropzone-preview" [removable]="true"
                                            *ngFor="let f of selectedFileIcon" [file]="f" (removed)="onRemove($event)">
                                        </ngx-dropzone-image-preview>
                                    </div>
                                </div>
                            </div>
                            <div class="text-dark">
                                {{ 'Modules.Miniature' | transloco }}
                                <div class="thumbnail flex relative w-40 h-44 flex-col justify-center items-center rounded-xl"
                                    ngx-dropzone [accept]="'image/*,video/*'" id="inputImages" (change)="onMiniatureSelect($event)">
                                    <img *ngIf="selectedMiniatureFiles.length==0" class="p-1 w-full rounded-sm h-full absolute top-0 left-0 bg-[#ADADAD] object-cover" src="{{jsonForm.get('thumbnail')?.value}}" />
                                    <div *ngIf="selectedMiniatureFiles.length==0" class="flex flex-col justify-around ml-auto mr-auto">
                                        <ngx-dropzone-label class="Button-icons flex justify-center items-center gap-1 px-2.5 rounded-lg">{{ 'Modules.AddFiles' | transloco }}</ngx-dropzone-label>
                                        <ngx-dropzone-label class="block text-sm font-normal leading-4 relative">{{ 'Modules.Format' | transloco }}</ngx-dropzone-label>
                                    </div>
                                    
                                    <div *ngIf="selectedMiniatureFiles.length>0" class="h-full w-full flex flex-col">
                                        <ngx-dropzone-image-preview class="w-24 bg-cover bg-opacity-5 bg-gray-medio" ngProjectAs="ngx-dropzone-preview" [removable]="true"
                                            *ngFor="let f of selectedMiniatureFiles" [file]="f" (removed)="onRemoveMiniature($event)">
                                        </ngx-dropzone-image-preview>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="father-info flex flex-row justify-center">
            <div class="AddProperty bg-white rounded-2xl py-5 mt-5 flex flex-col justify-center p-6 items-center">
                <div class="min-w-full text-left">
                        <!-- Encabezado -->
                    <div class="">
                        <div class="font-bold">
                        {{ 'Modules.Properties' | transloco }}
                        </div>
                    </div>
                </div>
                
                    <div class="div-1 rounded-2xl py-5 mx-auto flex flex-col justify-center items-center gap-4">
                            
                            <form [formGroup]="jsonForm" (ngSubmit)="submitPreview()">  
                                <div class="flex flex-row items-center gap-2 my-5">
                                    <div>
                                        <input type="text" formControlName="name" placeholder="Propiedad"  class="w-full h-9 pl-3 text-base rounded-xl border border-dark-light focus:ring-1 focus:outline-none focus:border-transparent focus:ring-dark-light">
                                    </div>
                                    <div>
                                        <select formControlName="type"
                                         class="w-24 h-9 pl-1 text-base text-dark-light rounded-xl border border-dark-light focus:ring-1 focus:outline-none focus:border-transparent focus:ring-dark-light">
                                            <option value="LA">{{ 'Modules.Articles' | transloco }}</option>
                                            <option value="LS">{{ 'Modules.String' | transloco }}</option>
                                            <option value="IMG">{{ 'Modules.img' | transloco }}</option>
                                        </select>
                                    </div>
                                    <div class="border border-dark-light rounded-xl">
                                        <input type="text" class="w-8 h-9 rounded-xl text-center" formControlName="quantity" placeholder="0"/>
                                    </div>
                                    <div>
                                        <button type="submit" class="w-full h-9 shadow-lg rounded-xl text-sm text-white bg-green px-20 md:px-5"> {{'globals.add' | transloco }}</button>
                                    </div>
                                </div>
                            </form>
        
                            <div class="inline-block min-w-full rounded-lg overflow-hidden py-3 pl-3 bg-light-light" *ngFor="let dato of datos; let i = index">
                                <table class="min-w-full leading-normal">
                                    <tbody>
                                        <tr>
                                            <td class="py-3 bg-white table-cell rounded-lg">
                                                <p class="ml-3 whitespace-no-wrap text-dark text-base text-left font-medium">{{dato.name}}</p>
                                                <p class="ml-3 whitespace-no-wrap text-dark-light  text-xs text-left font-medium">{{dato.type}} ( {{dato.quantity}} )</p>
                                            </td>
                                            <td class="py-3 text-center">
                                                <a  title="Eliminar" class="cursor-pointer" (click)="elementDelete(i)">
                                                <i class="fa fa-trash fa-sm mr-2 text-red cursor-pointer text-base"></i></a>
                                            </td>
                                        </tr>
                                        
                                    </tbody>
                                </table>  
                            </div>
                        
                    </div>
                
            </div>
            <div class="Json bg-white rounded-2xl py-5 ml-4 mt-5 flex flex-col justify-center p-6 items-center gap-6">

                <p class="text-left text-lg font-bold mb-5">{{ 'Modules.JsonPreview' | transloco }}</p>
                
                <section class="h-auto w-full py-6 pl-6 pr-10 mb-8 bg-gray-900 rounded-2xl">

                    <span class="text-green-light">{{keyOpening}}</span>&nbsp;&nbsp;
                        <div *ngFor="let dato of datos" class="text-green-light">
                        
                            <div>
                                &nbsp;&nbsp;<span>{{keyOpening}}</span><br>
                                &nbsp;&nbsp;&nbsp;&nbsp;{{ dato.name }}  
                            </div>
                            <div>
                                &nbsp;&nbsp;&nbsp;&nbsp;{{ dato.type }}
                            </div>
                            <div>
                                &nbsp;&nbsp;&nbsp;&nbsp;{{ dato.quantity }} <br> 
                                &nbsp;&nbsp;<span>{{keyClosing}}</span><br> 
                            </div>
                        </div>
                    <span class="text-green-light">{{keyClosing}}</span> 
                </section>
            </div>
        </div>
    </div>
</div>        


