<app-header title="{{'home.title' | transloco}}"></app-header>

<div class=" flex mt-2">

    <app-sidebar optionMenu="1"></app-sidebar>

    <div class="flex flex-col mt-5 h-full w-full justify-center items-center">
        <div class="flex flex-col items-start">
            <div class="flex items-center cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M15 19L8 12L15 5" stroke="#038652" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
                <p class="text-gray-500 font-sans text-sm font-bold leading-6 tracking-wider uppercase">
                    {{ "home.backPB" | transloco }}
                </p>
            </div>
            <img class="py-6" src="assets/welcome.png" alt="" srcset="">
        </div>
    </div>
</div>