import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Create } from 'src/app/models/create-template';
import { propertiesTemplate } from 'src/app/models/duplicate-template';
import { environment as env } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DuplicateTemplateService {
  private baseUrl = `${env.apiCerberoUrl}`;

  constructor(private http: HttpClient) { }

  getDuplicate(data:any): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/request?event=list-templates`, data);
  }

  getProperties(): Observable<propertiesTemplate[]> {
    return this.http.get<propertiesTemplate[]>(`${this.baseUrl}/request?event=list-templates`);
  }

  /* getPropertyIcons(): Observable<string[]> {
    return this.http.get<string[]>(`${this.baseUrl}/request?event=list-templates`);
  } */

  getName(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/request?event=list-templates`);
  }

  getDataTemplateByID(id:number): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/request?event=find-template-by-id&id=${id}`);
  }

}
