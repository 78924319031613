import { Component } from '@angular/core';

import { Router } from '@angular/router';
import { DuplicateTemplateService } from 'src/app/core/services/duplicate-template.service'
import { ModalService } from 'src/app/core/services/modal.service';
import { propertiesTemplate } from 'src/app/models/duplicate-template';

@Component({
  selector: 'app-duplicate-template',
  templateUrl: './duplicate-template.component.html',
  styleUrls: ['./duplicate-template.component.css']
})
export class DuplicateTemplateComponent {
  templates:  propertiesTemplate[] = [];
  modalOpen:boolean = false;

  constructor(private DuplicateTemplateService: DuplicateTemplateService, private router: Router, private x: ModalService) { }

  ngOnInit(): void {
    this.getTemplates();
  }

  getTemplates(): void {
    this.DuplicateTemplateService.getName()
      .subscribe(response => {
        this.templates = response.data; 
      });
  }

  editTemplate(id: number): void {
    this.router.navigate(['/edit-template'], {queryParams: {id : id}});
  }

}
