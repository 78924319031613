export interface ResponseModel {
    code: string;
    data: any;
}

export interface DataItem {
    id: number;
    name: string;
    editor: string;
    updated_at: string;
    status:string;
    icon: string;
    thumbnail: string;
    active: boolean;
    template_id: number;
    section: section[];
    json_name: string;
}

export interface section {
  id: number;
  name: string;
  position: string;
}


export interface filterByModule{
description: string
id: number
name: string
properties: Property[]
thumbnail: string
view_pulzo_revolution: string
}

export interface Property {
name: string
quantity: number
type: string
}


export interface AddModule {
    name: string;
    json_name: string;
}

export interface RemoveModule {
    id: number;
}

export interface SettingJson{
    id: number;
    json_struct: { name: string; type: string; quantity: number}[]; 
}  

export interface ShowArticle {
    createdAt: string;
    data: {
        alt: string;
        categories: {
            main: {
                name: string;
                slug: string;
            };
        };
        id: string;
        images: {
            medio: string;
            thumb: string;
        };
        lead: string;
        title: string;
        url: string;
    };
    updatedAt: string;
}




export interface Root {
    articulos: Articulo[]
    liveblog: Liveblog[]
    videos: string[]
  }
  
  export interface Articulo {
    createdAt: string
    data: DataArticulo
    updatedAt: string
  }
  
  export interface DataArticulo {
    alt: string
    categories: Categories
    id: string
    images: Images
    lead: string
    title: string
    url: string
  }
  
  export interface Categories {
    main: Main
  }
  
  export interface Main {
    name: string
    slug: string
  }
  
  export interface Images {
    medio: string
    thumb: string
  }
  
  export interface Liveblog {
    createdAt: string
    data: Data2
    updatedAt: string
  }
  
  export interface Data2 {
    alt: string
    categories: Categories2
    id: string
    images: Images2
    lead: string
    title: string
    url: string
  }
  
  export interface Categories2 {
    main: Main2
  }
  
  export interface Main2 {
    name: string
    slug: string
  }
  
  export interface Images2 {
    medio: string
    thumb: string
  }
  
  export type Video = string[]
export interface LiveBlogStructure {
  createdAt: string
  data: DataLive
  updatedAt: string
}

export interface DataLive {
  alt: string
  categories: Categories
  id: string
  images: Images
  lead: string
  title: string
  url: string
}

export interface Categories {
  main: Main
}

export interface Main {
  name: string
  slug: string
}

export interface Images {
  medio: string
  thumb: string
}

export type PropertyList = PropertyStructure[]

export interface PropertyStructure {
  property: string
  type: string
}


// Ultimo 

export interface DataJsonForProperties {
    active: boolean
    articulos: Articulo[]
    liveblog: Liveblog[]
    videos: string[]
  }
  export interface Articulo {
    createdAt: string
    data: Data2
    updatedAt: string
  }
  
  export interface Data2 {
    alt: string
    categories: Categories
    id: string
    images: Images
    lead: string
    title: string
    url: string
  }
  
  export interface Categories {
    main: Main
  }
  
  export interface Main {
    name: string
    slug: string
  }
  
  export interface Images {
    medio: string
    thumb: string
  }
  
  export interface Liveblog {
    createdAt: string
    data: Data3
    updatedAt: string
  }
  
  export interface Data3 {
    alt: string
    categories: Categories2
    id: string
    images: Images2
    lead: string
    title: string
    url: string
  }
  
  export interface Categories2 {
    main: Main2
  }
  
  export interface Main2 {
    name: string
    slug: string
  }
  
  export interface Images2 {
    medio: string
    thumb: string
  }
  
  export interface moduleStructure {
    created_at: string
    editor: string
    id: number
    name: string
    properties: PropertyModuleStructure[]
    section: Section
    status: string
    template_id: number
    updated_at: string
  }
  
  export interface PropertyModuleStructure {
    name: string
    type: string
    open:boolean
    value: string[]
  }
  
  export interface Section {
    position: string
    sections: string[]
  }


  export interface TemplateStructure {
    description: string
    icon: string
    id: number
    name: string
    properties: Property[]
    thumbnail: string
    view_pulzo_revolution: string
  }
  
  export interface Property {
    name: string
    quantity: number
    type: string
  }
  

  export const allowedPositions = [
    {
      "name":"P1",
      "text":"P1: Arriba del opening"
    },
    {
      "name":"P2",
      "text":"P2: Debajo del opening"
    },
    {
      "name":"P3",
      "text":"P3: Arriba de la grilla"
    },
    {
      "name":"P4",
      "text":"P4: Debajo de la grilla"
    },
    {
      "name":"P5",
      "text":"P5: Módulo 1"
    },
    {
      "name":"P6",
      "text":"P6: Módulo 2"
    }
  ]

  export interface openModalUploadImage {
    open: boolean
    modalToOpen: string
  }
  
