import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services';
import { ModuleManagementService } from 'src/app/core/services/module-management.service';
import { AddModule, DataItem } from 'src/app/models/moduleManagement';


@Component({
  selector: 'app-create-module',
  templateUrl: './create-module.component.html',
  styleUrls: ['./create-module.component.css'],
})
export class CreateModuleComponent {
  inputForm!: FormGroup;
  addModule: AddModule;
  dataModule: DataItem;
  parametroId: number;
  isUpdate: boolean;
  templates:any[] = [];
  activeModule: any | null = null;
  jsonForm!: FormGroup;

  constructor(private fB:FormBuilder, private moduleService: ModuleManagementService, private router: Router, private route: ActivatedRoute, private authService:AuthService){
    this.addModule = {} as AddModule;
    this.dataModule = {} as DataItem;
    this.parametroId = 0;
    this.isUpdate = false;

    this.jsonForm = this.fB.group({
      name: ['', Validators.required],
      typeModule:[[], Validators.required]
    });
  }

  ngOnInit(): void {
    this.listTemplates();
  }

  listTemplates(){

    this.moduleService.getTemplates().subscribe(
      (response) => {
       this.templates = response.data;
      }
    );
  }

  isActiveTypeModule(id:string){
    const selectedData = this.templates.find(data => data.id === id);

    if (selectedData && selectedData !== this.activeModule) {
      if (this.activeModule) {
        this.activeModule.isActive = false;
      }
      selectedData.isActive = true;
      this.activeModule = selectedData;
    } else {
      selectedData.isActive = false;
      this.activeModule = null;
    }
    
    this.updateDateForm();
  }

  updateDateForm(){
    this.jsonForm.setValue({
      name: this.jsonForm.get('name')?.value, 
      typeModule: this.activeModule
    });
  }


  sendData(): void {

    this.updateDateForm();

    this.moduleService.setData(this.jsonForm.value);
    this.router.navigate(['/new-module']);
  }

  
}
