import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ListLinkService } from 'src/app/core/services/list-link.service';
import { DataLink, Links } from 'src/app/models/list-link';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-link-list',
  templateUrl: './edit-link-list.component.html',
  styleUrls: ['./edit-link-list.component.css']
})
export class EditLinkListComponent {
  listForm: FormGroup;
  links: Links[] = [];
  parametroId: number = 0;

  constructor(private fB:FormBuilder, private srvLinks: ListLinkService,  private router: Router, private route: ActivatedRoute){
    this.listForm = this.fB.group({
      positionArray: [''],
      name: ['', Validators.required],
      label: ['', Validators.required],
      url: ['', Validators.required],
      //order: ['', Validators.required],
    });
  }

  ngOnInit(): void {
     //trae el id del home
     this.route.queryParams.subscribe(params => {
      this.parametroId = +params['id'];
      this.getList(this.parametroId);
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.links, event.previousIndex, event.currentIndex);
  }

  addItem(){
    if (this.links.length >= 8) {
      Swal.fire('', 'No puedes agregar más de 8 enlaces.', 'warning');
      return;
    }

    if(this.listForm.valid){
      const newLink: Links = {
        label: this.listForm.get('label')?.value,
        url: this.listForm.get('url')?.value,
        //order: parseInt(this.listForm.get('order')?.value),
      };

      this.links.push(newLink);
      this.clearFormFields();
    }
    //console.log('addlinks', this.links);
  }

  clearFormFields() {
    this.listForm.patchValue({
      label: '',
      url: '',
      //order: ''
    });
  }

  getList(id: number){
    if (id != null) {
      this.srvLinks.getListLinkById(id).subscribe((response) =>{ 
        //console.log('traer lista', response);

        this.listForm.patchValue({
          name: response.data.name,
        });

        this.links = response.data.links.map((link: Links) => ({
          label: link.label,
          url: link.url,
          //order: link.order,
        }));
      });
    }
  }

  editarItem(link:Links, i:number){
    this.listForm.patchValue({
      positionArray: i,
      label: link.label,
      url: link.url,
    });
  }

  updateItem(){
    const newLink: any = {
      positionArray : this.listForm.get('positionArray')?.value,
      name: this.listForm.get('name')?.value,
      label: this.listForm.get('label')?.value,
      url: this.listForm.get('url')?.value,
      //order: parseInt(this.listForm.get('order')?.value),
    };
    //console.log("La data a modificar es: " + newLink.label + " " + newLink.url);

    for(let i = 0; i < this.links.length; i++){
  
      if(newLink.positionArray == i){
        //console.log("Lo encontro");
        this.links[i]['label'] = newLink.label;
        this.links[i]['url'] = newLink.url;
        //this.links[i]['order'] = newLink.order;
        break;
      }
      
    }

    this.clearFormFields();
  }

  deleteItem(i:number){
    Swal.fire({
      title: "Eliminar item",
      text: "¿Está seguro de eliminar este item?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar"
    }).then((result) => {
      if (result.isConfirmed) {
         Swal.fire("Deleted!", "El item ha sido eliminado con éxito", "success");
        this.links.splice(i,1);
      }
    });
  }

  submitList(){  
    if (this.listForm.get('name')?.valid && this.links.length > 0) {
      const update: DataLink = {
        id: this.parametroId,
        name: this.listForm.get('name')?.value,
        links: this.links
      };

       this.srvLinks.updateListLink(update).subscribe(() =>{
          Swal.fire("", "Lista actualizada con exito", "success")
          .then(() => {
            this.router.navigate(['/list-links']);
          });

       });
    } else {
      Swal.fire('', 'El nombre de la lista es obligatorio y debe tener al menos un enlace.', 'warning');
    } 

  }
}
