import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Route, Router, RouterStateSnapshot, CanMatch } from '@angular/router';
import { Observable, map, tap } from 'rxjs';
import { AuthService } from '../services';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class HasRoleGuard implements CanMatch, CanActivate {
 
  role: string = "";
  constructor(private authService: AuthService, private storageService: StorageService,  private router: Router) {
    this.role = this.authService.getRole();
    //console.log("ROLE:" +this.role);
  }

  canMatch(route: Route): Observable<boolean> {
    return this.hasRole(route);
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.hasRole(route);
  }

  private hasRole(route: Route | ActivatedRouteSnapshot): Observable<boolean> {
    const allowedRoles = route.data?.['allowedRoles'];
    const userRole = this.role;

    console.log(userRole, allowedRoles)
  
    return this.authService.isLoggedIn$.pipe(
      map(() => Boolean(userRole && allowedRoles.includes(userRole.toLowerCase()))),
      tap((hasRole) => {
        if (!hasRole) {
          this.router.navigate(['/home']);
        }
      })
    );
  }
}