
<div class="-top-5 relative w-64 max-w-full sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl 2xl:max-w-2xl h-screen bg-white  z-50 rounded-br-lg mr-2" >

    <nav class="py-14 px-3  flex items-center justify-center max-h-72 w-full mb-4 sm:mb-6 md:mb-8 lg:mb-10 xl:mb-12 ">
        <ul class="mt-[250px] px-3  md:px-0 py-2 rounded-xl flex flex-wrap overflow-hidden items-center text-xs">
            <button type="button" [routerLink]="['/home']" [ngClass]="{'h-[50px] p-4 rounded-lg bg-blue-500 hover:bg-blue-500 hover:rounded-l-xl text-white boder shadow' : optionMenu == '1'}"
                class="w-56 h-12 cursor-pointer rounded-l-xl pulzo-w-30 transition-all pulzo-mx-1 text-center md:pl-2 md:text-left px-2 py-1 md:my-2 hover:bg-gray-transparent hover:rounded-lg">
                <svg class="inline-block mr-2" [ngClass]="{'text-white' : optionMenu == '1'}" width="20" height="20"
                    viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                    [attr.stroke]="optionMenu == '1' ? 'white' : '#53545C'"
                        d="M20 17.0002V11.4522C20 10.9179 19.9995 10.6506 19.9346 10.4019C19.877 10.1816 19.7825 9.97307 19.6546 9.78464C19.5102 9.57201 19.3096 9.39569 18.9074 9.04383L14.1074 4.84383C13.3608 4.19054 12.9875 3.86406 12.5674 3.73982C12.1972 3.63035 11.8026 3.63035 11.4324 3.73982C11.0126 3.86397 10.6398 4.19014 9.89436 4.84244L5.09277 9.04383C4.69064 9.39569 4.49004 9.57201 4.3457 9.78464C4.21779 9.97307 4.12255 10.1816 4.06497 10.4019C4 10.6506 4 10.9179 4 11.4522V17.0002C4 17.932 4 18.3978 4.15224 18.7654C4.35523 19.2554 4.74432 19.6452 5.23438 19.8482C5.60192 20.0005 6.06786 20.0005 6.99974 20.0005C7.93163 20.0005 8.39808 20.0005 8.76562 19.8482C9.25568 19.6452 9.64467 19.2555 9.84766 18.7654C9.9999 18.3979 10 17.932 10 17.0001V16.0001C10 14.8955 10.8954 14.0001 12 14.0001C13.1046 14.0001 14 14.8955 14 16.0001V17.0001C14 17.932 14 18.3979 14.1522 18.7654C14.3552 19.2555 14.7443 19.6452 15.2344 19.8482C15.6019 20.0005 16.0679 20.0005 16.9997 20.0005C17.9316 20.0005 18.3981 20.0005 18.7656 19.8482C19.2557 19.6452 19.6447 19.2554 19.8477 18.7654C19.9999 18.3978 20 17.932 20 17.0002Z"
                        stroke="#53545C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <span class="text-sm text-gray-500" [ngClass]="{'text-white' : optionMenu == '1'}">{{ "home.home" | transloco }}</span>
            </button>

            <button type="button" [routerLink]="['/home-newsletter']"
                [ngClass]="{'h-[50px] p-4 rounded-lg bg-blue-500 hover:bg-blue-500 hover:rounded-l-xl text-white boder shadow' :optionMenu == '2'}"
                class="w-56 h-12 cursor-pointer rounded-l-xl transition-all pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left px-2 py-1 md:my-2  hover:bg-gray-transparent hover:rounded-lg">
                <svg class="inline-block mr-2" [ngClass]="{'text-white' : optionMenu == '2'}" width="20" height="20"
                    viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                    [attr.stroke]="optionMenu == '2' ? 'white' : '#53545C'"
                        d="M4 10L10.1076 14.6122L10.1097 14.6139C10.7878 15.1112 11.1271 15.36 11.4988 15.4561C11.8272 15.5411 12.1725 15.5411 12.501 15.4561C12.8729 15.3599 13.2132 15.1104 13.8926 14.6122L20 10M19.8002 9.03944L14.2012 4.55657C13.506 3.99995 13.1581 3.72174 12.7715 3.61126C12.4304 3.51378 12.0692 3.50861 11.7255 3.59661C11.336 3.69634 10.9809 3.96473 10.2705 4.50188L4.26953 9.03967C3.8038 9.39184 3.57123 9.56804 3.40332 9.7906C3.2546 9.98772 3.14377 10.2107 3.07624 10.4482C3 10.7163 3 11.0083 3 11.5922V17.8001C3 18.9202 3 19.4805 3.21799 19.9083C3.40973 20.2847 3.71547 20.5904 4.0918 20.7822C4.5192 20.9999 5.07899 20.9999 6.19691 20.9999H17.8031C18.921 20.9999 19.48 20.9999 19.9074 20.7822C20.2837 20.5904 20.5905 20.2844 20.7822 19.9081C21 19.4807 21 18.9214 21 17.8035V11.5265C21 10.9693 21 10.689 20.9287 10.4301C20.8651 10.1992 20.7595 9.98161 20.619 9.78768C20.4604 9.56876 20.2409 9.39227 19.8002 9.03944Z"
                        stroke="#53545C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <span class="text-sm text-gray-500 "[ngClass]="{'text-white' : optionMenu == '2'}">{{ "Newsletter.title" | transloco }}</span>
            </button>

            <button type="button" [routerLink]="['/home-gallery']"
                [ngClass]="{'h-[50px] p-4 rounded-lg bg-blue-500  hover:bg-blue-500 hover:rounded-l-xl text-white boder shadow' :optionMenu == '3'}"
                class="w-56 h-12 cursor-pointer rounded-l-xl  transition-all pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left px-2 py-1 md:my-2  hover:bg-gray-transparent hover:rounded-lg">
                <svg class="inline-block mr-2" [ngClass]="{'text-white' : optionMenu == '3'}" width="20" height="20"
                    viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                    [attr.stroke]="optionMenu == '3' ? 'white' : '#53545C'"
                        d="M3.00005 18.0001C3 17.9355 3 17.8689 3 17.8002V6.2002C3 5.08009 3 4.51962 3.21799 4.0918C3.40973 3.71547 3.71547 3.40973 4.0918 3.21799C4.51962 3 5.08009 3 6.2002 3H17.8002C18.9203 3 19.4801 3 19.9079 3.21799C20.2842 3.40973 20.5905 3.71547 20.7822 4.0918C21 4.5192 21 5.07899 21 6.19691V17.8031C21 18.2881 21 18.6679 20.9822 18.9774M3.00005 18.0001C3.00082 18.9884 3.01337 19.5058 3.21799 19.9074C3.40973 20.2837 3.71547 20.5905 4.0918 20.7822C4.5192 21 5.07899 21 6.19691 21H17.8036C18.9215 21 19.4805 21 19.9079 20.7822C20.2842 20.5905 20.5905 20.2837 20.7822 19.9074C20.9055 19.6654 20.959 19.3813 20.9822 18.9774M3.00005 18.0001L7.76798 12.4375L7.76939 12.436C8.19227 11.9426 8.40406 11.6955 8.65527 11.6064C8.87594 11.5282 9.11686 11.53 9.33643 11.6113C9.58664 11.704 9.79506 11.9539 10.2119 12.4541L12.8831 15.6595C13.269 16.1226 13.463 16.3554 13.6986 16.4489C13.9065 16.5313 14.1357 16.5406 14.3501 16.4773C14.5942 16.4053 14.8091 16.1904 15.2388 15.7607L15.7358 15.2637C16.1733 14.8262 16.3921 14.6076 16.6397 14.5361C16.8571 14.4734 17.0896 14.4869 17.2988 14.5732C17.537 14.6716 17.7302 14.9124 18.1167 15.3955L20.9822 18.9774M20.9822 18.9774L21 18.9996M15 9C14.4477 9 14 8.55228 14 8C14 7.44772 14.4477 7 15 7C15.5523 7 16 7.44772 16 8C16 8.55228 15.5523 9 15 9Z"
                        stroke="#53545C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <span class="text-sm text-gray-500" [ngClass]="{'text-white' : optionMenu == '3'}">{{ "gallery.title" | transloco }}</span>
            </button>

            <button type="button" [routerLink]="['/home-seo']"
                [ngClass]="{'h-[50px] p-4 rounded-lg bg-blue-500  hover:bg-blue-500 hover:rounded-l-xl text-white boder shadow' :optionMenu == '4'}"
                class="w-56 h-12 cursor-pointer rounded-l-xl transition-all pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left px-2 py-1 md:my-2  hover:bg-gray-transparent hover:rounded-lg">
                <svg class="inline-block mr-2" [ngClass]="{'text-white' : optionMenu == '4'}" width="20" height="20"
                    viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                    [attr.stroke]="optionMenu == '4' ? 'white' : '#53545C'"
                        d="M15 15L21 21M10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17Z"
                        stroke="#53545C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <span class="text-sm text-gray-500" [ngClass]="{'text-white' : optionMenu == '4'}">{{ "seo.title" | transloco }}</span>
            </button>


            <button type="button" [routerLink]="['/list-template']"
                [ngClass]="{'h-[50px] p-4 rounded-lg bg-blue-500 text-white hover:bg-blue-500 hover:rounded-l-xl' :optionMenu == '5'}"
                class="w-56 h-12 cursor-pointer rounded-l-xl transition-all pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left px-2 py-1 md:my-2  hover:bg-gray-transparent hover:rounded-lg">
                <svg class="inline-block mr-2" [ngClass]="{'text-white' : optionMenu == '5'}" width="20" height="20"
                viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path [attr.stroke]="optionMenu == '5' ? 'white' : '#53545C'"
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    d="M10 18H21M3 18H6M6 18V20M6 18V16M20 12H21M3 12H16M16 12V14M16 12V10M14 6H21M3 6H10M10 6V8M10 6V4" />
                </svg>
                <span class="text-sm text-gray-500" [ngClass]="{'text-white' : optionMenu == '5'}">{{ "module-management.title" | transloco }}</span>
            </button>

            <button type="button" [routerLink]="['/list-links']"
                [ngClass]="{'h-[50px] p-4 rounded-lg bg-blue-500 text-white hover:bg-blue-500 hover:rounded-l-xl' :optionMenu == '6'}"
                class="w-56 h-12 cursor-pointer rounded-l-xl transition-all pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left px-2 py-1 md:my-2  hover:bg-gray-transparent hover:rounded-lg">
                
                <i class="fas fa-list-ul fa-lg mr-2" [ngClass]="{'text-white' : optionMenu == '6'}"></i>
                <span class="text-sm text-gray-500" [ngClass]="{'text-white' : optionMenu == '6'}">{{'links-list.title' | transloco }}</span>
            </button>

            <button type="button" [routerLink]="['/main-menu']"
                [ngClass]="{'h-[50px] p-4 rounded-lg bg-blue-500 text-white hover:bg-blue-500 hover:rounded-l-xl' :optionMenu == '7'}"
                class="w-56 h-12 cursor-pointer rounded-l-xl transition-all pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left px-2 py-1 md:my-2  hover:bg-gray-transparent hover:rounded-lg">
                
                <i class="fas fa-bars fa-lg mr-2" [ngClass]="{'text-white' : optionMenu == '7'}"></i>
                <span class="text-sm text-gray-500" [ngClass]="{'text-white' : optionMenu == '7'}">{{'main-menu.title' | transloco }}</span>
            </button>

            <button type="button" [routerLink]="['/first-note-scroll']"
            [ngClass]="{'h-[50px] p-4 rounded-lg bg-blue-500 text-white hover:bg-blue-500 hover:rounded-l-xl' :optionMenu == '8'}"
            class="w-56 h-12 cursor-pointer rounded-l-xl transition-all pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left px-2 py-1 md:my-2  hover:bg-gray-transparent hover:rounded-lg">
            
            <i class="fas fa-file-alt fa-lg mr-2" [ngClass]="{'text-white' : optionMenu == '8'}"></i>
            <span class="text-sm text-gray-500" [ngClass]="{'text-white' : optionMenu == '8'}">{{'firstNoteScroll.title' | transloco }}</span>
            </button>
        </ul>
    </nav>


    <nav class="flex flex-col items-center justify-center h-4/6 w-full">
        <button type="button" (click)="navigateToSlack()"
        class="w-60 h-12 cursor-pointer rounded-l-xl pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left px-2 py-1 md:my-2 mx-auto transition-colors duration-200 ease-in-out hover:bg-gray-transparent hover:rounded-lg ">
                <svg  class="inline-block mr-2"  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="32" height="32" rx="8" fill="white"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2782 6.0791C13.0168 6.07934 12.7581 6.13107 12.5167 6.23132C12.2753 6.33158 12.0561 6.4784 11.8714 6.6634C11.6868 6.8484 11.5404 7.06795 11.4407 7.30953C11.3409 7.5511 11.2897 7.80996 11.29 8.07132C11.2898 8.33265 11.341 8.59147 11.4408 8.833C11.5406 9.07454 11.687 9.29405 11.8716 9.47901C12.0562 9.66397 12.2754 9.81076 12.5168 9.91099C12.7581 10.0112 13.0168 10.0629 13.2782 10.0632H15.2663V8.07132C15.2666 7.80996 15.2154 7.5511 15.1157 7.30953C15.0159 7.06795 14.8695 6.8484 14.6849 6.6634C14.5003 6.4784 14.281 6.33158 14.0397 6.23132C13.7983 6.13107 13.5395 6.07934 13.2782 6.0791ZM13.2782 11.3916H7.97644C7.7151 11.3918 7.45638 11.4435 7.21503 11.5438C6.97369 11.644 6.75445 11.7908 6.56983 11.9757C6.38522 12.1607 6.23884 12.3802 6.13905 12.6217C6.03927 12.8633 5.98804 13.1221 5.98828 13.3834C5.98799 13.6448 6.03919 13.9036 6.13895 14.1452C6.23871 14.3868 6.38508 14.6063 6.5697 14.7913C6.75432 14.9763 6.97358 15.1232 7.21495 15.2234C7.45632 15.3237 7.71507 15.3754 7.97644 15.3756H13.2782C13.5395 15.3754 13.7983 15.3237 14.0397 15.2234C14.281 15.1232 14.5003 14.9763 14.6849 14.7913C14.8695 14.6063 15.0159 14.3868 15.1157 14.1452C15.2154 13.9036 15.2666 13.6448 15.2663 13.3834C15.2666 13.1221 15.2153 12.8633 15.1156 12.6217C15.0158 12.3802 14.8694 12.1607 14.6848 11.9757C14.5002 11.7908 14.2809 11.644 14.0396 11.5438C13.7982 11.4435 13.5395 11.3918 13.2782 11.3916Z" fill="#36C5F0"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M25.8698 13.3834C25.8701 13.1221 25.8189 12.8633 25.7191 12.6217C25.6193 12.3802 25.4729 12.1607 25.2883 11.9757C25.1037 11.7908 24.8844 11.644 24.6431 11.5438C24.4017 11.4435 24.143 11.3918 23.8817 11.3916C23.6204 11.3918 23.3616 11.4435 23.1203 11.5438C22.8789 11.644 22.6597 11.7908 22.4751 11.9757C22.2905 12.1607 22.1441 12.3802 22.0443 12.6217C21.9445 12.8633 21.8933 13.1221 21.8935 13.3834V15.3756H23.8817C24.1431 15.3754 24.4018 15.3237 24.6432 15.2234C24.8845 15.1232 25.1038 14.9763 25.2884 14.7913C25.473 14.6063 25.6194 14.3868 25.7192 14.1452C25.8189 13.9036 25.8701 13.6448 25.8698 13.3834ZM20.5681 13.3834V8.07132C20.5684 7.80996 20.5172 7.5511 20.4174 7.30953C20.3177 7.06795 20.1713 6.8484 19.9867 6.6634C19.8021 6.4784 19.5828 6.33158 19.3414 6.23132C19.1001 6.13107 18.8413 6.07934 18.58 6.0791C18.3186 6.07934 18.0598 6.13107 17.8185 6.23132C17.5771 6.33158 17.3578 6.4784 17.1732 6.6634C16.9886 6.8484 16.8422 7.06795 16.7425 7.30953C16.6427 7.5511 16.5915 7.80996 16.5918 8.07132V13.3834C16.5915 13.6448 16.6427 13.9036 16.7425 14.1452C16.8422 14.3868 16.9886 14.6063 17.1732 14.7913C17.3578 14.9763 17.5771 15.1232 17.8185 15.2234C18.0598 15.3237 18.3186 15.3754 18.58 15.3756C18.8413 15.3754 19.1001 15.3237 19.3414 15.2234C19.5828 15.1232 19.8021 14.9763 19.9867 14.7913C20.1713 14.6063 20.3177 14.3868 20.4174 14.1452C20.5172 13.9036 20.5684 13.6448 20.5681 13.3834Z" fill="#2EB67D"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.58 26.0006C18.8413 26.0004 19.1001 25.9487 19.3414 25.8484C19.5828 25.7482 19.8021 25.6013 19.9867 25.4163C20.1713 25.2313 20.3177 25.0118 20.4174 24.7702C20.5172 24.5286 20.5684 24.2698 20.5681 24.0084C20.5683 23.7471 20.5171 23.4883 20.4173 23.2467C20.3175 23.0052 20.1712 22.7857 19.9866 22.6007C19.8019 22.4158 19.5827 22.269 19.3414 22.1688C19.1 22.0685 18.8413 22.0168 18.58 22.0166H16.5918V24.0084C16.5915 24.2698 16.6427 24.5286 16.7425 24.7702C16.8422 25.0118 16.9886 25.2313 17.1732 25.4163C17.3578 25.6013 17.5771 25.7482 17.8185 25.8484C18.0598 25.9487 18.3186 26.0004 18.58 26.0006ZM18.58 20.6882H23.8817C24.143 20.6879 24.4017 20.6362 24.6431 20.536C24.8844 20.4358 25.1037 20.289 25.2883 20.104C25.4729 19.919 25.6193 19.6995 25.7191 19.458C25.8189 19.2165 25.8701 18.9577 25.8698 18.6963C25.8701 18.435 25.8189 18.1761 25.7192 17.9345C25.6194 17.693 25.473 17.4734 25.2884 17.2884C25.1038 17.1034 24.8845 16.9566 24.6432 16.8563C24.4018 16.7561 24.1431 16.7043 23.8817 16.7041H18.58C18.3186 16.7043 18.0598 16.7561 17.8185 16.8563C17.5771 16.9566 17.3578 17.1034 17.1732 17.2884C16.9886 17.4734 16.8422 17.693 16.7425 17.9345C16.6427 18.1761 16.5915 18.435 16.5918 18.6963C16.5916 18.9577 16.6428 19.2165 16.7426 19.458C16.8424 19.6995 16.9887 19.919 17.1733 20.104C17.358 20.289 17.5772 20.4358 17.8186 20.536C18.0599 20.6362 18.3186 20.6879 18.58 20.6882Z" fill="#ECB22E"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.98828 18.6963C5.98804 18.9577 6.03927 19.2165 6.13905 19.458C6.23884 19.6995 6.38522 19.919 6.56983 20.104C6.75445 20.289 6.97369 20.4358 7.21504 20.536C7.45638 20.6362 7.7151 20.6879 7.97644 20.6882C8.23777 20.6879 8.49649 20.6362 8.73784 20.536C8.97918 20.4358 9.19842 20.289 9.38304 20.104C9.56766 19.919 9.71403 19.6995 9.81382 19.458C9.9136 19.2165 9.96483 18.9577 9.96459 18.6963V16.7041H7.97644C7.71504 16.7043 7.45626 16.7561 7.21486 16.8564C6.97347 16.9566 6.7542 17.1035 6.56957 17.2885C6.38494 17.4736 6.23858 17.6932 6.13884 17.9348C6.0391 18.1764 5.98794 18.4349 5.98828 18.6963ZM11.29 18.6963V24.0084C11.2897 24.2698 11.3409 24.5286 11.4407 24.7702C11.5404 25.0118 11.6868 25.2313 11.8714 25.4163C12.0561 25.6013 12.2753 25.7482 12.5167 25.8484C12.7581 25.9487 13.0168 26.0004 13.2782 26.0006C13.5395 26.0004 13.7983 25.9487 14.0397 25.8484C14.281 25.7482 14.5003 25.6013 14.6849 25.4163C14.8695 25.2313 15.0159 25.0118 15.1157 24.7702C15.2154 24.5286 15.2666 24.2698 15.2663 24.0084V18.6967C15.2666 18.4353 15.2154 18.1765 15.1157 17.9349C15.0159 17.6933 14.8695 17.4738 14.6849 17.2888C14.5003 17.1038 14.281 16.9569 14.0397 16.8567C13.7983 16.7564 13.5395 16.7047 13.2782 16.7045C13.0168 16.7047 12.7581 16.7564 12.5167 16.8567C12.2753 16.9569 12.0561 17.1038 11.8714 17.2888C11.6868 17.4738 11.5404 17.6933 11.4407 17.9349C11.3409 18.1765 11.2897 18.435 11.29 18.6963Z" fill="#E01E5A"/>
                  </svg>
                <span class="text-sm text-gray-500">{{ "home.support" | transloco }}</span>
            </button>

            <button type="button" [routerLink]="['/home-module']"
                class="w-56 h-5 cursor-pointer rounded-l-xl pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left md:my-3">
                <svg class="inline-block mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.4" d="M0 4.447C0 1.996 2.03024 0 4.52453 0H9.48564C11.9748 0 14 1.99 14 4.437V15.553C14 18.005 11.9698 20 9.47445 20H4.51537C2.02515 20 0 18.01 0 15.563V14.623V4.447Z" fill="#CC5F5F"/>
                    <path d="M19.7792 9.45504L16.9334 6.54604C16.6393 6.24604 16.166 6.24604 15.8728 6.54804C15.5807 6.85004 15.5816 7.33704 15.8748 7.63704L17.434 9.23004H15.9391H7.54875C7.13483 9.23004 6.79883 9.57504 6.79883 10C6.79883 10.426 7.13483 10.77 7.54875 10.77H17.434L15.8748 12.363C15.5816 12.663 15.5807 13.15 15.8728 13.452C16.0199 13.603 16.2118 13.679 16.4046 13.679C16.5955 13.679 16.7873 13.603 16.9334 13.454L19.7792 10.546C19.9204 10.401 20.0003 10.205 20.0003 10C20.0003 9.79604 19.9204 9.60004 19.7792 9.45504Z" fill="#CC5F5F"/>
                  </svg>
                <span class="text-sm text-red-400 pl-1">{{ "home.backPB" | transloco }}</span>
            </button>
    </nav>







<!--Barra menu plegada-->
<!-- <div class="bg-black w-14 max-h-full rounded-r-lg mr-2 relative z-10 h-screen" *ngIf="sider">
    <div class="bg-dark rounded-full w-11 h-11 text-center py-2 ml-7 my-4" (click)="showSiderbar()">
        <i class="fa fa-chevron-right text-white "></i>
    </div>
    <div class="border-b"></div>
    <nav class="py-3 px-2">
        <ul class="px-3 md:px-0 py-2 rounded-xl flex flex-wrap overflow-hidden items-center text-xs">
            <button type="button" [routerLink]="['/home']"
                class="h-9  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-1 md:text-left  px-2 py-1 md:my-2">
                <i class="fa fa-home fa-lg mr-2 text-green-700"></i>
            </button>
            <button type="button" [routerLink]="['/home-newsletter']"
                class="w-56 h-5  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left  px-2 py-1 md:my-2">
                <i class="fa fa-clipboard fa-lg mr-2 text-green-700"></i>
            </button>
            <button type="button" [routerLink]="['/home-gallery']"
                class="w-56 h-5  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left  px-2 py-1 md:my-2">
                <i class="fa fa-image fa-lg mr-2 text-green-700"></i>
            </button>
            <button type="button" [routerLink]="['/home-seo']" [ngClass]="{'bg-blue boder shadow h-7' :optionMenu == '4'}"
                class="w-56 h-5  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left  px-2 py-1 md:my-2">
                <i class="fas fa-search fa-lg mr-2 text-green-700" [ngClass]="{'text-white' : optionMenu == '4'}"></i>
            </button>
            <button type="button" [routerLink]="['/home-module']" [ngClass]="{'bg-blue boder shadow h-7' :optionMenu == '5'}"
                class="w-56 h-5  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left  px-2 py-1 md:my-2">
                <i class="fas fa-tasks fa-lg mr-2 text-green-700" [ngClass]="{'text-white' : optionMenu == '5'}"></i>
            </button>
        </ul>
    </nav>
</div> -->

<!--Barra menu desplegada-->
<!-- <div class="bg-black w-56 max-h-full rounded-r-lg mr-2 relative z-10 h-screen" *ngIf="!sider">
    <div class="bg-dark rounded-full w-11 h-11 text-center py-2 ml-52 my-4" (click)="showSiderbar()">
        <i class="fa fa-chevron-left text-white "></i>
    </div>
    <div class="pl-3 pb-5 border-b">
        <p class="text-white text-sm">{{ 'globals.welcome' | transloco }}</p>
    </div>

    <nav class="py-3 px-2">
        <ul class="px-3 md:px-0 py-2 rounded-xl flex flex-wrap overflow-hidden items-center text-xs">
            <button type="button"  [routerLink]="['/home']" [ngClass]="{'bg-blue boder shadow h-7' : optionMenu == '1'}" 
                class="w-56 h-5  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left  px-2 py-1 md:my-2">
                <i class="fa fa-home fa-lg mr-2 text-green-700" [ngClass]="{'text-white' : optionMenu == '1'}" ></i><span class="text-sm text-white"> {{ "home.home" | transloco }}</span>
            </button>
            <button type="button" [routerLink]="['/home-newsletter']" [ngClass]="{'bg-blue boder shadow h-7' :optionMenu == '2'}"
                class="w-56 h-5  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left  px-2 py-1 md:my-2">
                <i class="fa fa-clipboard fa-lg mr-2 text-green-700" [ngClass]="{'text-white' : optionMenu == '2'}"></i><span
                    class="text-sm text-white">{{ "Newsletter.title" | transloco }}</span>
            </button>
            <button type="button" [routerLink]="['/home-gallery']" [ngClass]="{'bg-blue boder shadow h-7' :optionMenu == '3'}"
                class="w-56 h-5  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left  px-2 py-1 md:my-2">
                <i class="fa fa-image fa-lg mr-2 text-green-700" [ngClass]="{'text-white' : optionMenu == '3'}"></i><span
                    class="text-sm text-white">{{ "gallery.title" | transloco }}</span>
            </button>
            <button type="button" [routerLink]="['/home-seo']" [ngClass]="{'bg-blue boder shadow h-7' :optionMenu == '4'}"
                class="w-56 h-5  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left  px-2 py-1 md:my-2">
                <i class="fas fa-search fa-lg mr-2 text-green-700" [ngClass]="{'text-white' : optionMenu == '4'}"></i><span
                    class="text-sm text-white">{{ "seo.title" | transloco }}</span>
            </button>
            <button type="button" [routerLink]="['/home-module-coordinador']" [ngClass]="{'bg-blue boder shadow h-7' :optionMenu == '5'}"
                class="w-56 h-5  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left  px-2 py-1 md:my-2">
                <i class="fas fa-tasks fa-lg mr-2 text-green-700" [ngClass]="{'text-white' : optionMenu == '5'}"></i><span
                    class="text-sm text-white">{{ "module-management.title" | transloco }}</span>
            </button>

        </ul>
    </nav>
</div> -->
