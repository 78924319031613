import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { content } from 'src/app/models/verioner-url';

@Component({
  selector: 'app-article-versioner',
  templateUrl: './article-versioner.component.html',
  styleUrls: ['./article-versioner.component.css']
})
export class ArticleVersionerComponent implements OnInit {
  versionData: content | null = null;

  ngOnInit(): void {
    const storedData = sessionStorage.getItem('versionData');
    if (storedData) {
      this.versionData = JSON.parse(storedData);
      console.log('Version data:', this.versionData); 
    } else {
      console.error('No version data found in sessionStorage');
    }
  }
  
}
