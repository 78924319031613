import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment as env } from 'src/environments/environment';
import { Router } from '@angular/router';
import { User } from 'src/app/models';
import { PulzoHubService } from './pulzo-hub.service';
import { StorageService } from './storage.service';
import { Debug } from '@sentry/integrations';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private baseUrl = `${env.apiCerberoUrl}`;
  private _user: User | null = null;
  isLoggedIn$: Observable<boolean> = of(this.isAuthenticated());
  public role: string;

  get user(): User | null {
    return this._user;
  }

  set user(user: User | null) {
    this.storageService.encryptAndSaveObject('user', user);
    if (user?.token) {
      this.storageService.encryptAndSaveObject('token', user?.token);
    }
    
    this._user = user;
  }

  constructor(private http: HttpClient, private router: Router, private pulzoHubService: PulzoHubService, private storageService: StorageService) {
    //console.log("PULZOHUB:",this.getPulzoHub());
    this.role = this.getRole();
  }

  isAuthenticated() {
    return !!this._user;
  }
  

  // public getPulzoHub(){
  //   return this.getPulzohub().toLowerCase();
  // }

  public getRole(): string {
    //const SEARCH_TERMS = ['redactor', 'coordinadorredaccion'];
    //const SEARCH_TERMS = env.permissionsNewsletter;
    const pulzohub = this.getPulzohub().toLowerCase();
    let role = '';
  
    const rolesArray = pulzohub.split(',');
    for (const element of rolesArray) {
      const rolesArray = element.split('@');
      const trimmedElement = rolesArray[1].trim().replace(/['"]+/g, ''); // Elimina comillas
      const appName = rolesArray[0].trim().replace(/['"]+/g, ''); // Elimina comillas
      
      if(env.nameAppHub.toLowerCase() == appName){
        role = trimmedElement;
        //console.log("Rol:" + role);
        break;
      }
    }
    
    return role;
  }

  public getUserLogged(){
    return this.storageService.decryptAndGetObject('user').email;
  }
  
  private getPulzohub(): string {
    let pulzohub: string = this.storageService.decryptAndGetObject('pulzohub');
    if (pulzohub) {
      //console.log("Pulzohub: ", pulzohub);
      pulzohub = pulzohub.replace("[", "");
      pulzohub = pulzohub.replace("]", "");
      pulzohub = pulzohub.replace("\"", "");
      pulzohub = pulzohub.replace("\"", "");
    } else {
      pulzohub = "redaccionapp@redactor";
    }

    return pulzohub;
  }

  logout() {
    this.user = null;
    this.http.get<any>(`${this.baseUrl}/logout`);
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    this.pulzoHubService.removePulzoHub();
    //localStorage.clear();
    //localStorage.empty();
    // this.router.navigate(['/login']);
    window.open(`${env.cerberoFrontURL}/home`, '_self');
  }
}
