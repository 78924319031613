import { Injectable } from '@angular/core';
import { environment as env } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { AddModule, DataItem, moduleStructure, RemoveModule, ResponseModel, SettingJson } from 'src/app/models';


@Injectable({
  providedIn: 'root'
})
export class ModuleManagementService {

  private baseUrl = `${env.apiCerberoUrl}`;
  private apiKey =`${env.apiKey}`;
  private sharedData: any;

  constructor(private http: HttpClient) { }

  getListModules(): Observable <ResponseModel>{
    return this.http.get<ResponseModel>(`${this.baseUrl}/request?event=modules`);
  }

  getEventListModule(field:string, value:string): Observable <ResponseModel>{
    let url = "";
    
    url = `${this.baseUrl}/request?event=search-module&field=${field}&value=${value}`;
    
    return this.http.get<ResponseModel>(url);
  }

updateModuleStatus(id: number, status: string): Observable<ResponseModel> {
  const body = { id, status }; 
  return this.http.put<ResponseModel>(`${this.baseUrl}/request?event=enable-disabled-module`, body);
}


  filterListTemplatesModules(): Observable<ResponseModel>{
  return this.http.get<ResponseModel>(`${this.baseUrl}/request?event=list-templates`) 
  }


  createModules(newModule: AddModule): Observable <ResponseModel>{
    return this.http.post<ResponseModel>(`${this.baseUrl}/request?event=modules`, newModule);
  }

  editModules(id: number){
    return this.http.get<ResponseModel>(`${this.baseUrl}/request?event=modules-edit&id=${id}`).pipe(
      map((resp: ResponseModel) => {
        if (resp.code === "200") {
          return resp.data as DataItem;
        } else {
          throw new Error('Error en la respuesta del servicio.');
        }
      })
    );
  }

  updateModules(update: DataItem): Observable <ResponseModel>{
    //console.log('serv', `${this.baseUrl}/request?event=modules`, update);
    return this.http.put<ResponseModel>(`${this.baseUrl}/request?event=modules`, update);
  }

  deleteModules(id: RemoveModule) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: id  
    };
    return this.http.delete<any>(`${this.baseUrl}/request?event=modules`, httpOptions);
  }

  saveJson(json: SettingJson):Observable<SettingJson>{
    return this.http.put<SettingJson>(`${this.baseUrl}/request?event=modules-save-config`, json);
  }

  StateModule(id: number, status: boolean): Observable<any> {
    console.log("id" + id + " status: " + status);
    const url = `${this.baseUrl}/request?event=modules-update-status`;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    // Preparar los datos a enviar en la solicitud PUT
    const body = { id, status };

    return this.http.put(url, body, httpOptions);
  }

  // Coordinador Redacción

  callModules():Observable<any> {
    return this.http.get(`${this.baseUrl}/request?event=modules`);
  }

  ModuleOpenForEdit(id:number):Observable<any> {
    return this.http.get(`${this.baseUrl}/request?event=modules-read-config&id=${id}`);
  }

  CallInfoArticle(id:number):Observable<any> {
    return this.http.get(`${this.baseUrl}/request?event=module-read-values-json&id=${id}`);
  }

  ReedJsonStructure(id:number):Observable<any> {
    return this.http.get(`${this.baseUrl}/request?id=${id}&event=module-json-struct`);
  }

  

  RemoveArticle(id: number, property: string, position: number): Observable<any> {
    const url = `${this.baseUrl}/request?event=module-remove-value-to-json`;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: { id, property, position }
    };
    return this.http.delete(url, httpOptions);
  }

  SendDataModuleManagement(id: number, property: string, value: string): Observable<any> {
    const url = `${this.baseUrl}/request?event=module-add-value-to-json`;
    const body = { id, property, value };

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.post(url, body, httpOptions);
  }


  // Templates
  getTemplates(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/request?event=list-templates`);
  }

  getTemplateByID(id:number): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/request?event=find-template-by-id&id=${id}`);
  }

  // Share data create module with new module 

  setData(data: any): void {
    this.sharedData = data;
  }

  getData(): any {
    return this.sharedData;
  }

  // Modules

  createModule(object:any): Observable<any> {
    const url = `${this.baseUrl}/request?event=create-module`;
    const body =  object;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post(url, body, httpOptions);
  }

  getModuleByID(id:number): Observable<any> {
    return this.http.get<moduleStructure>(`${this.baseUrl}/request?event=find-module-by-id&id=${id}`);
  }

  UpdateModule(object:moduleStructure, id:number): Observable<any> {
    const url = `${this.baseUrl}/request?event=update-module`;
    const body =  object;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.put(url, body, httpOptions);
  }

  getListNews(){
    return this.http.get<any>(`https://s3-us-west-2.amazonaws.com/filesstaticpulzo/pulzo-lite/jsons/admin/scroll_articles.json`);
  }

  getInfoArticle(url:string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/request?event=find-article-by-url&url=${url}`);
  }

  sendArticles(articles: any): Observable<any> {
    const url = `${this.baseUrl}/request?event=save-first-scroll`;
    return this.http.post<any>(url, articles);
  }
  


}
