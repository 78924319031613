import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthService, PulzoHubService } from 'src/app/core/services';
import { Newsletter } from 'src/app/models/newsletter';
import { environment } from 'src/environments/environment';
import { StorageService } from '../../../core/services/storage.service';
import { NewsletterService } from 'src/app/core/services/newsletter.service';

@Component({
  selector: 'app-home-newsletter',
  templateUrl: './home-newsletter.component.html',
  styleUrls: ['./home-newsletter.component.css']
})

export class HomeNewsletterComponent {
  createNewsletterform!: FormGroup;
  newsletterList: Newsletter[] = [];
  permissionNewsletter: string[] = [];
  thTitles = ['Nombre', 'Sección', 'Estado programación'];
  COORDINADOR: number = 0;
  REDACTOR: number = 1;
  pulzohubValues: string[] = [];

  constructor(private NewsletterService: NewsletterService, private logoutSrv: AuthService, private router: Router) {}

  ngOnInit(): void {
    this.getListNewsletter();
  }

  logout() {
    this.logoutSrv.logout()
  }

  getListNewsletter() {
    this.NewsletterService.getListNewsletter().subscribe((data: Newsletter[]) => {
      this.newsletterList = data;
    });
  }

  edit(id: number): void {
    this.router.navigate(['/create-newsletter'], { queryParams: { id: id } });
  }

  programmingNewsletter(id: number) {
    this.router.navigate(['/admin-newsletter'], { queryParams: { id: id } });
  }

  deleteNewsletter(idNewsletter: number): void {
    let newsletter: Newsletter = { id: idNewsletter } as Newsletter;

    Swal.fire({
      text: '¿Está seguro de eliminar este boletín de noticias?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    }).then((confirmDelete) => {
      if (confirmDelete.isConfirmed) {
        this.NewsletterService.deleteNewsletter(newsletter).subscribe(() => {
          Swal.fire({
            text: 'Se ha eliminado el boletín de noticias correctamente',
            icon: 'success',
          });
          this.getListNewsletter();
        });
      }
    });
  }
}