<div class="fixed inset-0 flex justify-center items-center z-50 backdrop-blur-lg bg-black bg-opacity-60">
    <div class="flex  justify-center items-center w-screen h-screen bg-transparent text-black">
        <div class="w-[440px] bg-white h-auto rounded-md py-7 px-7">
            <div class="header w-full gap-1 grid grid-cols-2 mb-4">
                <span class="col-span-3 flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none" class="mr-2">
                        <path d="M16.5 12.2644V17.5977M6.33854 20.5308C5.12596 22.6311 4.51985 23.6816 4.61042 24.5433C4.68942 25.2949 5.08389 25.9777 5.69531 26.422C6.39605 26.9311 7.60787 26.9311 10.0314 26.9311H22.9686C25.3921 26.9311 26.6038 26.9311 27.3045 26.422C27.9159 25.9777 28.3106 25.2949 28.3896 24.5433C28.4801 23.6816 27.8742 22.6311 26.6616 20.5308L20.1953 9.33081C18.9827 7.23056 18.3762 6.18062 17.5846 5.8282C16.8942 5.52081 16.1053 5.52081 15.4149 5.8282C14.6237 6.18047 14.0175 7.23042 12.806 9.32892L6.33854 20.5308ZM16.5677 21.5977V21.7311L16.4336 21.7313V21.5977H16.5677Z" stroke="#E63535" stroke-width="3.33333" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <h1 class="text-black font-bold text-2xl">{{ title }}</h1>
                </span>
            
                <span class="col-span-2 -mt-9 flex justify-end items-center cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none" class="ml-2">
                        <path d="M18.5 6.26465L6.5 18.2646" stroke="#1C1D22" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M6.5 6.26465L18.5 18.2646" stroke="#1C1D22" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </span>
            </div>
            

            <div class="inform-text text-lg mb-8">
                <p>{{ description }}</p>
            </div>


            <div class="inputBtn w-full flex flex-col items-center">
                <input type="text" class="w-96 h-12 bg-gray-transparent rounded-sm p-2 mb-8" placeholder="{{placeholder}}"
                    name="" id="">
                <button type="submit" (click)="callFunction()" class="w-52 h-12 bg-red-600 rounded-lg text-white">{{buttonName}}</button>
            </div>

        </div>
    </div>
</div>