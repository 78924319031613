import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SaveSettingNewsletter, Programmer, DeleteArticleNews, Respuesta, Newsletter  } from '../../models';
import { environment as env } from 'src/environments/environment';
import { map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {
  private baseUrl = `${env.apiCerberoUrl}`;
  
  constructor(private httpClient: HttpClient){}

  getListNewsletter(): Observable<Newsletter[]>{
   
    return this.httpClient.get<Respuesta>(`${this.baseUrl}/request?event=newsletters`).pipe(
      map((respuesta: Respuesta) => {
        if (respuesta.code === "200") {
          return respuesta.data as Newsletter[];
        } else {
          throw new Error('Error en la respuesta del servicio.');
        }
      })
    );
   
  }

  createNewsletter(newsletter:Newsletter): Observable<Respuesta>{
    return this.httpClient.post<Respuesta>(`${this.baseUrl}/request?event=newsletters`, newsletter);
  }

  deleteNewsletter(newsletter:Newsletter): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: newsletter  
    };
    return this.httpClient.delete<any>(`${this.baseUrl}/request?event=newsletters`, httpOptions);
  }


  searchNewsletter(id: number){
    return this.httpClient.get<Respuesta>(`${this.baseUrl}/request?event=newsletters-edit&id=${id}`).pipe(
      map((respuesta: Respuesta) => {
        if (respuesta.code === "200") {
          return respuesta.data as Newsletter;
        } else {
          throw new Error('Error en la respuesta del servicio.');
        }
      })
    );
  }
  
  updateNewsletter(newsletter: Newsletter): Observable<Respuesta>{
    return this.httpClient.put<Respuesta>(`${this.baseUrl}/request?event=newsletters`, newsletter);
  }


  addArticleToNewsletter(url: string, id: number){
    return this.httpClient.get<Programmer>(`${this.baseUrl}/request?event=newsletters-add-article&q=${url}&newsletter=${id}`);
  }

  programmerNewsletter(id: number){
    return this.httpClient.get<Programmer>(`${this.baseUrl}/request?event=newsletters-schedule&id=${id}`);
  }

  saveNewsletter(save: SaveSettingNewsletter): Observable<any>{
    //console.log('serv', `${this.baseUrl}/request?event=newsletters-schedule`, save);
    return this.httpClient.post<any>(`${this.baseUrl}/request?event=newsletters-schedule`, save);
  }

  deleteArticleNewsletter(deleteArt: DeleteArticleNews){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: deleteArt,  
    };
    return this.httpClient.delete<any>(`${this.baseUrl}/request?event=newsletters-remove-article`, httpOptions);

  }
}
