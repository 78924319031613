<app-header title="{{'main-menu.title' | transloco }}"></app-header>

<div class="flex mt-2">
    <app-sidebar optionMenu="7"></app-sidebar>

    <div class="w-3/4 mx-auto">
        <div class="bg-white rounded-2xl shadow-md p-8 mt-8 min-w-full h-auto flex flex-col">
            <h1 class="font-bold text-xl mt-4 md:text-2xl md:mt-1 mb-4">{{ 'main-menu.form' | transloco }}</h1>
        
            <span class="text-sm text-dark">{{ 'globals.requiredField' | transloco }}</span>

            <form [formGroup]="menuForm">
                <div class="grid grid-cols-2 gap-4">
                    <div class="py-2">
                        <label for="" class="text-dark text-xs uppercase font-thin tracking-widest ml-2 py-2 inline-block ">{{ 'main-menu.section' | transloco }} (*)</label>
                        <div class="w-full inline-block">
                            <input type="text" formControlName="name" class="py-2 w-full h-9 text-base rounded-xl pl-4 border border-dark-light  focus:ring-1 focus:outline-none focus:border-transparent focus:ring-dark-light">
                        </div>

                        <div *ngIf="checkValidation(1, menuForm.get('name'))" class="text-red text-sm">
                            <div *ngIf="checkValidation(2, menuForm.get('name'))"> {{ 'globals.required' | transloco }}</div>
                        </div>
                    </div> 
                    <div class="py-2">
                        <label for="" class="text-dark text-xs uppercase font-thin tracking-widest ml-2 py-2 inline-block">{{ 'main-menu.titleSection' | transloco }} (*)</label>
                        <div class="w-full inline-block">
                            <input type="text" formControlName="title" class="py-2 w-full h-9 text-base rounded-xl pl-4 border border-dark-light  focus:ring-1 focus:outline-none focus:border-transparent focus:ring-dark-light">
                        </div>  
                        
                        <div *ngIf="checkValidation(1, menuForm.get('title'))" class="text-red text-sm">
                            <div *ngIf="checkValidation(2, menuForm.get('title'))"> {{ 'globals.required' | transloco }}</div>
                        </div>
                    </div> 
                </div>

                <div class="grid grid-cols-2 gap-4">
                    <div class="py-2">
                        <label for="" class="text-dark text-xs uppercase font-thin tracking-widest ml-2 py-2 inline-block ">{{ 'main-menu.titleH1' | transloco }} (*)</label>
                        <div class="w-full inline-block">
                            <input type="text" formControlName="title_h1" class="py-2 w-full h-9 text-base rounded-xl pl-4 border border-dark-light  focus:ring-1 focus:outline-none focus:border-transparent focus:ring-dark-light">
                        </div> 
                        
                        <div *ngIf="checkValidation(1, menuForm.get('title_h1'))" class="text-red text-sm">
                            <div *ngIf="checkValidation(2, menuForm.get('title_h1'))"> {{ 'globals.required' | transloco }}</div>
                        </div>
                    </div> 

                    <div class="py-2">
                        <label for="" class="text-dark text-xs uppercase font-thin tracking-widest ml-2 py-2 inline-block">{{ 'main-menu.headerTitle' | transloco }} (*)</label>
                        <div class="w-full inline-block">
                            <input type="text" formControlName="header" class="py-2 w-full h-9 text-base rounded-xl pl-4 border border-dark-light  focus:ring-1 focus:outline-none focus:border-transparent focus:ring-dark-light">
                        </div> 
                        
                        <div *ngIf="checkValidation(1, menuForm.get('header'))" class="text-red text-sm">
                            <div *ngIf="checkValidation(2, menuForm.get('header'))"> {{ 'globals.required' | transloco }}</div>
                        </div>
                    </div> 
                   
                </div>

                <div class="grid grid-cols-2 gap-4">

                    <div class="py-2">
                        <label for="" class="text-dark text-xs uppercase font-thin tracking-widest ml-2 py-2 inline-block">{{ 'main-menu.location' | transloco }} (*)</label>
                        <div class="w-full inline-block">
                            <select formControlName="location" class="py-2 w-full h-9 text-base rounded-xl pl-4 border border-dark-light  focus:ring-1 focus:outline-none focus:border-transparent focus:ring-dark-light">
                                <option value="">Seleccione</option>
                                <option value="menu">Menú</option>
                                <option value="landing">Landing propios</option>
                                <option value="redes_sociales">Redes sociales</option>
                            </select>
                        </div> 
                        
                        <div *ngIf="checkValidation(1, menuForm.get('location'))" class="text-red text-sm">
                            <div *ngIf="checkValidation(2, menuForm.get('location'))"> {{ 'globals.required' | transloco }}</div>
                        </div>
                    </div> 
                    
                    <div class="py-2">
                        <label for="" class="text-dark text-xs uppercase font-thin tracking-widest ml-2 py-2 inline-block">{{ 'main-menu.sectionOf' | transloco }}</label>
                        <div  class="w-full inline-block">
                            <select formControlName="parent_id" class="py-2 w-full h-9 text-base rounded-xl pl-4 border border-dark-light  focus:ring-1 focus:outline-none focus:border-transparent focus:ring-dark-light">
                                <option value="0">Seleccione...</option>
                                <option *ngFor="let parents of sectionParents" value="{{parents.id}}">{{parents.name}}</option>
                            </select>
                        </div>    
                    </div> 
                </div>

                <div class="grid grid-cols-2 grid-rows-2 gap-4">
                    <div class="py-2 row-span-3">
                        <label for="" class="text-dark text-xs uppercase font-thin tracking-widest ml-2 py-2 inline-block">{{ 'main-menu.description' | transloco }} (*)</label>
                        <div class="w-full inline-block">
                            <textarea formControlName="description" class="py-2 w-full h-32 text-base rounded-xl pl-4 border border-dark-light  focus:ring-1 focus:outline-none focus:border-transparent focus:ring-dark-light"></textarea>
                            <input type="text" >
                        </div>  
                        
                        <div *ngIf="checkValidation(1, menuForm.get('description'))" class="text-red text-sm">
                            <div *ngIf="checkValidation(2, menuForm.get('description'))"> {{ 'globals.required' | transloco }}</div>
                        </div>
                    </div>

                    <div class="py-2">
                        <label for="" class="text-dark text-xs uppercase font-thin tracking-widest ml-2 py-2 inline-block">{{ 'main-menu.slug' | transloco }} </label>
                        <div class="w-full inline-block">
                            <input type="text" formControlName="slug" class="py-2 w-full h-9 text-base rounded-xl pl-4 border border-dark-light  focus:ring-1 focus:outline-none focus:border-transparent focus:ring-dark-light">
                        </div>    
                    </div> 

                    <div *ngIf="menuForm.get('id')?.value" class="relative inline-block w-10 ml-5 align-middle select-none transition duration-200 ease-in">
                        <label for="" class="text-dark text-xs uppercase font-thin tracking-widest ml-2 py-2 inline-block">{{ 'globals.asset' | transloco }}?</label>
                        <input type="checkbox" formControlName="status"
                          class=" toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"/>
                        <label for="toggle" class="toggle-label block overflow-hidden h-6 rounded-full bg-dark-dark5 cursor-pointer"></label>
                    </div>
                </div>

                

                <div class="py-6">
                    <button type="submit" (click)="submitForm()" class="items-center w-44 h-10 border rounded-md  bg-green-MainColor flex">
                        <span class="text-white text-center font-semibold text-sm m-auto">{{ 'globals.save' | transloco }}</span>
                    </button>
                </div>
        
            </form>
            
        </div> 

        <div class="bg-white rounded-2xl shadow-md p-8 mt-8 min-w-full h-auto flex flex-col">        
            <h2 class="font-bold text-xl my-5 ml-5">{{ 'main-menu.title' | transloco }}</h2>

              <table class="mx-auto w-full table-auto overflow-hidden divide-y divide-light-light">
                <thead>
                    <tr>
                        <th class="px-6 py-5 border-b-2 border-light-dark text-left p-2 text-xs md:text-sm font-bold uppercase tracking-wider">{{ 'main-menu.section' | transloco }}</th>
                        <th class="py-5 border-b-2 border-light-dark text-center p-2 text-xs md:text-sm font-bold uppercase tracking-wider">{{ 'main-menu.sectionOf' | transloco }}</th>
                        <th class="py-5 border-b-2 border-light-dark text-center p-2 text-xs md:text-sm font-bold uppercase tracking-wider">{{ 'main-menu.slug' | transloco }}</th>
                        <th class="py-5 border-b-2 border-light-dark text-center p-2 text-xs md:text-sm font-bold uppercase tracking-wider">{{ 'globals.options' | transloco }}</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let list of dataList" class="border-b border-gray-200 relative text-dark-light">
                        <td class="px-6 py-4 text-left">{{list.name}}</td>
                        <td class="px-6 py-4 text-center">{{list.parent_name}}</td>
                        <td class="px-6 py-4 text-center">{{list.slug}}</td>
                        <td class="px-6 py-4 text-center">
                            <i class="far fa-edit fa-lg text-green-MainColor mr-5 cursor-pointer" (click)="getEdit(list.id)"></i>
                            <i class="fas fa-trash-alt fa-lg text-red-light cursor-pointer" (click)="delete(list.id)"></i>
                        </td>
                    </tr>
                </tbody>
              </table>
        </div>  
    </div>
    
</div>