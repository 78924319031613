import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeNewsletterComponent } from './home-newsletter/home-newsletter.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { CreateNewsletterComponent } from './create-newsletter/create-newsletter.component';
import { AdminNewsletterComponent } from './admin-newsletter/admin-newsletter.component';
import { SharedModule } from '../shared/shared.module';
import { ShowForRolesDirective } from 'src/app/core/guards/directives/show-for-roles.directive';


import { 
  BasicTableIrisModule, 
  BuscadorModule, 
  ButtonsIrisModule, 
  HeaderModule, 
  InputFileModule, 
  InputIrisModule, 
  NavbarModule, 
  PaginadorIrisModule, 
  TextAreaModule 
} from 'iris-front';


@NgModule({
  declarations: [
    HomeNewsletterComponent,
    CreateNewsletterComponent,
    AdminNewsletterComponent,
    ShowForRolesDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslocoModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HeaderModule,
    NavbarModule,
    BasicTableIrisModule,
    PaginadorIrisModule,
    BuscadorModule,
    ButtonsIrisModule,
    InputIrisModule,
    InputFileModule,
    TextAreaModule,
    SharedModule
  ],
  exports:[ShowForRolesDirective]
})
export class NewsletterModule { }
