<app-header></app-header>
<div class="min-h-screen flex flex-col items-center justify-center">
    <div class="w-full max-w-3xl flex items-center mb-4 cursor-pointer">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 19L8 12L15 5" stroke="#E67A00" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <span class="text-xs not-italic font-bold leading-6 uppercase">ATRÁS</span>
    </div>
  
    <div class="w-full max-w-3xl bg-white p-8 rounded-lg shadow">
      <h1 class="text-2xl not-italic font-bold leading-8 mb-6">Versionador de artículos</h1>
  
      <div class="space-y-6">
        <div class="flex flex-col">
          <label class="block text-sm font-medium text-gray-700" for="url">URL del artículo 1</label>
          <form [formGroup]="formGroup">
            <input type="text" class="h-12 mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm bg-gray-200" id="url" formControlName="url" placeholder="URL">
          </form>
        </div>
      </div>
    </div>
  
    <div class="w-full max-w-3xl mt-6">
      <button (click)="generarVersion()" type="submit" class="font-bold py-2 px-4 rounded-md hover:bg-green-700 flex items-center bg-green-700">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M12 5V19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M5 12H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <span class="text-white font-semibold text-sm pl-3">Generar versión</span>
      </button>
    </div>
  </div>
  <!-- Indicador de carga -->
<div *ngIf="loading" class="loading-overlay">
  <div class="spinner">Cargando...</div>
</div>


  