import { Component, OnInit, Sanitizer } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NewsletterService } from 'src/app/core/services';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-admin-newsletter',
  templateUrl: './admin-newsletter.component.html',
  styleUrls: ['./admin-newsletter.component.css'],
})
export class AdminNewsletterComponent implements OnInit {
  horas: Array<string> = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0'));
  minutos: Array<string> = ['00', '15', '30', '45'];
  linkArticle!: string;
  formNewsletter!: FormGroup;
  idNews!: number;
  htmlTable!: any;
  header: string = '';
  footer: string = '';
  hora!: string;
  dataForm: any;
  horaSeparada!: string[];

  constructor(private newsletterService: NewsletterService, private route: ActivatedRoute, private sanitizer: DomSanitizer) {   }

  ngOnInit(): void {
    this.formNewsletter = new FormGroup({
      subject: new FormControl(null, [Validators.maxLength(150), Validators.required]),
      date: new FormControl(null, Validators.required),
      hour: new FormControl(null, Validators.required),
      minute: new FormControl(null, Validators.required),
    })

    //Trae el id
    this.route.queryParams.subscribe(params => {
      this.idNews = parseInt(params['id']);
      //console.log('id', this.idNews);
    });

    //Trae la info de la tabla y del formulario
    this.newsletterService.programmerNewsletter(this.idNews).subscribe((response) => {
      //console.log('prog', response.data);
      this.htmlTable = response.data.html;
      this.dataForm = response.data;
      this.horaSeparada = this.dataForm.hour.split(':');

      this.htmlTable = response.data.content.articles
      this.header = response.data.content.header
      this.footer = response.data.content.footer

      if (this.dataForm.status === 'programado') {
        this.formNewsletter = new FormGroup({
          subject: new FormControl(this.dataForm.subject, [Validators.maxLength(150), Validators.required]),
          date: new FormControl(this.dataForm.date, Validators.required),
          hour: new FormControl(this.horaSeparada[0], Validators.required),
          minute: new FormControl(this.horaSeparada[1], Validators.required),
        });
      }
    })
  }


  addArticleNewsletter() {
    this.newsletterService.addArticleToNewsletter(this.linkArticle, this.idNews).subscribe((response) => {
      //console.log('link', response);
      this.htmlTable = response.data.content.articles
      this.header = response.data.content.header
      this.footer = response.data.content.footer
      
      this.linkArticle = '';
    });
  }

  //Para sanitizar contenido Html y que acepte los estilos
  getTrustedHeader(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.header);
  }

  getTrustedFooter(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.footer);
  }

  getTrustedAnything(someString: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(someString);
  }

  deleteArticle(index: number){
    console.log('funcion eliminar', index);
    const request = {
      idNewsletter: this.idNews,
      idArticle: index,
    }

    this.newsletterService.deleteArticleNewsletter(request).subscribe((response) => {
      Swal.fire({
        text: 'Se ha eliminado la nota correctamente',
        icon: 'success',
      });

      this.htmlTable = response.data.content.articles
      this.header = response.data.content.header
      this.footer = response.data.content.footer
      
    })
  }

  //Validación campos
  checkValidation(option: number, field: any) {
    if (option == 1) {
      return field.invalid && (field.dirty || field.touched);
    } else {
      return field.errors?.['required'];
    }
  }

  submitNewsletter() {
    this.hora = this.formNewsletter.value.hour + ':' + this.formNewsletter.value.minute;

    const request = {
      id: this.idNews,
      subject: this.formNewsletter.value.subject,
      date: this.formNewsletter.value.date,
      hour: this.hora,

    }

    if (this.formNewsletter.valid) {

      this.newsletterService.saveNewsletter(request).subscribe(() => {
        Swal.fire('', 'Newsletter guardado exitosamente', 'success');
      })
    } else {
      Swal.fire('', 'Campos incompletos', 'warning');
    }
  }

}
