import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from './core/guards/auth.guard';
import { HasRoleGuard } from './core/guards/has-role.guard';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from './layout/components/main-layout/main-layout.component';
import { HomeComponent } from './modules/home/home.component';
import { HomeNewsletterComponent } from './modules/newsletter/home-newsletter/home-newsletter.component';
import { CreateNewsletterComponent } from './modules/newsletter/create-newsletter/create-newsletter.component';
import { AdminNewsletterComponent } from './modules/newsletter/admin-newsletter/admin-newsletter.component';
import { CreateGalleryComponent } from './modules/gallery/create-gallery/create-gallery.component';
import { HomeGalleryComponent } from './modules/gallery/home-gallery/home-gallery.component';
import { HomeSeoComponent } from './modules/seo/home-seo/home-seo.component';
import { HomeModulesComponent } from './modules/module-management/home-modules/home-modules.component';
import { ListTemplateComponent } from './modules/module-management/new-template/list-template.component';
import { DuplicateTemplateComponent } from './modules/module-management/home-modules-coordinador/duplicate-template.component';
import { HomeVersionerComponent } from './modules/versioner-module/home-versioner/home-versioner.component'; //
import { ArticleVersionerComponent } from './modules/versioner-module/article-versioner/article-versioner.component';
import { CreateModuleComponent } from './modules/module-management/create-module/create-module.component';
import { EditTemplateComponent } from './modules/module-management/edit-template/edit-template.component';
import { NewModuleComponent } from './modules/module-management/new-module/new-module.component';
import { DeleteModalComponent } from './modules/shared/delete-modal/delete-modal.component';
import { EditModuleComponent } from './modules/module-management/edit-module/edit-module.component';
import { HomeLinksListComponent } from './modules/links-list/home-links-list/home-links-list.component';
import { CreateLinkListComponent } from './modules/links-list/create-link-list/create-link-list.component';
import { EditLinkListComponent } from './modules/links-list/edit-link-list/edit-link-list.component';
import { HomeMenuComponent } from './modules/main-menu/home-menu/home-menu.component';
import { FirstNoteScrollComponent } from './modules/module-management/first-note-scroll/first-note-scroll.component';



const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        canActivate: [AuthGuard],
        path: 'home',
        component: HomeComponent,
      },
      {
        canActivate: [AuthGuard],
        path: 'home-newsletter',
        component: HomeNewsletterComponent,
      },
      {
        canLoad:[HasRoleGuard],
        canActivate:[HasRoleGuard],
        data:{
          allowedRoles:  ['redactor','adminredaccion']
        },
        path: 'create-newsletter',
        component: CreateNewsletterComponent,
      },
      {
        canLoad:[HasRoleGuard],
        canActivate:[HasRoleGuard],
        data:{
          allowedRoles:  ['redactor','adminredaccion']
        },
        path: 'admin-newsletter',
        component: AdminNewsletterComponent,
      },
      {
        canActivate: [AuthGuard],
        path: 'home-gallery',
        component: HomeGalleryComponent,
      },
      {
        canActivate: [AuthGuard],
        path: 'create-gallery',
        component: CreateGalleryComponent,
      },
      {
        canActivate: [AuthGuard],
        path: 'home-seo',
        component: HomeSeoComponent,
      },
      /*  Generador de modulos  */ 
      {
        canLoad:[HasRoleGuard],
        canActivate:[HasRoleGuard],
        data:{
          allowedRoles: ['redactor']
        },
        path: 'home-module',
        component: HomeModulesComponent,
      },
      {
        canLoad:[HasRoleGuard],
        canActivate:[HasRoleGuard],
        data:{
          allowedRoles: ['redactor']
        },
        path: 'edit-module',
        component: EditModuleComponent,
      },
        {
        canLoad:[HasRoleGuard],
        canActivate:[HasRoleGuard],
        data:{
          allowedRoles:  ['adminredaccion','redactor']
        },
        path:'delete-modal',
        component: DeleteModalComponent,
      },
      {
        canLoad:[HasRoleGuard],
        canActivate:[HasRoleGuard],
        data:{
          allowedRoles: ['adminredaccion']
        },
        path: 'new-template',
        component: ListTemplateComponent,
      },
      {
        canLoad:[HasRoleGuard],
        canActivate:[HasRoleGuard],
        data:{
          allowedRoles: ['adminredaccion']
        },
        path: 'list-template',
        component: DuplicateTemplateComponent,
      },
      {
        canLoad:[HasRoleGuard],
        canActivate:[HasRoleGuard],
        data:{
          allowedRoles: ['adminredaccion','redactor']
        },
        path: 'home-versioner',
        component: HomeVersionerComponent,
      },
      {
        canLoad:[HasRoleGuard],
        canActivate:[HasRoleGuard],
        data:{
          allowedRoles: ['adminredaccion','redactor']
        },
        path: 'article-versioner',
        component: ArticleVersionerComponent,
      },
      {
        canLoad:[HasRoleGuard],
        canActivate:[HasRoleGuard],
        data:{
          allowedRoles: ['adminredaccion']
        },
        path: 'edit-template',
        component: EditTemplateComponent,
      },
      {
        canActivate:[HasRoleGuard],
        data:{
           allowedRoles: ['redactor']
         },
        path: 'crud-module',
        component: CreateModuleComponent,
      },
      {
        canActivate:[HasRoleGuard],
        data:{
          allowedRoles: ['redactor']
        },
        path: 'create-module',
        component: CreateModuleComponent,
      },
      {
         canActivate:[HasRoleGuard],
         data:{
           allowedRoles: ['redactor']
         },
        path: 'new-module',
        component: NewModuleComponent,
      },
      {
        canLoad:[HasRoleGuard],
        canActivate:[HasRoleGuard],
        data:{
          allowedRoles: ['adminredaccion','redactor']
        },
        path: 'list-links',
        component: HomeLinksListComponent,
      },
      {
        canLoad:[HasRoleGuard],
        canActivate:[HasRoleGuard],
        data:{
          allowedRoles: ['adminredaccion','redactor']
        },
        path: 'create-list',
        component: CreateLinkListComponent,
      },
      {
        canLoad:[HasRoleGuard],
        canActivate:[HasRoleGuard],
        data:{
          allowedRoles: ['adminredaccion','redactor']
        },
        path: 'edit-list',
        component: EditLinkListComponent,
      },
      {
        canLoad:[HasRoleGuard],
        canActivate:[HasRoleGuard],
        data:{
          allowedRoles: ['adminredaccion']
        },
        path: 'main-menu',
        component: HomeMenuComponent,
      },
      {
        canLoad:[HasRoleGuard],
        canActivate:[HasRoleGuard],
        data:{
          allowedRoles: ['adminredaccion']
        },
        path: 'first-note-scroll',
        component: FirstNoteScrollComponent,
      },

    ],

  },
]

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
    CommonModule
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
