import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Gallery, Photo } from 'src/app/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UploadImageService } from 'src/app/core/services/uploadImage.service';
import { finalize } from 'rxjs';
import { UploadGalleryService } from 'src/app/core/services/uploadGallery.service';
import Swal from 'sweetalert2';
import { GetGalleryService } from 'src/app/core/services/getGallery.service';
import { UpdateGalleryService } from 'src/app/core/services/updateGallery.service';
import { DeletePhotoFromGalleryService } from 'src/app/core/services/deletePhoto.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';


@Component({
  selector: 'app-create-gallery',
  templateUrl: './create-gallery.component.html',
  styleUrls: ['./create-gallery.component.css']
})
export class CreateGalleryComponent {
  formPrueba!: FormGroup;
  formGallery!: FormGroup;
  formData: Photo[] = [];
  submitted = false;
  public archivos: any[] = []
  editRecordId = null;
  photo!: Photo;
  gallery!: Gallery;
  srcImageDesktop!: string;
  srcImageMobile!: string;
  desktopFileToUpload: File | null = null;
  mobileFileToUpload: File | null = null;
  imgPrev!: string;
  loading: boolean = false;
  idParam!: string;
  public item: string | null = this._router.snapshot.paramMap.get('id');
  indexArray!: number | undefined;
  isEddting: boolean = false;
  isError: boolean = false;

  constructor(private fb: FormBuilder, private deleteFromGallerySrv: DeletePhotoFromGalleryService, private uploadService: UploadImageService, private uploadGallerySrv: UploadGalleryService, private _router: ActivatedRoute, private getGallerySrv: GetGalleryService, private router: Router, private updateGallerySrv: UpdateGalleryService) {
   }

  ngOnInit(): void {

    if (this.item !== null) {
      this.getGallerySrv.getGallery(this.item).subscribe((response) => {
        this.formData = response.images
        this.formGallery = this.fb.group({
          id: response.id,
          name: response.name,
          images: response.images
        })
      }
      )
    }
    this.formPrueba = this.fb.group({
      fileDesktop: ['', Validators.required],
      fileMobile: ['', Validators.required],
      photoTitle: ['', Validators.required],
      alt: ['', Validators.required],
      description: ['', Validators.required],
      credits: ['', Validators.required],
    });

    this.formGallery = this.fb.group({
      name: ['', Validators.required],
      images: [this.formData]
    })


  }


  submitGalleryForm() {
    if (this.formGallery.invalid) {
      Swal.fire("Faltan campos obligatorios")
    } else {
      if (this.item !== null) {

        this.gallery = {
          id: this.formGallery.value.id,
          name: this.formGallery.value.name,
          images: this.formData
        }

        this.updateGallery(this.gallery)
        this.router.navigate(['/home-gallery']);
        Swal.fire('', 'La galería se ha actualizado con éxito', 'success')
      } else {
        this.gallery = {
          name: this.formGallery.value.name,
          images: this.formData
        }
        this.uploadGallery(this.gallery);
        this.router.navigate(['/home-gallery']);
        Swal.fire('', 'La galería se ha creado con éxito', 'success')
      }
    }
  }

  onPhotoSubmit() {
    this.submitted = true;
    this.photo = {
      title: this.formPrueba.value.photoTitle,
      alt: this.formPrueba.value.alt,
      description: this.formPrueba.value.description,
      pathDesktop: this.formPrueba.value.fileDesktop,
      pathMobile: this.formPrueba.value.fileMobile,
      copyright: this.formPrueba.value.credits,
    };

    this.loading = true;
    if (!this.isError) {
      
      if (!Object.values(this.photo).every(x => x === null || x === '')) {
        if (this.mobileFileToUpload !== null) {
          this.uploadImageToS3(this.mobileFileToUpload).subscribe((response) => {
            this.photo.pathMobile = response.path
          })
        } else {
          if ((this.indexArray && this.indexArray >= 0) || this.indexArray === 0) {
            this.photo.pathMobile = this.formData[this.indexArray].pathMobile
          }
        }
        if (this.desktopFileToUpload !== null) {
          this.uploadImageToS3(this.desktopFileToUpload).subscribe((response) => {
            this.photo.pathDesktop = response.path
          })
        } else {
          if ((this.indexArray && this.indexArray >= 0) || this.indexArray === 0) {

            this.photo.pathDesktop = this.formData[this.indexArray].pathDesktop
          }
        }


        if (this.item === null) {
          if (this.isEddting === true) {
            if (this.indexArray !== null && this.indexArray !== undefined && this.indexArray > -1) {
              this.formData.splice(this.indexArray, 1, this.photo)
            }
          } else {
            this.formData.push(this.photo)
          }

        } else {
          this.photo.title = this.formPrueba.value.photoTitle
          this.photo.alt = this.formPrueba.value.alt
          this.photo.description = this.formPrueba.value.description
          this.photo.copyright = this.formPrueba.value.credits
          if ((this.indexArray && this.indexArray >= 0) || this.indexArray === 0) {
            this.photo.id = this.formData[this.indexArray].id
          }
          if (this.indexArray !== null && this.indexArray !== undefined && this.indexArray > -1) {
            this.formData.splice(this.indexArray, 1, this.photo)
          } else {
            this.formData.push(this.photo)
          }


        }

        this.formPrueba.reset({
          fileDesktop: '',
          fileMobile: '',
          galleryTitle: '',
          photoTitle: '',
          alt: '',
          description: '',
          credits: '',
        }
        )
        
        // this.photo.pathDesktop = ""
        // this.photo.pathMobile = ""
      } else {
        Swal.fire("Faltan campos obligatorios")
      }
      console.log(this.photo)
    }else {
      this.isError = false;
      
      Swal.fire("Falta elegir las fotos")
    }
  }

  captureFile(event: any) {
    const isDesktop = document.querySelector('desktop')
    const isMobile = document.querySelector('mobile')
    document.body.addEventListener('click', function () {
      if (isDesktop?.contains(event.target)) {

      }
    })
    const extValid = ['jpg', 'jpeg', 'png'];
    const archivoCapturado = event.target.files[0];
    const ext = archivoCapturado.type.split('/');

    if (extValid.includes(ext[ext.length - 1].toLowerCase()) === false) {
      Swal.fire(
        'Error',
        'La extensión de la imagen que seleccionaste es: ' +
        ext[ext.length - 1].toLowerCase() +
        ' la cual no es válida, se aceptan imágenes en jpg o png',
        'error'
      );
      this.isError = true;

    } else if (archivoCapturado.size > 2000000) {
      Swal.fire(
        'Error',
        'El peso de la imagen que seleccionaste es muy grande, recuerda que el peso máximo es de 2MB',
        'error'
      );
      this.isError = true;
    } else {

      this.archivos[0] = archivoCapturado;
      // File Preview
      const reader = new FileReader();
      reader.onload = () => {
        if (event.target === document.querySelector('#desktop')) {
          this.srcImageDesktop = reader.result as string;
        } else {
          this.srcImageMobile = reader.result as string;
        }

      };
      reader.readAsDataURL(archivoCapturado);
    }
  }

  clearPhotoPreview() {
    this.srcImageDesktop = ''
    this.srcImageMobile = ''
  }

  handleFileInput(event: Event) {
    const element = event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;
    if (fileList) {
      console.log(fileList)
      if (event.target === document.querySelector('#desktop')) {
        this.desktopFileToUpload = fileList[0]
      } else {
        this.mobileFileToUpload = fileList[0]
      }
    }
  }

  uploadImageToS3(file: any) {
    return this.uploadService.uploadImageS3(file).pipe(
      finalize(() => (
        this.loading = false
      )),
    )


  }

  uploadGallery(gallery: Gallery) {
    return this.uploadGallerySrv.uploadGallery(gallery).subscribe((response) => {

    })
  }

  edit(id: string | undefined, index?: number | undefined) {
    this.isEddting = true;
    this.indexArray = index;
    if (id) {
      let filterFunction = (value: any, index: any, array: any) => {
        return value.id == id
      }
      const editPhoto = this.formData.filter(filterFunction)
      console.log("Path mobile", editPhoto[0].pathMobile)
      this.srcImageMobile = editPhoto[0].pathMobile
      this.srcImageDesktop = editPhoto[0].pathDesktop
      this.formPrueba = this.fb.group({
        fileDesktop: [editPhoto[0].pathDesktop, Validators.required],
        fileMobile: [editPhoto[0].pathMobile, Validators.required],
        photoTitle: [editPhoto[0].title, Validators.required],
        alt: [editPhoto[0].alt, Validators.required],
        description: [editPhoto[0].description, Validators.required],
        credits: [editPhoto[0].copyright, Validators.required],
        id: [editPhoto[0].id]
      });
    }


    if ((index && index >= 0) || index === 0) {
      this.srcImageMobile = this.formData[index].pathMobile
      this.srcImageDesktop = this.formData[index].pathDesktop
      this.formPrueba = this.fb.group({
        fileDesktop: ['', Validators.required],
        fileMobile: ['', Validators.required],
        photoTitle: [this.formData[index].title, Validators.required],
        alt: [this.formData[index].alt, Validators.required],
        description: [this.formData[index].description, Validators.required],
        credits: [this.formData[index].copyright, Validators.required],
      });
    }
  }

  deletePhotoFromArray(index: number) {
    Swal.fire({
      title: 'Advertencia',
      text: '¿Estás seguro de eleminar la foto?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí',
      cancelButtonText: "No"
    }).then((result) => {
      if (result.isConfirmed) {
        this.formData.splice(index, 1)
      }
    });

  }

  deletePhotoFromGallery(index: string | undefined, arrayIndex: number) {
    Swal.fire({
      title: 'Advertencia',
      text: '¿Estás seguro de eleminar la foto de la galería?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí',
      cancelButtonText: "No"
    }).then((result) => {
      if (result.isConfirmed) {
        if (index) {
          this.deleteFromGallerySrv.deletePhotoFromGallery(index).subscribe()
          this.formData.splice(arrayIndex, 1)
        }
      }
    });
  }

  updateGallery(gallery: Gallery) {
    return this.updateGallerySrv.updateGallery(this.gallery).subscribe()
  }

}
