<app-back-header link='/home-newsletter'></app-back-header>

<div class="md:flex mt-8 w-full rounded-2xl mb-10 pl-3 pr-3">
  <div class="w-full max-w-md mx-auto">
    <h1 class="font-bold text-xl mt-4 md:text-2xl md:mt-1 mb-4">{{ 'Newsletter.TittleNewsletter' | transloco }}</h1>

    <form [formGroup]="formNewsletter" class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
      (ngSubmit)="submit()">
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2">Nombre</label>

        <div class="w-full  md:inline-block">
          <input class="shadow appearance-none border rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            formControlName="name" type="text" placeholder="Nombre"
            [ngClass]="{'border-red': checkValidation(1, formNewsletter.get('name'))}">

          <div *ngIf="checkValidation(1, formNewsletter.get('name'))" class="text-red text-sm">
            {{ 'Newsletter.character80' | transloco }}
            <div *ngIf="checkValidation(2, formNewsletter.get('name'))"> {{ 'admin.user.form.required' | transloco}}</div>
          </div>
        </div>
      </div>

      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2">Sección</label>

        <select class="shadow appearance-none border rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          formControlName="section" [ngClass]="{'border-red': checkValidation(1, formNewsletter.get('section'))}">
          <!-- <option value="" disabled selected>Seleccione una opción</option> -->
          <option *ngFor="let opcion of secciones" [value]="opcion">{{ opcion }}</option>
        </select>

        <div *ngIf="checkValidation(1, formNewsletter.get('section'))" class="text-red text-sm">
          <div *ngIf="checkValidation(2, formNewsletter.get('section'))"> {{ 'admin.user.form.required' | transloco }}</div>
        </div>
      </div>


      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2">Audiencia</label>
        <select class="shadow appearance-none border rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          formControlName="recipients" [ngClass]="{'border-red': checkValidation(1, formNewsletter.get('recipients'))}">
          <option *ngFor="let opcion of audiences" [value]="opcion.segment_id">{{ opcion.audience }}</option>
        </select>

        <div *ngIf="checkValidation(1, formNewsletter.get('recipients'))" class="text-red text-sm">
          {{ 'Newsletter.character80' | transloco }}
          <div *ngIf="checkValidation(2, formNewsletter.get('recipients'))"> {{ 'admin.user.form.required' | transloco }}</div>
        </div>
      </div>

      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2">Plantilla</label>
        <select class="shadow appearance-none border rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          formControlName="html_template" [ngClass]="{'border-red': checkValidation(1, formNewsletter.get('html_template'))}">
          <option *ngFor="let opcion of template" [value]="opcion">{{ opcion }}</option>
        </select>

        <div *ngIf="checkValidation(1, formNewsletter.get('html_template'))" class="text-red text-sm">
          <div *ngIf="checkValidation(2, formNewsletter.get('html_template'))">{{ 'admin.user.form.required' |  transloco }}</div>
        </div>
      </div>

      <div class="mb-4" *ngIf="isUpdate">
        <label class="block text-gray-700 text-sm font-bold mb-2"> Estado</label>
        <select  class="shadow appearance-none border rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          formControlName="state">
          <option value="active">Activo</option>
          <option value="inactive">Inactivo</option>
        </select>
      </div>


      <div class="flex items-center justify-between">
        <div class="m-auto">
          <lib-buttons-iris typeValue="submit"></lib-buttons-iris>
        </div>
      </div>
    </form>
  </div>
</div>