import { Component } from '@angular/core';
import { ModuleManagementService } from 'src/app/core/services/module-management.service';
import { HttpClient } from '@angular/common/http'; // Importa HttpClient
import { Observable } from 'rxjs';
import { DataArticleFounded, ListArticles, ListNews, New } from 'src/app/models/first-note-scroll';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-first-note-scroll',
  templateUrl: './first-note-scroll.component.html',
  styleUrls: ['./first-note-scroll.component.css']
})
export class FirstNoteScrollComponent {

  formGroup!: FormGroup;
  url:string = "";
  dataNewArticle:DataArticleFounded;

  dataArticleBuild:New;
  arrayArticles: ListArticles = {
    articles: []  // Inicializa como un array vacío
  };
  
  

constructor(private moduleService: ModuleManagementService, private http: HttpClient, private fB:FormBuilder){
  this.getListNews();

  this.formGroup = this.fB.group({
    url: ['', Validators.required]
  });



  
}

getListNews(){
  // prod
  // https://s3-us-west-2.amazonaws.com/filesstaticpulzo/pulzo-lite/jsons/admin/scroll_articles.json
  // const jsonUrl = 'https://s3-us-west-2.amazonaws.com/filesstaticpulzo/pulzo-lite/jsons/admin/scroll_articles.json';
  const jsonUrl = 'https://s3-us-west-2.amazonaws.com/filesstaticpulzo/pulzo-dev/jsons/admin/scroll_articles.json';
  fetch(jsonUrl)
      .then(response => {
          if (!response.ok) {
              throw new Error('Error al realizar la solicitud');
          }
          return response.json();
      })
      .then(data => {
        
          this.arrayArticles.articles = data;
          console.log("Values: ",this.arrayArticles);
      })
      .catch(error => {
          console.error('Error al obtener los datos:', error);
      });

   
}


async addNew() {
  try {
    const response = await this.moduleService.getInfoArticle(this.formGroup.value.url).toPromise();
    this.dataNewArticle = response.data;
    console.log("Value Article Founded: ", this.dataNewArticle);

    this.dataArticleBuild = {
      title: '',
      image: '',
      url: '',
      section: '',
      article: ''
    };

    if (this.dataNewArticle && this.dataNewArticle.titles) {
      const exists = this.arrayArticles.articles.some(article => article.article === this.dataNewArticle.id);
    
      if (!exists) {
        console.log("Valor titulo: " + this.dataNewArticle.titles.main);
    
        this.dataArticleBuild = {
          title: this.dataNewArticle.titles.main,
          image: this.dataNewArticle.images.types.square.original,
          url: this.formGroup.value.url,
          section: this.dataNewArticle.categories.main.slug,
          article: this.dataNewArticle.id
        };
    
        this.arrayArticles.articles.unshift(this.dataArticleBuild);

        //this.arrayArticles.articles.push(this.dataArticleBuild);
        console.log("ArrayArticles:", this.arrayArticles);
    
        this.formGroup.get('url')?.reset();
      } else {
        console.warn("El artículo ya existe en el array.");
      }
    } else {
      console.error("Error: `dataNewArticle` no está definido o no tiene el formato esperado.");
    }
    
  } catch (error) {
    console.error("Error al obtener los datos del artículo:", error);
  }
}

removeArticleByArticleId(articleId: string) {
  this.arrayArticles.articles = this.arrayArticles.articles.filter(article => article.article !== articleId);
}

sendArticles() {
  console.log("Array a enviar:", this.arrayArticles);

  // Convertimos el observable a una promesa usando lastValueFrom
  lastValueFrom(this.moduleService.sendArticles(this.arrayArticles))
    .then((response) => {
      console.log("Respuesta del guardado:", response);

      if (response.code === "200") {
        Swal.fire({
          icon: 'success',
          title: '¡Guardado!',
          text: 'Guardó artículos con éxito',
          confirmButtonText: 'Aceptar'
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Ocurrió un error al guardar los artículos. Inténtalo de nuevo.',
          confirmButtonText: 'Aceptar'
        });
      }
    })
    .catch((error) => {
      console.error("Error al enviar los artículos:", error);

      // Manejo de errores con Swal
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al guardar los artículos. Inténtalo de nuevo.',
        confirmButtonText: 'Aceptar'
      });
    });
}







}
