import { Component, Input } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent {
  @Input() optionMenu: string | undefined;
  public role: string = '';

  
  constructor(private authService: AuthService) {
    
    if(this.role == '') {
      setTimeout(() => {
        this.role = this.authService.getRole();
      }, 1000);
    }
  }

}
