import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Gallery, Photo } from 'src/app/models';

import { environment as env } from 'src/environments/environment'

@Injectable({ providedIn: 'root' })
export class UploadGalleryService {
  private baseUrl = `${env.apiCerberoUrl}`;

  constructor(private httpClient: HttpClient) {}

  uploadGallery(gallery: Gallery): Observable<any>{  
    return this.httpClient.post<any>(`${this.baseUrl}/request?event=create-gallery`, gallery);
  }
  
}
