import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment as env } from 'src/environments/environment';
import * as Sentry from "@sentry/angular-ivy";
import { enableProdMode } from '@angular/core';


if (env.production || env.staging) {
  Sentry.init({
    dsn: env.sentry,
    release: env.release,
    environment: env.production ? 'prod' : 'dev',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,

    tracePropagationTargets: [/^https:\/\/stage-mercurio\.pulzo\.com/, /^https:\/\/mercurio\.pulzo\.com/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

  enableProdMode();
}


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
