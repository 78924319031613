import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { VersionerUrlService } from 'src/app/core/services/versioner-url.service';
import { generator } from 'src/app/models/verioner-url';
import { lastValueFrom } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-home-versioner',
  templateUrl: './home-versioner.component.html',
  styleUrls: ['./home-versioner.component.css']
})
export class HomeVersionerComponent implements OnInit {
  formGroup: FormGroup;
  loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private versionerService: VersionerUrlService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      url: ['', [Validators.required, Validators.pattern('(https?://.*)')]] // Validación para URLs
    });
  }

  generarVersion() {
    if (this.formGroup.valid) {
      this.loading = true; // Inicia el indicador de carga
  
      const articleUrl: generator = { url: this.formGroup.value.url };
  
      this.versionerService.generarVersion(articleUrl).subscribe({
        next: (response) => {
          console.log('Response from API:', response);
          sessionStorage.setItem('versionData', JSON.stringify(response.data));
          this.router.navigate(['/article-versioner']);
        },
        error: (err) => {
          console.error('Error al generar la versión:', err);
        },
        complete: () => {
          this.loading = false; // Detiene el indicador de carga cuando la solicitud termina
        }
      });
    } else {
      console.error('Formulario no válido');
    }
  }

  
}
