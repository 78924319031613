<app-back-header link='/home-newsletter'></app-back-header>

<div class="bg-white mt-2">
    <div class="grid grid-cols-2 h-16 shadow-md">
        <div>
            <h1 class="text-3xl text-dark py-3 pl-4">Administrador Newsletter</h1>
        </div>

        <!-- <button class="flex gap-2 items-center justify-center cursor-pointer">
            <i class="far fa-eye text-dark-light"></i>
            <p class="text-dark-light">Ver Newsletter</p>
        </button> -->
    </div>

    <div class="grid grid-cols-2">
        <div class="my-10 mx-auto w-4/5">

            <section class="h-auto w-full py-6 px-10 mb-8 bg-white rounded-2xl shadow-md">
                <div class="grid grid-row-2 gap-4">

                    <div class="flex bg-light-dark pl-2 py-2 h-10">
                        <i class="fas fa-plus-circle text-dark-light py-1"></i>
                        <label class="font-bold text-lg ml-3">Añadir nueva nota</label>
                    </div>

                    <input type="text" placeholder="Pegue la URL del artículo" [(ngModel)]="linkArticle"
                        class="rounded-md h-8 mb-2 pl-2 border border-dark-light focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black">
                    <div class="flex justify-end">
                        <button (click)="addArticleNewsletter()"
                            class="bg-blue-500 w-20 h-10 rounded-lg text-white">Añadir</button>
                        <!-- <button class="bg-green-400 w-20 h-10 ml-5 rounded-lg text-white">Limpiar</button> -->
                    </div>

                </div>
            </section>

            <form [formGroup]="formNewsletter" (ngSubmit)="submitNewsletter()">
                <section class="h-auto w-full py-6 px-10 bg-white rounded-2xl shadow-md">
                    <div class="flex bg-light-dark pl-2 py-2 h-10 mb-4">
                        <i class="fas fa-envelope text-dark-light pt-1.5"></i>
                        <label class="font-bold text-lg ml-3">Información del newsletter</label>
                    </div>

                    <div class="grid grid-row-2 gap-4">
                        <label>Asunto</label>
                        <input type="text" formControlName="subject"
                            class="rounded-md h-8 mb-2 pl-2 border border-dark-light focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black"
                            [ngClass]="{'border-red': checkValidation(1, formNewsletter.get('subject'))}">
                        <div *ngIf="checkValidation(1, formNewsletter.get('subject'))" class="text-red text-sm">
                            Máximo 150 caracteres.
                            <div *ngIf="checkValidation(2, formNewsletter.get('subject'))"> {{
                                'admin.user.form.required' | transloco }}</div>
                        </div>
                    </div>

                    <div class="grid grid-row-2 gap-4">
                        <label>Fecha</label>
                        <input type="date" formControlName="date" pattern="\d{4}-\d{2}-\d{2}"
                            class="rounded-md h-8 mb-2 pl-2 border border-dark-light focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black"
                            [ngClass]="{'border-red': checkValidation(1, formNewsletter.get('date'))}">
                        <div *ngIf="checkValidation(1, formNewsletter.get('date'))" class="text-red text-sm">
                            <div *ngIf="checkValidation(2, formNewsletter.get('date'))"> {{ 'admin.user.form.required' |
                                transloco }}</div>
                        </div>
                    </div>

                    <div class="grid grid-row-2 gap-4">
                        <label>Hora</label>
                        <div class="flex">
                            <select formControlName="hour"
                                class="rounded-md w-1/2 h-8 mb-2 pl-2 border border-dark-light focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black"
                                [ngClass]="{'border-red': checkValidation(1, formNewsletter.get('hour'))}">
                                <option value="" selected>HH</option>
                                <option *ngFor="let hora of horas" [value]="hora">{{ hora }}</option>
                            </select>

                            <span class="mx-2">:</span>

                            <select formControlName="minute"
                                class="rounded-md w-1/2 h-8 mb-2 pl-2 md:mt-0 border border-dark-light focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black"
                                [ngClass]="{'border-red': checkValidation(1, formNewsletter.get('minute'))}">
                                <option value="" selected>MM</option>
                                <option *ngFor="let minuto of minutos" [value]="minuto">{{ minuto }}</option>
                            </select>

                        </div>
                        <div class="flex justify-between">
                            <div *ngIf="checkValidation(1, formNewsletter.get('hour'))" class="text-red text-sm">
                                <div *ngIf="checkValidation(2, formNewsletter.get('hour'))"> {{
                                    'admin.user.form.required' | transloco }}</div>
                            </div>
                            <div *ngIf="checkValidation(1, formNewsletter.get('minute'))" class="text-red text-sm">
                                <div *ngIf="checkValidation(2, formNewsletter.get('minute'))"> {{
                                    'admin.user.form.required' | transloco }}</div>
                            </div>
                        </div>

                    </div>
                </section>

                <button type="submit"
                    class="border bg-green-MainColor hover:bg-green  cursor-pointer rounded-lg shadow-lg float-right mr-2 h-9 w-48 my-5 text-white">
                    Guardar todo</button>
            </form>
        </div>

        <div class="mt-10 mx-auto">
            <div [innerHTML]="getTrustedHeader()"></div>

            <div *ngFor='let item of htmlTable; let i = index'>
                <div [innerHTML]="getTrustedAnything(item)"></div>
                <button class="" (click)="deleteArticle(i)"
                    style="margin-top: 7px; background: #fff; border: 1px solid #DBDBDB; border-radius: 5px; width: 35px; height: 35px; padding: 7px;">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <path d="M3 6L5 6L21 6" />
                        <path
                            d="M10 6L10 21C10 21.5523 9.55229 22 9 22L7 22C6.44771 22 6 21.5523 6 21L6 6L6 6L6 6L10 6Z" />
                        <path
                            d="M14 6L14 21C14 21.5523 13.5523 22 13 22L11 22C10.4477 22 10 21.5523 10 21L10 6L10 6L14 6Z" />
                        <path
                            d="M18 6L18 21C18 21.5523 17.5523 22 17 22L15 22C14.4477 22 14 21.5523 14 21L14 6L14 6L18 6Z" />
                        <path d="M6 10L6 6L18 6L18 10" />
                        <path d="M6 6L12 2L18 6" />
                    </svg>
                </button>
            </div>

            <div [innerHTML]="getTrustedFooter()"></div>
        </div>
    </div>
</div>