import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CreateList, DataLink, DeleteList, ListLinks, SearchList } from 'src/app/models/list-link';
import { environment as env } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ListLinkService {
  private baseUrl = `${env.apiCerberoUrl}`;

  constructor(private http: HttpClient) { }

  getListLink(): Observable<ListLinks>{
    return this.http.get<ListLinks>(`${this.baseUrl}/request?event=list-links`);
  }

  createNewList(links: CreateList){
    //console.log('srv-crear', `${this.baseUrl}/request?event=create-list-links`, links );
    return this.http.post<CreateList>(`${this.baseUrl}/request?event=create-list-links`, links);
  }

  getListLinkById(id: number): Observable<SearchList>{
    //console.log('srv-editar', `${this.baseUrl}/request?event=list-links-find-by-id&id=${id}` );
    return this.http.get<SearchList>(`${this.baseUrl}/request?event=list-links-find-by-id&id=${id}`);
  }

  updateListLink(updateList: DataLink){
    //console.log('srv-update', `${this.baseUrl}/request?event=update-list-links`, updateList );
    return this.http.put<DataLink>(`${this.baseUrl}/request?event=update-list-links`, updateList);
  }

  deleteListLink(body:DeleteList){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: body 
    };

    return this.http.delete<DeleteList>(`${this.baseUrl}/request?event=delete-list-links`, httpOptions);
  }
}
