import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CreateList, Links } from 'src/app/models/list-link';
import { ListLinkService } from 'src/app/core/services/list-link.service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-form-links',
  templateUrl: './create-link-list.component.html',
  styleUrls: ['./create-link-list.component.css'],

})
export class CreateLinkListComponent implements OnInit  {
  listForm: FormGroup;
  links: Links[] = [];

  constructor(private fB:FormBuilder, private srvLinks: ListLinkService,  private router: Router, private route: ActivatedRoute){
    this.listForm = this.fB.group({
      name: ['', Validators.required],
      label: [''],
      url: [''],
    });
  }

  ngOnInit(): void {}

  //Validación campos
  checkValidation(option: number, field: any) {
    if (option == 1) {
      return field.invalid && (field.dirty || field.touched);
    } else {
      return field.errors?.['required'];
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.links, event.previousIndex, event.currentIndex);
  }

  addItem(){
    if (this.links.length >= 8) {
      Swal.fire('', 'No puedes agregar más de 8 enlaces.', 'warning');
      return;
    }

    if(this.listForm.valid){
      const newLink: Links = {
        label: this.listForm.get('label')?.value,
        url: this.listForm.get('url')?.value,
      };

      this.links.push(newLink);
      
      this.listForm.patchValue({
        label: '',
        url: '',
      });
    }
    //console.log('addlinks', this.links);
  }

  submitList(){
    if (this.listForm.invalid) {
      Swal.fire('', 'Falta información, complete los campos', 'warning');
    } else {
        if (this.listForm.get('name')?.valid && this.links.length > 0) {
        
          const newList: CreateList = {
            name: this.listForm.get('name')?.value,
            links: this.links
          };

          this.srvLinks.createNewList(newList).subscribe(() =>{
              Swal.fire("", "Lista guardada con exito", "success")
              .then(() => {
                this.router.navigate(['/list-links']);
              }); 
          });
        } 
      }
  }

}
