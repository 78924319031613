import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { User } from 'src/app/models';
import { environment as env } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class TableService {
  private baseUrl = `${env.apiCerberoUrl}`;

  constructor(private httpClient: HttpClient) {}

  tableListing(page: number) {
    return this.httpClient.get<any>(`${this.baseUrl}/request?event=gallery-list&page=${page}`);
  }
  
}
