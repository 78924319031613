<app-back-header link='/home-versioner'></app-back-header>

<div class="min-h-screen flex flex-col items-center justify-center">
  <div class="w-full max-w-3xl bg-white p-8 rounded-lg shadow">
    <h1 class="text-2xl not-italic font-bold leading-8 mb-6">Versionador de artículos</h1>

    <div *ngIf="versionData" class="space-y-6">
      <div class="flex flex-col">
        <label class="block text-sm font-medium text-gray-700" for="">Título</label>
        <div class="mt-1 block w-full border-gray-300 rounded-md shadow-sm sm:text-sm bg-gray-200 p-4">{{ versionData?.titulo }}</div>
      </div>
      
      <div class="flex flex-col">
        <label class="block text-sm font-medium text-gray-700" for="">Subtítulo</label>
        <div class="mt-1 block w-full border-gray-300 rounded-md shadow-sm sm:text-sm bg-gray-200 p-4">{{ versionData?.subtitulo }}</div>
      </div>
      
      <div class="flex flex-col">
        <label class="block text-sm font-medium text-gray-700" for="">Cuerpo del texto</label>
        <div class="mt-1 block w-full border-gray-300 rounded-md shadow-sm sm:text-sm bg-gray-200 p-4">{{ versionData?.body }}</div>
      </div>
      
      <div class="flex flex-col">
        <label class="block text-sm font-medium text-gray-700" for="">URL Utilizada</label>
        <a [href]="versionData?.url_usada" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm sm:text-sm bg-gray-200 p-4">{{ versionData?.url_usada }}</a>
      </div>
    </div>
    
  </div>
</div>
