    <div class="w-[792px] m-auto">
        <button  [routerLink]="['/create-module']" class="w-[79px] flex justify-between mb-6 mt-8 items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M15 19L8 12L15 5" stroke="#038652" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span class="font-bold text-[13px] text-[#666]">{{ 'Modules.Back' | transloco }}</span>
        </button>
        <div class="w-[792px] flex justify-between mb-6">
            <h1 class="text-2xl font-bold">{{ 'ModuleManager.newModule' | transloco }}</h1>
            <button 
                (click)="sendInformation()" 
                class="send-info w-[168px] h-10 bg-[#CBCBCB] text-[#fff] rounded font-semibold" 
                [ngClass]="{ 'active': formCreateModule.valid && !hasEmptyProperties(formCreateModule.get('properties').value)}"
                [disabled]="!formCreateModule.valid || hasEmptyProperties(formCreateModule.get('properties').value)"
                >
                {{ 'globals.createModule' | transloco }}
            </button>

        </div>
        <div class="grid w-full grid-cols-2 ">
            <div class="flex flex-row justify-between w-[792px]">
                <div class="w-[456px] col-span-1 h-[288px] flex flex-col items-center bg-[#fff] rounded-xl">
                    <h2 class="text-[#45464E] text-xl w-[408px] mb-7 mt-6 text-left font-bold">{{ 'Modules.ModuleInformation' | transloco }}</h2>
                    <div class="flex flex-row justify-between w-[408px] h-5 mb-4">
                        <span class="text-[#8B8D97] font-semibold text-sm">{{ 'Modules.ModuleName' | transloco }}</span><span class="text-[#282828] text-sm font-normal">{{receivedData?.name}}</span>
                    </div>
                    <div class="flex flex-row justify-between w-[408px] h-5 mb-4">
                        <span class="text-[#8B8D97] font-semibold text-sm">{{ 'Modules.CreationDate' | transloco }}</span><span class="text-[#282828] text-sm font-normal">{{dateNow}}</span>
                    </div>
                    <div class="flex flex-row justify-between w-[408px] h-5 mb-4">
                        <span class="text-[#8B8D97] font-semibold text-sm">{{ 'Modules.LastUpdate' | transloco }}</span><span class="text-[#282828] text-sm font-normal">{{dateNow}}</span>
                    </div>
                    <div class="flex flex-row justify-between w-[408px] h-5 mb-4">
                        <span class="text-[#8B8D97] font-semibold text-sm">{{ 'Modules.Editor' | transloco }}</span><span class="text-[#282828] text-sm font-normal">{{editor}}</span>
                    </div>
                    <div class="flex flex-row justify-between w-[408px] h-[42px]">
                        <span class="text-[#8B8D97] font-semibold text-sm">{{ 'Modules.templateType' | transloco }}</span>
                        <div class="w-[138px] h-[42px] bg-[#F4F5FA] flex items-center flex-row rounded-[6px]">
                            <div class="w-[36px] h-[36px] bg-[#81BCFF] flex rounded-[3px] ml-[3px] mr-[6px] flex-row justify-center items-center mt-auto mb-auto">
                                <div class="w-[24px] h-[24px] bg-[#81BCFF] flex items-center ml-[6px] mr-[6px] mt-auto mb-auto justify-center">
                                    <img class="w-6 h-[18px] m-auto object-contain" src="{{receivedData?.typeModule?.icon}}" />
                                </div>
                            </div>
                            <span class="text-[10.5px] font-semibold text-[#282828] mr-[3px]">{{receivedData?.typeModule?.name}}</span>
                        </div>
                    </div>
                </div>

                <div class="flex flex-col w-[312px] col-span-1  h-72 bg-[#fff] rounded-xl">
                    <h2 class="mt-6 mb-[23px] w-[264px] ml-auto mr-auto text-left font-bold text-xl text-[#45464E]" >{{ 'Modules.Structure' | transloco }}</h2>
                    <div class="ml-auto mr-auto flex flex-col justify-center items-center">
                        <img class="w-[196px] h-[182px] m-auto object-cover" src="{{receivedData?.typeModule?.thumbnail}}" />
                    </div>
                </div>
            </div>

            <div class="w-[792px] min-h-[418px]  h-auto  mb-4 rounded-xl col-span-2 mt-[52px] bg-[#fff] flex flex-col justify-center items-center relative">
                <div class="w-[428px] h-14 bg-[#F4F5FA] rounded-2xl flex items-center absolute top-[-28px]">
                    <button (click)="changeProcess('ubicacion')" [ngClass]="{'active': process === 'ubicacion'}" class="w-[132px] h-10 flex flex-row ml-2 rounded-lg items-center">
                        <div class="ml-4 mr-1">
                            <div *ngIf="process!='ubicacion'; else elseUbication">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M4 17H10M4 12H13M18 11V19M18 19L21 16M18 19L15 16M4 7H16" stroke="#53545C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <ng-template #elseUbication>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M4 17H10M4 12H13M18 11V19M18 19L21 16M18 19L15 16M4 7H16" stroke="#4279E3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </ng-template>
                        </div>
                        <span class="text-[#53545C] text-sm font-medium">{{ 'Modules.Location' | transloco }}</span>
                    </button>
                    <button (click)="changeProcess('contenido')" [ngClass]="{'active': process === 'contenido'}" class="w-[132px] h-10 flex flex-row ml-2 rounded-lg items-center">
                        <div class="ml-4 mr-1">
                            <div *ngIf="process!='contenido'; else elseContenido">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M4.53516 11.4647L11.4294 4.5704C13.4797 2.52015 16.8039 2.52015 18.8541 4.5704C20.9044 6.62066 20.9041 9.94496 18.8539 11.9952L10.8989 19.9502C9.53209 21.317 7.31639 21.3168 5.94955 19.95C4.58272 18.5831 4.58238 16.3673 5.94922 15.0005L13.9042 7.0455C14.5876 6.36208 15.6962 6.36208 16.3796 7.0455C17.0631 7.72892 17.0626 8.83669 16.3792 9.52011L9.48486 16.4144" stroke="#53545C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <ng-template #elseContenido>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M4.53516 11.4647L11.4294 4.5704C13.4797 2.52015 16.8039 2.52015 18.8541 4.5704C20.9044 6.62066 20.9041 9.94496 18.8539 11.9952L10.8989 19.9502C9.53209 21.317 7.31639 21.3168 5.94955 19.95C4.58272 18.5831 4.58238 16.3673 5.94922 15.0005L13.9042 7.0455C14.5876 6.36208 15.6962 6.36208 16.3796 7.0455C17.0631 7.72892 17.0626 8.83669 16.3792 9.52011L9.48486 16.4144" stroke="#4279E3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </ng-template>
                        </div>
                        <span class="text-[#53545C] text-sm font-medium">{{ 'Modules.Content' | transloco }}</span>
                    </button>
                    <button (click)="changeProcess('diseno')" [ngClass]="{'active': process === 'diseno'}" class="w-[132px] h-10 flex flex-row ml-2 rounded-lg items-center">
                        <div class="ml-4 mr-1">
                            <div *ngIf="process!='diseno'; else elseBlock">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M6.5 21H20.4706C20.763 21 21.0002 20.7631 21.0002 20.4707L21 14.5293M7.98047 20.6713L20.6421 14.7671C20.9071 14.6435 21.0217 14.3285 20.8981 14.0635L18.3872 8.6792C18.2636 8.41421 17.9486 8.29914 17.6836 8.42271L12.0303 11.0591M9.88182 18.4048C9.38152 20.2719 7.46235 21.38 5.59521 20.8797C3.72808 20.3794 2.61982 18.4604 3.12011 16.5933L6.73596 3.09863C6.81163 2.81621 7.10168 2.64855 7.3841 2.72422L13.1231 4.26171C13.4055 4.33738 13.5731 4.6276 13.4974 4.91002L9.88182 18.4048ZM6.5 17.6001H6.502L6.50195 17.6021L6.5 17.6021V17.6001Z" stroke="#53545C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <ng-template #elseBlock>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M6.5 21H20.4706C20.763 21 21.0002 20.7631 21.0002 20.4707L21 14.5293M7.98047 20.6713L20.6421 14.7671C20.9071 14.6435 21.0217 14.3285 20.8981 14.0635L18.3872 8.6792C18.2636 8.41421 17.9486 8.29914 17.6836 8.42271L12.0303 11.0591M9.88182 18.4048C9.38152 20.2719 7.46235 21.38 5.59521 20.8797C3.72808 20.3794 2.61982 18.4604 3.12011 16.5933L6.73596 3.09863C6.81163 2.81621 7.10168 2.64855 7.3841 2.72422L13.1231 4.26171C13.4055 4.33738 13.5731 4.6276 13.4974 4.91002L9.88182 18.4048ZM6.5 17.6001H6.502L6.50195 17.6021L6.5 17.6021V17.6001Z" stroke="#4279E3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </ng-template>
                        </div>
                        <span class="text-[#53545C] text-sm font-medium">{{ 'Modules.Design' | transloco }}</span>
                    </button>
                </div>
                <div *ngIf="process=='ubicacion'" class="w-[744px] min-h-[314px]  mt-16 mb-10 flex flex-row">
                    <div class="w-[360px] h-full flex flex-col mr-6">
                        <span class="text-base font-semibold mb-2 text-[#8B8D97]">{{ 'ModuleManager.position' | transloco }}</span>
                            <div class="bg-[#EFF1F9] rounded-lg">
                                <form [formGroup]="formCreateModule">
                                    <div formGroupName="location">
                                        <select class="w-[328px] h-9 bg-[#EFF1F9] opacity-60 mt-2 mb-2 ml-4 mr-4" formControlName="position">
                                            <option *ngFor="let opcion of selectInfo" [value]="opcion.name">{{opcion.text}}</option>
                                            <option selected disabled hidden>{{ 'Modules.Sections' | transloco }}</option>
                                        </select>
                                    </div>
                                </form>                             
                            </div>      
                    </div> 
                    <div class="w-[360px] h-full flex flex-col">
                        <span class="text-base font-bold mb-2 text-[#212121]">{{ 'Modules.Sections' | transloco }}</span>
                        <div class="flex flex-row flex-wrap justify-between">
                            <div>
                                <div class="flex flex-row items-center">
                                    <input type="checkbox"
                                    (change)="onChangeCheck($event.target['value'], $event.target['checked'])" 
                                     [checked]="formCreateModule.value.location.sections.includes('home')"
                                    value="home" class="w-5 h-5 rounded-sm" /><span class="text-[#2B2F32] text-sm font-normal ml-2">Home</span>
                                </div>
                                <hr class="w-[336px] mt-4 h-[1px] bg-[#F0F1F5]">
                            </div> 
                            <div *ngFor="let section of sections" class="mt-[10px] w-[160px] h-6 flex items-center">
                                <input type="checkbox" 
                                [checked]="formCreateModule.value.location.sections.includes(section.Loc)"
                                value="{{ section.Loc }}" 
                                (change)="onChangeCheck($event.target['value'], $event.target['checked'])" 
                                class="w-5 h-5 rounded-sm" />
                                <span class="text-[#2B2F32] text-sm font-normal ml-2">{{section.Loc}}</span>
                            </div>
                            <hr class="w-[336px] mt-4 h-[1px] bg-[#F0F1F5]">
                            <div *ngFor="let specialSection of specialSections" class="mt-[10px] w-[160px] h-6 flex items-center">
                                <input type="checkbox" 
                                [checked]="formCreateModule.value.location.sections.includes(specialSection.Loc)"
                                value="{{ specialSection.Loc }}" 
                                (change)="onChangeCheck($event.target['value'], $event.target['checked'])" 
                                class="w-5 h-5 rounded-sm" />
                                <span class="text-[#2B2F32] text-sm font-normal ml-2">{{specialSection.Loc}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="process=='contenido'" class="w-[744px]  h-auto min-h-[314px] mt-16">
                        <div *ngFor="let property of receivedData?.typeModule?.properties">
                            <div *ngIf="property.type.toLowerCase()=='ls' || property.type.toLowerCase()=='la'" class="w-[744px] min-h-[79px] mb-6 grid grid-cols-auto">
                                <hr class="w-full border-t-2 border-gray-50">
                                <div class="w-[744px] justify-between h-auto flex flex-row flex-wrap">
                                    <div  *ngFor="let item of generateArray(property['quantity']); let i = index">
                                        <div *ngIf="property['type']=='LS'" class="col-span-1 w-[360px] mt-6">
                                            <div *ngIf="property['type']=='LS'" class="flex flex-col h-full">
                                            <span class="text-base font-semibold mb-2 text-[#8B8D97]">{{capitalizeFirstLetter(property?.name)}} {{i + 1}}</span>
                                            <div class="bg-[#EFF1F9] rounded-lg">
                                                <input type="text" [value]="property['value']"  (change)="onChangeInputContent($event.target,property['name'], i)"  placeholder="Link del {{property['name']}}" class="w-[328px] h-9 bg-[#EFF1F9] opacity-60 mt-2 mb-2 ml-4 mr-4" />
                                            </div>
                                            </div>
                                        </div>
                                        <div *ngIf="property.type == 'LA'" class="col-span-1 w-[360px] flex flex-col mt-6">
                                            <div *ngIf="property.type == 'LA'" class="flex flex-col">
                                                <span class="text-base font-semibold mb-2 text-[#8B8D97]">{{ capitalizeFirstLetter(property?.name) }} {{i + 1}}</span>
                                                <div class="bg-[#EFF1F9] rounded-lg">
                                                    <input type="text" [value]="getPropertyValue(property.value, i)" (change)="onChangeInputContent($event.target, property?.name, i)" placeholder="{{ 'Modules.Link' | transloco }} {{property?.name}}" class="w-[328px] h-9 bg-[#EFF1F9] opacity-60 mt-2 mb-2 ml-4 mr-4" />
                                                </div>
                                            </div>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                  
                  
                  
                <div *ngIf="process=='diseno'" class="w-[744px] min-h-[414px] bg-white">
                    <div class="mt-10 mb-4">
                        <div class="mr-auto ml-auto flex flex-col items-center">
                            <div class="w-[360px] h-[52px] mb-8 flex flex-row items-center justify-center rounded-lg bg-[#EFF1F999]">
                                <select (change)="onChangeSelect($event)" class="w-[328px] h-[19px] bg-[#EFF1F999]">
                                    <option value="" selected>{{ 'Modules.SelectOption' | transloco }}</option>
                                    <ng-container *ngFor="let option of selectElements">
                                      <option *ngIf="option.type === 'IMG' || option.type ==='img'" [value]="option.name"> 
                                        {{ option.name }}  {{ 'Modules.Quantity' | transloco }}:{{ option.quantity }}
                                      </option>
                                    </ng-container>
                                  </select>                                  
                                
                            </div>
                            <div class="w-full h-44 relative flex items-center justify-center">
                                <div  *ngIf="selectedOption!='' && selectedOption!='Seleccione...'" class="Icon absolute flex w-full h-44 flex-col justify-center items-center rounded-xl"
                                    ngx-dropzone [accept]="'image/*,video/*'" id="inputImages" (change)="onSelect($event)">
                                    <div>
                                        <div *ngIf="selectedFiles.length==0" class="flex flex-col justify-around ml-auto mr-auto">
                                            <ngx-dropzone-label class="Button-icons flex justify-center items-center gap-1 px-2.5 rounded-lg">{{ 'globals.add' | transloco }} <strong>{{selectedOption}}</strong></ngx-dropzone-label>
                                            <ngx-dropzone-label class="block text-sm font-normal leading-4 relative">Formato: jpg o png</ngx-dropzone-label>
                                        </div>
                                    </div>
                                    
                                    <div *ngIf="selectedFiles.length>0" class="h-full w-full flex flex-col">
                                        <ngx-dropzone-image-preview class="w-24 bg-cover bg-opacity-5 bg-gray-medio" ngProjectAs="ngx-dropzone-preview" [removable]="true"
                                            *ngFor="let f of selectedFiles" [file]="f" (removed)="onRemove($event)">
                                        </ngx-dropzone-image-preview>
                                    </div>

                                
                                </div> 
                                <div *ngIf="selectedFiles.length>0" class="absolute m-auto">
                                    <button (click)="addElement()" class="mt-4 mb-4 w-[140px] h-[40px] rounded-3xl bg-[#4279E3] border border-[#D2D2D2] text-white">{{ 'globals.add' | transloco }}</button>
                                </div>
                            </div>
                        </div>
                        <div class="w-full h-auto ml-1 border border-x-gray-200 bg-gray-300 min-h-64 rounded-md mt-2">
                            <div class="w-full h-auto gap-1 flex flex-row flex-wrap">
                                <ng-container  *ngFor="let data of arrayNewModule; let i = index">
                                    <div *ngIf="data?.type?.toLowerCase() === 'img'" class="relative w-[23.5%] pl-[4px] pr-[4px] h-auto flex flex-col justify-between mr-1 ml-1 mt-1 rounded-md items-center bg-white shadow-lg">
                                        <div class="w-full p-1 flex items-center justify-between flex-row">
                                            <span class="h-8 flex flex-row items-center justify-between">{{data.name}}</span>
                                            <div *ngIf="data.type.toLowerCase()=='img' && data.name==data.name">
                                                <div *ngIf="this.cantOfValues > data.value.length; else elseBlock" class="bg-gray-400 h-6 w-6 text-white ml-2 mr-2 flex items-center justify-center rounded-full">
                                                    <span>{{data.value.length}}</span>
                                                </div>
                                                <ng-template #elseBlock>
                                                    <div class="bg-[#13B977] h-6 w-6 text-white ml-2 mr-2 flex items-center justify-center rounded-full">
                                                        <span>{{data.value.length}}</span>
                                                    </div>
                                                </ng-template>
                                            </div>
                                            <div *ngIf="data.value.length>0" (click)="changeStateOpenModalUploadImage(data.name)" class="cursor-pointer w-7 h-7 flex items-center justify-center">
                                                <button>{{ !data.open ? '▼' : '▲' }}</button>
                                            </div>
                                        </div>
                                        
                                        <ng-container *ngIf="data.open">
                                            <div class="modal absolute bg-white flex items-center flex-col w-full top-[35px] left-0 z-50 rounded-b-md">
                                                <div class="h-[2px] bg-gray-300 w-[95%] rounded-b-md"></div>
                                                <div *ngFor="let value of data.value; let i = index"  class="flex flex-col pb-[2px] pr-[2px] pl-[2px] bg-gray-300 items-center justify-center h-10 w-[95%] gap">
                                                    <div class="flex bg-white rounded-[1px] w-full flex-row items-center justify-between p-1">
                                                        <span (click)="removeImage(value, data.value)" class="cursor-pointer w-6 h-6  shadow-inherit border-gray-300 border-solid border rounded-full flex flex-col items-center justify-center bg-gray-100">x</span>
                                                        <span>{{i+1}}</span>
                                                        <img class="h-[40px] w-[50px] object-cover rounded-sm"  src="{{value}}" />
                                                    </div>
                                                </div>
                                                <div class="h-2 bg-white w-full rounded-b-md"></div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
