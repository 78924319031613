import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class DeletePhotoFromGalleryService {
  private baseUrl = `${env.apiCerberoUrl}`;

  constructor(private httpClient: HttpClient) {}

  deletePhotoFromGallery(idBody: string) {
      const options = {
        body: {
          id: idBody,
        },
      };

      console.log(options)
    return this.httpClient.delete<any>(`${this.baseUrl}/request?event=del-gallery-img`, options);
  }
  
}
