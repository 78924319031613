import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SeoService } from 'src/app/core/services/seo.service';
import { AddArticle, Article, DeleteArticle } from 'src/app/models';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-home-seo',
  templateUrl: './home-seo.component.html',
  styleUrls: ['./home-seo.component.css'],
})
export class HomeSeoComponent {
  inputUrlForm!: FormGroup;
  article!: AddArticle;
  articlesList: Article[] =[];

  constructor(private seoService: SeoService){
    this.article = {} as AddArticle;
  }

  ngOnInit(): void {
    this.inputUrlForm = new FormGroup({
      url: new FormControl(null, Validators.required),
    });

    this.listArticles();
  }

  addArticle(){
    this.article.url = this.inputUrlForm.value.url;
    
    this.seoService.createArticle(this.article).subscribe((respuesta) => {
      if (respuesta.code != "200") {
        Swal.fire({
          text: 'Ha ocurrido un error interno al agregar el artículo, por favor vuelva a intentarlo más tarde.',
          icon: 'error',
        });
      } else{
        Swal.fire({
          text: 'Se ha agredado correctamente el artículo',
          icon: 'success',
        });
        this.listArticles();
        this.inputUrlForm.reset();
      }
    })
  }

  listArticles(){
    this.seoService.getListArticles().subscribe((articles: Article[]) => {
      this.articlesList = articles;
      //console.log('data', this.articlesList);
    })
  }

  deleteArticle(id: number){
    let idArt = { index: id} as DeleteArticle;

    Swal.fire({
      text: '¿Está seguro de borrar este artículo',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    }).then((confirmDelete) => {
      if (confirmDelete.isConfirmed) {
        this.seoService.deleteArticle(idArt).subscribe(() =>{
          Swal.fire({
            text: 'Se ha borrado el artículo correctamente',
            icon: 'success',
          });
          this.listArticles();
        });
      }
    });
  }
}
