<article class="bg-white pr-8 pl-10 pt-2 pb-2 md:flex md:justify-between md:items-center">
    <div class="flex items-center gap-0">
        <!-- <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Lgo_pulzo.svg/2560px-Lgo_pulzo.svg.png"
            class="h-8 mx-4 md:ml-0" alt="logo" />
        <h1 class="border-l-2 pl-5 font-semibold text-sm uppercase text-gray-medio tracking-wide">
            {{title}}
        </h1> -->

        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 40 40" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M28.507 6.37078C21.952 9.41003 11.9011 14.1158 11.353 16.2476C11.0181 17.5502 13.6252 22.9082 15.9332 27.2133C15.6296 27.7515 15.174 28.6053 15.0857 28.8837C14.2143 31.6287 15.7134 34.2081 17.8723 34.8934C19.8528 35.5221 22.7571 34.7246 23.4042 31.4892C23.8297 29.3615 22.499 27.0409 20.4255 26.3827C20.3309 26.3526 20.4256 26.6516 20.5787 27.1347C21.1141 28.8241 22.3633 32.7658 18.7233 32.7658C17.8894 32.7658 16.6469 31.4212 17.3979 29.8821C17.4685 30.0077 17.5379 30.1307 17.6059 30.2507C17.641 30.3127 17.6755 30.3753 17.7099 30.4379C17.8737 30.7354 18.0377 31.0332 18.2756 31.2746C18.4902 31.4924 18.7918 31.699 19.1606 31.6937C19.5475 31.6882 20.0085 31.4493 20.1085 30.3255C20.1449 29.9157 19.9458 29.1287 19.7292 28.2729C19.4178 27.0425 19.0705 25.6702 19.3359 25.0729C19.529 24.6382 20.7427 23.8387 22.1279 22.9263C23.9693 21.7135 26.1137 20.301 26.5673 19.2798C27.0251 18.2492 26.3087 17.9464 25.2989 18.4483C23.2406 19.4713 20.7529 20.671 20.3559 20.6462C20.0828 20.6292 19.6931 20.3074 20.1115 19.7983C20.2028 19.6872 20.8811 19.3005 21.8307 18.759C24.2145 17.3997 28.3082 15.0655 29.1185 13.67C29.6467 12.7602 28.5008 11.9879 27.5787 12.4942C23.8815 14.5244 18.9729 17.1697 18.6415 17.149C18.1379 17.1175 18.1323 16.7306 18.36 16.4177C18.4259 16.3272 19.3898 15.6736 20.7441 14.7554C24.0734 12.4981 29.762 8.64111 30.27 7.61591C30.8396 6.46622 29.671 5.83106 28.507 6.37078Z" fill="url(#paint0_linear_8465_1837)"/>
            <defs>
              <linearGradient id="paint0_linear_8465_1837" x1="23.8881" y1="3.6044" x2="15.2197" y2="30.9113" gradientUnits="userSpaceOnUse">
                <stop stop-color="#56CDF3"/>
                <stop offset="1" stop-color="#3C7DDE"/>
              </linearGradient>
            </defs>
          </svg>
          <h1 class="pr-2 pt-2 pb-2 font-semibold text-xl text-blue-mercuryBlue font-dm-sans border-r-2 "> {{ 'home.title' | transloco }}</h1>

          <svg xmlns="http://www.w3.org/2000/svg" width="56" height="32" viewBox="0 0 46 22" fill="none" class="pl-3">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.76919 14.893C4.29619 15.1047 4.88046 15.211 5.51099 15.211C6.10808 15.211 6.53482 15.0291 6.81837 14.6586C7.12804 14.2496 7.28654 13.5997 7.28654 12.7244C7.28654 11.3086 6.64148 10.6766 5.19355 10.6766C4.62831 10.6766 4.15085 10.6984 3.76919 10.7399V14.893ZM0.44873 21.3948V8.3569L0.815909 8.28316C2.1027 8.02598 3.54242 7.89551 5.09315 7.89551C6.87902 7.89551 8.25124 8.30235 9.17306 9.1081C10.1076 9.9277 10.5807 11.1505 10.5807 12.7408C10.5807 14.4689 10.1545 15.7958 9.30945 16.6875C8.45513 17.5889 7.18061 18.046 5.51892 18.046C4.92614 18.046 4.33908 17.9609 3.76919 17.7954V21.3948H0.44873Z" fill="#979797"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.4803 18.046L18.2585 17.1356C17.2094 17.7415 16.1425 18.046 15.0764 18.046C12.6308 18.046 11.3362 16.6984 11.3362 14.1508V7.89551H14.6588V14.1779C14.6588 14.5649 14.7449 14.8458 14.9148 15.0131C15.0825 15.1794 15.3659 15.2631 15.7559 15.2631C16.4259 15.2631 17.133 14.9521 17.8589 14.3393V7.89551H21.1802V18.046H18.4803Z" fill="#979797"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.2063 18.0459H25.5269V4.54492H22.2063V18.0459Z" fill="#979797"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M26.5396 18.046V15.6704L30.9384 10.6415H26.7185V7.89551H34.9775V10.3569L30.6401 15.388H34.796V18.046H26.5396Z" fill="#979797"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M40.405 10.554C39.7433 10.554 39.2709 10.7319 38.9613 11.0974C38.6337 11.4844 38.4654 12.1025 38.4654 12.9353C38.4654 13.7925 38.6337 14.4284 38.9638 14.8283C39.2748 15.2047 39.7472 15.3877 40.405 15.3877C41.0647 15.3877 41.5357 15.2047 41.8462 14.8283C42.1773 14.4284 42.3456 13.7925 42.3456 12.9353C42.3456 12.1025 42.1778 11.4844 41.8502 11.0974C41.5401 10.7319 41.0667 10.554 40.405 10.554ZM40.405 18.1247C38.6924 18.1247 37.3724 17.685 36.4867 16.8156C35.6025 15.9496 35.1502 14.6557 35.1433 12.9717C35.1433 11.2837 35.5937 9.98873 36.477 9.11804C37.3631 8.2485 38.685 7.80615 40.405 7.80615C42.1265 7.80615 43.4479 8.2485 44.3345 9.11804C45.2183 9.98873 45.6672 11.2837 45.6672 12.9706C45.6672 14.6552 45.2173 15.9482 44.3345 16.8156C43.4479 17.685 42.1265 18.1247 40.405 18.1247Z" fill="#979797"/>
            <path d="M5.21353 8.27858V7.17626H5.71398C6.14293 7.17626 6.31272 7.01134 6.31272 6.70755C6.31272 6.51659 6.22336 6.2562 6.03569 5.7875L4.68628 2.36768H6.22336L6.83998 4.25986C6.98296 4.69385 7.12595 5.28407 7.12595 5.28407H7.14382C7.14382 5.28407 7.26893 4.69385 7.41191 4.25986L8.01066 2.36768H9.46731L7.88555 6.87246C7.51915 7.91403 7.08126 8.27858 6.1608 8.27858H5.21353Z" fill="#979797"/>
            <path d="M2.79713 6.95036C2.18944 6.95036 1.69794 6.70733 1.41197 6.2473H1.39409V6.81149H0V0.605469H1.45665V2.87088H1.48346C1.76943 2.48897 2.16263 2.23726 2.78819 2.23726C3.97675 2.23726 4.74529 3.25279 4.74529 4.58947C4.74529 6.06503 3.97675 6.95036 2.79713 6.95036ZM2.38605 5.83068C2.95798 5.83068 3.27076 5.35329 3.27076 4.58079C3.27076 3.81698 2.95798 3.31355 2.36817 3.31355C1.72475 3.31355 1.41197 3.86037 1.41197 4.58947C1.41197 5.32725 1.7873 5.83068 2.38605 5.83068Z" fill="#979797"/>
          </svg>
    </div>

    <div class="flex gap-7 items-center justify-between" *ngIf="getUser">
        <p class="inline-block py-2 text-dark-light text-sm font-semibold no-underline pr-8">
            {{ 'home.WelcomeBack' | transloco }} {{getUser}}
        </p>

        <div class="dropdown w-12 rounded-md">
            <button>
                <div class="border-solid border rounded-lg w-20 grid grid-cols-2 border-green-800 pr-1 py-2">
                    <a class="tracking-wider">
                        <span class=""><i class="fas fa-chevron-down mt-2 text-green-600"></i></span>
                    </a>
                    
                    <img class="w-8 h-8 rounded-full pl-1"
                        src="https://www.pngall.com/wp-content/uploads/5/Profile-PNG-Clipart.png" alt="" />

                </div>
            </button>
            <div class="dropdown-content bg-white rounded-md border-[1px]">

                <button class="w-40 h-10 rounded-md text-sm hover:text-red-light" (click)="logout()">
                    <i class="fas fa-sign-out-alt fa-fw mr-2"></i>{{ 'globals.closeSesion' | transloco }}
                </button>

                <div class="w-full text-center text-sm">
                    <span >Version {{ version }}</span>
                </div>
            </div>
        </div>
    </div>
</article>
