import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NewsletterService } from 'src/app/core/services';
import { Newsletter, Seccion } from 'src/app/models/newsletter';
import { FormControl, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-create-newsletter',
  templateUrl: './create-newsletter.component.html',
  styleUrls: ['./create-newsletter.component.css']
})

export class CreateNewsletterComponent implements OnInit {
  secciones: string[] = [];
  template: string[] = [];
  audiences: any;
  formNewsletter!: FormGroup;
  parametroId: number;
  state: string;
  newsletter: Newsletter;
  isUpdate: boolean;


  constructor(private newsletterService: NewsletterService, private route: ActivatedRoute) {
    this.newsletter = {} as Newsletter;
    this.parametroId = 0;
    this.state = '';
    this.isUpdate = false;
  }

  ngOnInit(): void {
    this.getSecciones();
    this.getTemplate();
    this.getAudiences();

    this.formNewsletter = new FormGroup({
      name: new FormControl(null, [Validators.required, Validators.maxLength(80)]),
      section: new FormControl(null, Validators.required),
      state: new FormControl(null),
      html_template: new FormControl(null),
      recipients: new FormControl(null, [Validators.required, Validators.maxLength(80)]),
    })

    //trae el id del home
    this.route.queryParams.subscribe(params => {
      this.parametroId = params['id'];
      this.searchNewsletter(this.parametroId);
    });


  }

  searchNewsletter(id: number) {
    if (id != null) {

      this.isUpdate = true;

      this.newsletterService.searchNewsletter(id).subscribe((respuesta) => {

        this.newsletter = respuesta as Newsletter;

        console.log('news', this.newsletter);

        this.formNewsletter = new FormGroup({
          id: new FormControl(this.newsletter.id),
          name: new FormControl(this.newsletter.name, [Validators.required, Validators.maxLength(80)]),
          section: new FormControl(this.newsletter.section, Validators.required),
          state: new FormControl(this.newsletter.state, Validators.required),
          html_template: new FormControl(this.newsletter.html_template),
          recipients: new FormControl(this.newsletter.recipients, [Validators.required, Validators.maxLength(80)])
        });
      })
    }
  }


  submit() {
    this.newsletter.name = this.formNewsletter.value.name;
    this.newsletter.section = this.formNewsletter.value.section;
    this.newsletter.state = this.formNewsletter.value.state;
    this.newsletter.html_template = this.formNewsletter.value.html_template;
    this.newsletter.recipients = this.formNewsletter.value.recipients;

    if (this.formNewsletter.invalid) {
      this.showMessage('Verifica que todos los campos esten llenos', 'warning');
      return;
    }

    if (this.isANewNewsletterRecord()) {
      this.createNewsletter();
    } else {
      this.updateNewsletter();
    }
  }

  isANewNewsletterRecord(): boolean {
    return this.newsletter.id === undefined
  }

  createNewsletter() {
    let message: string = "Información guardada exitosamente";
    let typeMessage: string = "success";
    this.newsletterService.createNewsletter(this.newsletter).subscribe((respuesta) => {
      if (respuesta.code != "201") {
        message = "Ha ocurrido un error interno al crear el newsletter, por favor vuelva a intentarlo más tarde.";
        typeMessage = "error";
      }
    })

    this.showMessage(message, typeMessage);
  }

  updateNewsletter() {
    let message: string = "Información guardada exitosamente";
    let typeMessage: string = 'success';
    this.newsletterService.updateNewsletter(this.newsletter).subscribe((respuesta) => {
      if (respuesta.code !== "200") {
        message = "Ha ocurrido un error interno al actualizar el newsletter, por favor vuelva a intentarlo más tarde.";
        typeMessage = "error";
      }
    })

    this.showMessage(message, typeMessage);
  }

  showMessage(message: string, typeMessage: string) {
    if (typeMessage == 'success') {
      console.log("typeMessage: ", typeMessage);
      Swal.fire('', message, 'success').then(() => {
        window.location.href = '/home-newsletter';
      });
      return;
    }

    if (typeMessage === 'warning') {
      Swal.fire('', message, 'warning').then(() => { });
      return;
    }

    Swal.fire('', message, 'error').then(() => {
      window.location.href = '/home-newsletter';
    });

  }

  //Validación campos
  checkValidation(option: number, field: any) {
    if (option == 1) {
      return field.invalid && (field.dirty || field.touched);
    } else {
      return field.errors?.['required'];
    }
  }


  getSecciones(): void {
    fetch('https://filesstaticpulzo.s3.us-west-2.amazonaws.com/pulzo-lite/sitemap/sections.json')
      .then(response => response.json())
      .then((data: Seccion[]) => {
        this.secciones = data.map((item: Seccion) => item.Loc);
      })
      .catch(error => console.log(error));
  }

  getTemplate(): void {
    fetch('https://filesstaticpulzo.s3.us-west-2.amazonaws.com/pulzo-lite/newsletters/templates.json')
      .then(response => response.json())
      .then((data: { name: string, value: string }[]) => {
        this.template = data.map(item => item.value);
      })
      .catch(error => console.log(error));
  }

  getAudiences(): void {

    fetch('https://filesstaticpulzo.s3.us-west-2.amazonaws.com/pulzo-lite/newsletters/audience.json')
      .then(response => response.json())

      //console.log(response);
      .then((data) => {
        this.audiences = data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

}